import React, { useState } from "react";
import Navbar from "../../components/navigation/navbar";
import { Filter, Info, LayoutDashboard } from "lucide-react";
import OrdersTable from "./components/table";
import moment from "moment";
import ProductTable from "./components/table";

export const EuProducts: React.FC = () => {
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [filterRecords, setFilterRecords] = useState<number>(0);
  const [leatestDate, setLeatestDate] = useState("2024-12-11");
  return (
    <div className="min-h-screen bg-gray-50 font-[Arial,sans-serif]">
      <Navbar />

      <div className="flex gap-1 bg-white border-b border-gray-200 px-6 py-4">
        <LayoutDashboard />
        <h1 className="text-xl font-medium">
          EU Products:{" "}
          <span className="font-bold">
            As of {moment(new Date(leatestDate)).format("DD MMM YYYY")}
          </span>
        </h1>
      </div>
      <div className="p-4 md:p-6">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
          <div className="sm:col-span-12 md:col-span-3 lg:col-span-2 space-y-4">
            <div className="bg-white p-4 rounded-lg border border-gray-200 flex items-center shadow-[0_4px_20px_rgba(0,0,0,0.1)] transform transition-transform duration-300 ease-in-out hover:scale-105">
              <div className="mr-3">
                <Info
                  color="#007bff"
                  className="w-5 h-5 sm:w-6 sm:h-6"
                  strokeWidth={3.5}
                />
              </div>
              <div>
                <h2 className="text-xl font-bold mb-2">Total Records</h2>
                <div className="space-y-2">
                  <div>
                    <span className="text-gray-600">{totalRecords}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg border border-gray-200 flex items-center shadow-[0_4px_20px_rgba(0,0,0,0.1)] transform transition-transform duration-300 ease-in-out hover:scale-105">
              <div className="mr-3">
                <Filter
                  color="orange"
                  className="w-5 h-5 sm:w-6 sm:h-6"
                  strokeWidth={3.5}
                />
              </div>
              <div>
                <h2 className="text-xl font-bold mb-2">Filtered Records</h2>
                <div className="space-y-2">
                  <div>
                    <span className="text-gray-600">{filterRecords}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:col-span-12 md:col-span-9 lg:col-span-10 space-y-4">
            <ProductTable
              totalRecords={setTotalRecords}
              filteredRecords={setFilterRecords}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EuProducts;
