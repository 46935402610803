import { CircleX } from "lucide-react";

const WheatherForcast = () => {
  return (
    <div>
      <div className="bg-white rounded-lg shadow">
        <div className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
            <div className="sm:col-span-12 md:col-span-12 lg:col-span-6 space-y-4">
              <div className="p-4 bg-gray-200">
                <div className="flex flex-col items-center mt-5 mb-5">
                  <CircleX />
                  <div className="text-sm font-bold text-center text-[#a1343c] mt-2">
                    Error fetching data for this visual
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="sm:col-span-12 md:col-span-12 lg:col-span-6 space-y-4">
              <div className="p-4 bg-gray-200">
                <div className="flex flex-col items-center mt-5 mb-5">
                  <CircleX />
                  <div className="text-sm font-bold text-center text-[#a1343c] mt-2">
                    Error fetching data for this visual
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="sm:col-span-12 md:col-span-12 lg:col-span-12 space-y-4">
              <div className="p-4 bg-gray-200">
                <div className="flex flex-col items-center mt-5 mb-5">
                  <CircleX />
                  <div className="text-sm font-bold text-center text-[#a1343c] mt-2">
                    Error fetching data for this visual
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="sm:col-span-12 md:col-span-12 lg:col-span-12 space-y-4">
              <div className="p-4 bg-gray-200">
                <div className="flex flex-col items-center mt-5 mb-5">
                  <CircleX />
                  <div className="text-sm font-bold text-center text-[#a1343c] mt-2">
                    Error fetching data for this visual
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WheatherForcast;
