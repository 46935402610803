import React from "react";
import Navbar from "../../components/navigation/navbar";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../../components/header";
import ScheduledPerson from "./scheduledPerson";
import ScheduledMachine from "./scheduledMachine";
import ProductionPlan from "./productionPlan";

const PlanningPhase = () => {
  const Menu = [
    {
      id: 1,
      name: "Scheduling Person",
      route: "/planning-phase/schedule-person",
    },
    {
      id: 2,
      name: "Scheduling Machine",
      route: "/planning-phase/schedule-machine",
    },
    {
      id: 3,
      name: "Production Plan",
      route: "/planning-phase/production-plan",
    },
  ];
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <Header options={Menu} />
      <div className="ProdPlanningScreen-box">
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/production-phase/PrintKanban" replace />}
          />
          <Route path="/schedule-person" element={<ScheduledPerson />} />
          <Route path="/schedule-machine" element={<ScheduledMachine />} />
          <Route path="/production-plan" element={<ProductionPlan />} />
        </Routes>
      </div>
    </div>
  );
};

export default PlanningPhase;
