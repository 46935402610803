import React, { useEffect, useState } from "react";
import { Select } from "antd";

const DemandNavigation = ({
  selectedTabTxt,
  selectedItem,
}: {
  selectedTabTxt: (val: string) => void;
  selectedItem: (val: string) => void;
}) => {
  const [selectedMainTab, setSelectedMainTab] = useState(1);
  const [selectedSubTab, setSelectedSubTab] = useState(1);
  const [selectedTabItem, setSelectedTabItem] = useState<any>({
    id: 1,
    title: "Daily Sales",
  });
  console.log("selectedTabItem ----", selectedTabItem);
  const navItems = [
    {
      id: 1,
      title: "Daily Sales",
      subMenu: [],
    },
    { id: 2, title: "Invoiced - Occassion Split" },

    { id: 3, title: "Invoiced - Promo Performance" },
    { id: 4, title: "Invoiced - Product Split" },
    // {
    //   id: 5,
    //   title: "B2B - Specific Reports",
    //   subMenu: [
    //     { id: 1, title: "B2B - Product Split" },
    //     { id: 2, title: "B2B - Day per Day" },
    //     { id: 3, title: "B2B - KPI" },
    //     { id: 4, title: "B2B | Taktim & Other Distributors" },
    //     { id: 5, title: "Shipped Not Invoiced" },
    //   ],
    // },
    // { id: 6, title: "Unlocked Orders Follow Up" },
  ];

  const options = [
    { label: "Select all", value: "all" },
    { label: "ACCESSORIES", value: "accessories" },
    { label: "CHOCOLATE", value: "chocolate" },
    { label: "PEANUT", value: "peanut" },
  ];
  const handleMainTabClick = (item: any) => {
    setSelectedMainTab(item.id);
    selectedItem(item);
    setSelectedTabItem(item);
    if (!item.subMenu) {
      selectedTabTxt(item.title);
    } else if (item.subMenu && item.subMenu.length > 0) {
      setSelectedSubTab(item.subMenu[0].id);
      selectedTabTxt(item.subMenu[0].title);
    }
  };

  const handleSubTabClick = (subItem: any) => {
    setSelectedSubTab(subItem.id);
    selectedTabTxt(subItem.title);
  };

  // Set initial tab text
  // useEffect(() => {
  //   const initialItem = navItems[0];
  //   if (initialItem?.subMenu && initialItem?.subMenu.length > 0) {
  //     selectedTabTxt("");
  //   }
  // }, []);

  return (
    <div className="w-full">
      <div className="flex gap-3 items-center justify-between">
        {navItems.map((item) => (
          <button
            key={item.id}
            onClick={() => handleMainTabClick(item)}
            className={`w-full whitespace-nowrap px-6 py-2 rounded-xl text-sm font-medium transition-colors duration-200 ${
              selectedMainTab === item.id
                ? "bg-blue-400 text-white"
                : "border border-gray-300 text-gray-600 hover:bg-gray-100"
            }`}
          >
            {item.title}
          </button>
        ))}
      </div>

      {navItems.find((item) => item.id === selectedMainTab)?.subMenu && (
        <div className="flex gap-2 mt-3">
          {navItems
            .find((item) => item.id === selectedMainTab)
            ?.subMenu?.map((subItem: any) => (
              <button
                key={subItem?.id}
                onClick={() => handleSubTabClick(subItem)}
                className={`w-full whitespace-nowrap px-8 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                  selectedSubTab === subItem?.id
                    ? "bg-blue-600 text-white"
                    : "bg-gray-500 text-white hover:bg-gray-600"
                }`}
              >
                {subItem?.title}
              </button>
            ))}
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
        {/* <div className="sm:col-span-12 md:col-span-8 lg:col-span-8 space-y-4"> */}
        {selectedTabItem?.title === "Daily Sales" && selectedSubTab === 1 ? (
          <div className="sm:col-span-12 md:col-span-12 lg:col-span-12 space-y-4">
            <div className="bg-blue-50 p-4 mt-3 text-sm text-gray-600 italic text-center">
              <p>
                Information: All orders have been selected that have an invoice
                date recorded
              </p>
            </div>
          </div>
        ) : selectedTabItem?.title === "Daily Sales" && selectedSubTab === 2 ? (
          <div className="sm:col-span-12 md:col-span-12 lg:col-span-12 space-y-4">
            <div className="bg-blue-50 p-4 mt-3 text-sm text-gray-600 italic text-center">
              <p> Information: This is from EU_All_Orders and then Filtered</p>
            </div>
          </div>
        ) : selectedTabItem?.title === "Occassion Split" ? (
          <div className="sm:col-span-12 md:col-span-8 lg:col-span-12 space-y-4">
            <div className="bg-blue-50 p-4 mt-3 text-sm text-gray-600 italic text-center">
              <p>
                Information: All orders have been selected that have an invoice
                date recorded
              </p>
            </div>
          </div>
        ) : selectedTabItem?.title === "Product Split" ? (
          <div className="sm:col-span-12 md:col-span-8 lg:col-span-10 space-y-4">
            <div className="bg-blue-50 p-4 mt-3 text-sm text-gray-600 italic text-center">
              <p>
                Information: The NSV excludes taxes. Shipment costs and fees
                linked to initial orders are divided and assigned based on the
                percentage of product allocations compared to the order NSV.
              </p>
            </div>
          </div>
        ) : selectedTabItem?.title === "Promo Performance" ? (
          <div className="sm:col-span-12 md:col-span-8 lg:col-span-10 space-y-4">
            <div className="bg-blue-50 p-4 mt-3 text-sm text-gray-600 italic text-center">
              <p>
                Information: All orders have been selected that have an invoice
                date recorded
              </p>
            </div>
          </div>
        ) : null}
        {/* </div> */}
        {/* <div className="sm:col-span-12 md:col-span-4 lg:col-span-4 space-y-4"> */}
        {selectedTabItem?.title === "Product Split" ? (
          <div className="sm:col-span-12 md:col-span-4 lg:col-span-2 space-y-4">
            <div className="bg-gray-200 px-2.5 pb-1 w-full mt-3">
              <div className="text-xs text-gray-600">Product Flavour</div>
              <Select
                className="bg-gray-200"
                style={{ width: "100%" }}
                bordered={false}
                options={options}
                placeholder="All"
                allowClear
              />
            </div>
          </div>
        ) : selectedTabItem?.title === "Promo Performance" ? (
          <div className="sm:col-span-12 md:col-span-4 lg:col-span-2 space-y-4">
            <div className="bg-gray-200 px-2.5 pb-1 w-full mt-3">
              <div className="text-xs text-gray-600">
                Search by Discount Code
              </div>
              <Select
                className="bg-white"
                style={{ width: "100%" }}
                bordered={false}
                showSearch
                allowClear
                options={options}
                placeholder="Search"
              />
            </div>
          </div>
        ) : null}
        {/* </div> */}
      </div>
    </div>
  );
};

export default DemandNavigation;
