import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import Pagination from "./pagination";
import LoadingBox from "../../../components/LoadingBox";
import { ArrowUpDown } from "lucide-react";
const Carrier = (props: any) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [isLoading5, setIsLoading5] = useState(false);
  const [isLoading6, setIsLoading6] = useState(false);
  const [carrierOrderData, setCarrierOrderData] = useState([]);
  const [deliveryModeData, setDeliveryModeData] = useState([]);
  const [dispatchedData, setDispatchedData] = useState([]);
  const [deliveryData, setDeliveryData] = useState([]);
  const [pieData2, setPieData2] = useState([]);
  const [countryNsvData, setCountryNsvData] = useState<any>([]);
  console.log("countryNsvData ...", countryNsvData);
  const [totalsCarrierDelivery, setTotalsCarrierDelivery] = useState({
    total_orders: 0,
    total_packages: 0,
    percentage: 0,
  });
  const [totalsCountryNsv, setTotalsCountryNsv] = useState({
    totalPackages: 0,
    totalNSV: 0,
  });
  const [currentPageCarrierDelivery, setCurrentPageCarrierDelivery] =
    useState(1);
  const [itemsPerPageCarrierDelivery] = useState(10);
  const [totalPagesCarrierDelivery, setTotalPagesCarrierDelivery] = useState(1);
  const [totalRowsCarrierDelivery, setTotalRowsCarrierDelivery] = useState(0);
  const [currentPageCountryNsv, setCurrentPageCountryNsv] = useState(1);
  const [itemsPerPageCountryNsv] = useState(10);
  const [totalPagesCountryNsv, setTotalPagesCountryNsv] = useState(1);
  const [totalRowsCountryNsv, setTotalRowsCountryNsv] = useState(0);
  const [sort1, setSort1] = useState("carrierName"); // Default sort column
  const [order1, setOrder1] = useState<"ASC" | "DESC">("ASC"); // Default order
  const [sort, setSort] = useState("carrierName"); // Default sort column
  const [order, setOrder] = useState<"ASC" | "DESC">("ASC"); // Default order

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [props?.item]);

  const COLORS = ["#1E88E5", "#FF5722", "#673AB7", "#2E7D32"];

  const formatNumber = (value: any) => {
    return value.toLocaleString();
  };

  const formatCurrency = (value: any) => {
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  //1
  const fetchCarrierOrdersCount = async (filterValues: any = {}) => {
    try {
      setIsLoading1(true);
      const response: any = await POST(
        API.CARRIER_ORDERS_INVOICE,
        filterValues
      );
      if (response) {
        const filteredData = response?.filter(
          (item: any) => item.name !== null && item.percentage > 0
        );
        setCarrierOrderData(filteredData);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading1(false);
    }
  };

  //2
  const fetchDeliveryModes = async (filterValues: any = {}) => {
    try {
      setIsLoading2(true);
      const response: any = await POST(
        API.CARRIER_DELIVERY_MODES,
        filterValues
      );

      if (response) {
        // Filter out null carriers
        const filteredResponse: any = response.filter(
          (item: any) => item.carrier !== null
        );

        // Transform data
        const transformedData: any = Object.values(
          filteredResponse.reduce((acc: any, { mode, carrier, count }: any) => {
            if (!acc[carrier]) {
              acc[carrier] = {
                name: carrier,
                Express: 0,
                Standard: 0,
              };
            }
            acc[carrier][mode] = count;
            return acc;
          }, {})
        );

        setDeliveryModeData(transformedData);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading2(false);
    }
  };
  //3
  const fetchMonthlyDispatchData = async (filterValues: any = {}) => {
    try {
      setIsLoading3(true);
      const response: any = await POST(
        API.MONTHLY_DISPATCHED_ORDERS,
        filterValues
      );

      if (response) {
        const sortedData = response.filter((item: any) => item.period);
        setDispatchedData(sortedData);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading3(false);
    }
  };
  //4
  const fetchCarrierDeliveryCount = async (
    filterValues = {},
    page = currentPageCarrierDelivery
  ) => {
    try {
      setIsLoading4(true);
      const response: any = await POST(API.CARRIER_DELIVERY_COUNT, {
        ...filterValues,
        page,
        limit: itemsPerPageCarrierDelivery,
        sort1,
        order1,
      });

      if (response?.rows) {
        const formattedData = response.rows.map((item: any) => ({
          carrierName: item.carrier,
          mode: item.mode,
          orderCount: item.order_count,
          carrierPackages: item.package_count,
          orderTotal: `${item.percentage.toFixed(2)}%`,
        }));

        setDeliveryData(formattedData);
        setTotalsCarrierDelivery(response.totals);
        setTotalPagesCarrierDelivery(response.totalPages);
        setTotalRowsCarrierDelivery(response.totalRows);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading4(false);
    }
  };

  //5
  const fetchCarrierModeStats = async (filterValues: any = {}) => {
    try {
      setIsLoading5(true);
      const response: any = await POST(API.CARRIER_MODE_STATS, filterValues);

      if (response) {
        setPieData2(response);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading5(false);
    }
  };
  //6
  const fetchCarrierCountryStats = async (
    filterValues = {},
    page = currentPageCountryNsv
  ) => {
    try {
      setIsLoading6(true);
      const response: any = await POST(API.CARRIER_COUNTRY_STATS_NSV, {
        ...filterValues,
        page,
        limit: itemsPerPageCountryNsv,
        sort,
        order,
      });

      if (response?.rows) {
        setCountryNsvData(response.rows);
        setTotalsCountryNsv(response.totals);
        setTotalPagesCountryNsv(response.totalPages);
        setTotalRowsCountryNsv(response.totalRows);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading6(false);
    }
  };

  useEffect(() => {
    fetchCarrierOrdersCount(props?.filters);
  }, [props?.filters]);
  useEffect(() => {
    fetchDeliveryModes(props?.filters);
  }, [props?.filters]);
  useEffect(() => {
    fetchMonthlyDispatchData(props?.filters);
  }, [props?.filters]);
  useEffect(() => {
    fetchCarrierDeliveryCount(props?.filters);
  }, [props?.filters, currentPageCarrierDelivery, sort1, order1]);
  useEffect(() => {
    fetchCarrierModeStats(props?.filters);
  }, [props?.filters]);
  useEffect(() => {
    fetchCarrierCountryStats(props?.filters);
  }, [props?.filters, currentPageCountryNsv, sort, order]);

  const handleSort1 = (column: string) => {
    console.log("column ...", column);
    if (sort1 === column) {
      // Toggle order
      setOrder1(order1 === "ASC" ? "DESC" : "ASC");
    } else {
      // Set new sort column
      setSort1(column);
      setOrder1("ASC"); // Default to ascending order for new column
    }
  };
  const handleSort = (column: string) => {
    if (sort === column) {
      // Toggle order
      setOrder(order === "ASC" ? "DESC" : "ASC");
    } else {
      // Set new sort column
      setSort(column);
      setOrder("ASC"); // Default to ascending order for new column
    }
  };
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
        <div className="sm:col-span-12 md:col-span-4">
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">
              Carrier Invoiced Orders Count
            </h3>
            {isLoading1 ? (
              <LoadingBox />
            ) : (
              <div className="w-full h-[400px]">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={carrierOrderData}
                      cx="50%"
                      cy="50%"
                      outerRadius={isMobile ? 50 : 100}
                      dataKey="value"
                      label={({ name, percentage }) =>
                        `${name} (${percentage.toFixed(2)}%)`
                      }
                    >
                      {carrierOrderData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            )}
          </div>
        </div>

        {/* Carriers' Delivery Mode */}
        <div className="sm:col-span-12 md:col-span-4">
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">
              Carriers' Delivery Mode (Invoiced Orders)
            </h3>
            {isLoading2 ? (
              <LoadingBox />
            ) : (
              <div className="w-full h-[400px]">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={deliveryModeData} barSize={40}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" axisLine={false} />
                    <YAxis
                      axisLine={false}
                      tickFormatter={(value) => `${value}`}
                      label={{
                        // value: "Count of Order number",
                        angle: -90,
                        position: "insideLeft",
                        dx: 0,
                        dy: 20,
                        style: { textAnchor: "middle", fontSize: "12px" },
                      }}
                    />
                    <Tooltip cursor={false} />
                    <Legend />
                    <Bar
                      style={{ padding: 10 }}
                      className="www"
                      dataKey="Express"
                      stackId="a"
                      fill="#2196F3"
                    />
                    <Bar dataKey="Standard" stackId="a" fill="#1a237e" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )}
          </div>
        </div>

        {/* Dispatched Invoiced Orders */}
        <div className="sm:col-span-12 md:col-span-4">
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">
              Dispatched Invoiced Orders
            </h3>
            {isLoading3 ? (
              <LoadingBox />
            ) : (
              <div className="w-full h-[400px]">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={dispatchedData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="period"
                      angle={-90}
                      textAnchor="end"
                      height={100}
                      axisLine={false} // Hides the X-axis line
                    />
                    <YAxis
                      axisLine={false} // Hides the Y-axis line
                      tickLine={false} // Hides tick lines but keeps values
                      tickFormatter={(value) => `${value / 1000}k`}
                      label={{
                        value: "Count of Dispatched to Carried",
                        angle: -90,
                        position: "insideLeft",
                        dx: 0, // Slight horizontal adjustment
                        dy: 20, // Moves the label downward
                        style: { textAnchor: "middle", fontSize: "12px" }, // Optional styling
                      }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#2196F3"
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
          </div>
        </div>

        <div className="sm:col-span-12 md:col-span-4">
          <div className="bg-white rounded-lg shadow">
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold text-gray-700">
                Carrier Delivery Count (Invoiced Orders)
              </h2>
            </div>
            <div className="overflow-x-auto">
              {isLoading4 ? (
                <LoadingBox />
              ) : (
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 cursor-pointer"
                        onClick={() => handleSort1("carrierName")}
                      >
                        <div className="flex items-center space-x-1">
                          CarrierName{" "}
                          {/* <ArrowUpDown
                            className={`h-4 w-4 ${
                              sort1 === "carrierName" && order1 === "ASC"
                                ? "rotate-180"
                                : ""
                            }`}
                          /> */}
                        </div>
                      </th>
                      <th
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 cursor-pointer"
                        onClick={() => handleSort1("mode")}
                      >
                        <div className="flex items-center space-x-1">
                          Mode{" "}
                          {/* <ArrowUpDown
                            className={`h-4 w-4 ${
                              sort1 === "mode" && order1 === "ASC"
                                ? "rotate-180"
                                : ""
                            }`}
                          /> */}
                        </div>
                      </th>
                      <th
                        className="px-4 py-2 text-right text-xs font-medium text-gray-500 cursor-pointer"
                        onClick={() => handleSort1("mode")}
                      >
                        <div className="flex items-center space-x-1">
                          Order Count{" "}
                          {/* <ArrowUpDown
                            className={`h-4 w-4 ${
                              sort1 === "order" && order1 === "ASC"
                                ? "rotate-180"
                                : ""
                            }`}
                          /> */}
                        </div>
                      </th>
                      <th
                        className="px-4 py-2 text-right text-xs font-medium text-gray-500 cursor-pointer"
                        onClick={() => handleSort1("mode")}
                      >
                        <div className="flex items-center space-x-1">
                          Carrier Packages
                          {/* <ArrowUpDown
                            className={`h-4 w-4 ${
                              sort1 === "carrierPakage" && order1 === "ASC"
                                ? "rotate-180"
                                : ""
                            }`}
                          /> */}
                        </div>
                      </th>
                      <th
                        className="px-4 py-2 text-right text-xs font-medium text-gray-500 cursor-pointer"
                        onClick={() => handleSort1("mode")}
                      >
                        <div className="flex items-center space-x-1">
                          % of Order Total
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {deliveryData.map((row: any, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-4 py-2 text-sm text-gray-600">
                          {row.carrierName}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-600">
                          {row.mode}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-600">
                          {formatNumber(row.orderCount)}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-600">
                          {formatNumber(row.carrierPackages)}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-600">
                          {row.orderTotal}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600 text-white">
                      <td colSpan={2} className="px-4 py-2 text-sm font-bold">
                        Total
                      </td>
                      <td className="px-4 py-2 text-sm font-bold">
                        {formatNumber(totalsCarrierDelivery?.total_orders)}
                      </td>
                      <td className="px-4 py-2 text-sm font-bold">
                        {formatNumber(totalsCarrierDelivery?.total_packages)}
                      </td>
                      <td className="px-4 py-2 text-sm font-bold">
                        {totalsCarrierDelivery?.percentage.toFixed(2)}%
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPageCarrierDelivery}
              setCurrentPage={setCurrentPageCarrierDelivery}
              totalPages={totalPagesCarrierDelivery}
              totalRows={totalRowsCarrierDelivery}
              itemsPerPage={itemsPerPageCarrierDelivery}
            />
          </div>
        </div>
        <div className="sm:col-span-12 md:col-span-4">
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-4 text-gray-700">
              Carrier Invoiced Orders Count
            </h3>
            {isLoading5 ? (
              <LoadingBox />
            ) : (
              <div className="w-full h-[400px]">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={pieData2 || []}
                      cx="50%"
                      cy="50%"
                      outerRadius={isMobile ? 50 : 100} // Adjust radius based on screen size
                      dataKey="value"
                      label={({ name, percentage }) =>
                        `${name} (${percentage.toFixed(2)}%)`
                      }
                    >
                      {pieData2?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                      ))}
                    </Pie>
                    <Tooltip /> <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            )}
          </div>
        </div>
        <div className="sm:col-span-12 md:col-span-4">
          <div className="bg-white rounded-lg shadow">
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold text-gray-700">
                Carrier by Country Invoiced NSV
              </h2>
            </div>
            <div className="overflow-x-auto">
              {isLoading6 ? (
                <LoadingBox />
              ) : (
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      {/* CarrierName Column */}
                      <th
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 cursor-pointer"
                        onClick={() => handleSort("carrierName")}
                      >
                        <div className="flex items-center space-x-1">
                          CarrierName
                          <ArrowUpDown
                            className={`h-4 w-4 ${
                              sort === "carrierName" && order === "ASC"
                                ? "rotate-180"
                                : ""
                            }`}
                          />
                        </div>
                      </th>

                      {/* Country Column */}
                      <th
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 cursor-pointer"
                        onClick={() => handleSort("country")}
                      >
                        <div className="flex items-center space-x-1">
                          Country
                          <ArrowUpDown
                            className={`h-4 w-4 ${
                              sort === "country" && order === "ASC"
                                ? "rotate-180"
                                : ""
                            }`}
                          />
                        </div>
                      </th>

                      {/* Carrier Packages Column */}
                      <th
                        className="px-4 py-2 text-right text-xs font-medium text-gray-500 cursor-pointer"
                        onClick={() => handleSort("carrierPackages")}
                      >
                        <div className="flex items-center space-x-1">
                          Carrier Packages
                          <ArrowUpDown
                            className={`h-4 w-4 ${
                              sort === "carrierPackages" && order === "ASC"
                                ? "rotate-180"
                                : ""
                            }`}
                          />
                        </div>
                      </th>

                      {/* Shipped NSV Column */}
                      <th
                        className="px-4 py-2 text-right text-xs font-medium text-gray-500 cursor-pointer"
                        onClick={() => handleSort("shippedNSV")}
                      >
                        <div className="flex items-center">
                          Shipped NSV
                          <ArrowUpDown
                            className={`h-4 w-4 ${
                              sort === "shippedNSV" && order === "ASC"
                                ? "rotate-180"
                                : ""
                            }`}
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200">
                    {countryNsvData?.map((row: any, index: number) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-4 py-2 text-sm text-gray-600">
                          {row?.carrierName}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-600">
                          {row?.country}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-600">
                          {/* {row?.carrierPackages} */}
                          {formatNumber(row?.carrierPackages)}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-600">
                          {new Intl.NumberFormat("en-EU", {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(row.shippedNSV)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600 text-white">
                      <td colSpan={2} className="px-4 py-2 text-sm font-bold">
                        Total
                      </td>
                      <td className="px-4 py-2 text-sm font-bold">
                        {formatNumber(totalsCountryNsv.totalPackages)}
                      </td>
                      <td className="px-4 py-2 text-sm font-bold">
                        {formatCurrency(totalsCountryNsv.totalNSV)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={currentPageCountryNsv}
              setCurrentPage={setCurrentPageCountryNsv}
              totalPages={totalPagesCountryNsv}
              totalRows={totalRowsCountryNsv}
              itemsPerPage={itemsPerPageCountryNsv}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carrier;
