import React, { useState, useEffect } from "react";

const DemandNavigation = ({
  selectedTabTxt,
  selectedItem,
}: {
  selectedTabTxt: (val: string) => void;
  selectedItem: (val: string) => void;
}) => {
  const [selectedMainTab, setSelectedMainTab] = useState(1);
  const [selectedSubTab, setSelectedSubTab] = useState(1);
  const [selectedTabItem, setSelectedTabItem] = useState<any>({
    id: 1,
    title: "Daily Sales",
  });
  const navItems = [
    {
      id: 1,
      title: "Daily Demand",
      subMenu: [
        { id: 1, title: "Demand by Date" },
        { id: 2, title: "Demand by Country" },
        { id: 3, title: "Demand (Web with GA4)" },
        { id: 4, title: "Demand - GA4 Traffic Overview" },
        // { id: 5, title: "Demand Orders Conversion" },
      ],
    },
    { id: 2, title: "Daily Demand Agencies" },
    { id: 3, title: "Demand - Occasion Split" },
    { id: 4, title: "Demand - Promo Performance" },
    { id: 5, title: "Demand - Products Split" },
  ];

  const handleMainTabClick = (item: any) => {
    setSelectedMainTab(item.id);
    selectedItem(item);
    setSelectedTabItem(item);
    if (!item.subMenu) {
      selectedTabTxt(item.title);
    } else if (item.subMenu && item.subMenu.length > 0) {
      setSelectedSubTab(item.subMenu[0].id);
      selectedTabTxt(item.subMenu[0].title);
    }
  };

  const handleSubTabClick = (subItem: any) => {
    setSelectedSubTab(subItem.id);
    selectedTabTxt(subItem.title);
  };

  // Set initial tab text
  useEffect(() => {
    const initialItem = navItems[0];
    if (initialItem.subMenu && initialItem.subMenu.length > 0) {
      selectedTabTxt(initialItem.subMenu[0].title);
    }
  }, []);

  return (
    <div className="w-full overflow-x-auto">
      <div className="flex gap-3 items-center justify-between">
        {navItems.map((item) => (
          <button
            key={item.id}
            onClick={() => handleMainTabClick(item)}
            className={`w-full whitespace-nowrap px-6 py-2 rounded-xl text-sm font-medium transition-colors duration-200 ${
              selectedMainTab === item.id
                ? "bg-blue-400 text-white"
                : "border border-gray-300 text-gray-600 hover:bg-gray-100"
            }`}
          >
            {item.title}
          </button>
        ))}
      </div>
      {navItems.find((item) => item.id === selectedMainTab)?.subMenu && (
        <div className="flex gap-2 mt-3">
          {navItems
            .find((item) => item.id === selectedMainTab)
            ?.subMenu?.map((subItem) => (
              <button
                key={subItem.id}
                onClick={() => handleSubTabClick(subItem)}
                className={`w-full whitespace-nowrap px-8 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                  selectedSubTab === subItem.id
                    ? "bg-blue-600 text-white"
                    : "bg-gray-500 text-white hover:bg-gray-600"
                }`}
              >
                {subItem.title}
              </button>
            ))}
        </div>
      )}
    </div>
  );
};

export default DemandNavigation;
