import { useState, useEffect, useMemo } from "react";
import { Button, Input } from "antd";
import { ArrowUpDown, Edit2, Search, Settings, X } from "lucide-react";
import LoadingBox from "../../components/LoadingBox";

interface ScheduleRow {
  compositionType: string;
  quantity: number;
}

const ScheduledMachine = () => {
  const columns: { key: keyof ScheduleRow; label: string }[] = [
    { key: "compositionType", label: "Composition Type" },
    { key: "quantity", label: "Quantity" },
  ];

  const initialData: ScheduleRow[] = [
    { compositionType: "600G", quantity: 650 },
    { compositionType: "400G", quantity: 450 },
    { compositionType: "700G", quantity: 750 },
    { compositionType: "500G", quantity: 550 },
    { compositionType: "300G", quantity: 350 },
  ];

  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);
  const [formData, setFormData] = useState<ScheduleRow | null>(initialData[0]);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState<ScheduleRow[]>(initialData);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof ScheduleRow | null;
    direction: "ascending" | "descending";
  }>({
    key: null,
    direction: "ascending",
  });
  const [sortLoading, setSortLoading] = useState(false);

  useEffect(() => {
    if (data?.length > 0) {
      setSelectedRowIndex(0);
      setFormData(data[0]);
    }
  }, []);

  const handleSort = async (key: keyof ScheduleRow) => {
    setSortLoading(true);

    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    // Simulate API sorting delay
    await new Promise((resolve) => setTimeout(resolve, 500));

    setSortConfig({ key, direction });
    setSortLoading(false);
  };

  const sortedData = useMemo(() => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      const aValue = a[sortConfig.key!];
      const bValue = b[sortConfig.key!];

      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortConfig.direction === "ascending"
          ? aValue - bValue
          : bValue - aValue;
      }

      if (aValue < bValue) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }, [data, sortConfig]);
  useEffect(() => {
    if (selectedRowIndex >= 0) {
      setFormData(sortedData[selectedRowIndex]);
    }
  }, [selectedRowIndex, sortedData]);

  const handleRowClick = (row: ScheduleRow, index: number) => {
    setSelectedRowIndex(index);
  };

  const handleInputChange = (
    key: keyof ScheduleRow,
    value: string | number
  ) => {
    if (formData) {
      setFormData({
        ...formData,
        [key]: key === "quantity" ? Number(value) || 0 : value,
      });
    }
  };

  return (
    <div className="p-6">
      <div className="bg-white rounded-lg border border-gray-200 shadow-sm p-4">
        <div className="flex justify-between items-center pb-2 mb-2">
          <div className="text-xl font-semibold">Scheduling Machine</div>
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-4">
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-600">Show</span>
            <select className="px-2 py-1 border border-gray-300 rounded-md text-sm">
              {[10, 25, 50, 100].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <span className="text-sm text-gray-600">entries</span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
          <div
            className={`sm:col-span-12 ${
              formData ? "md:col-span-8" : "md:col-span-12"
            }`}
          >
            <div className="overflow-x-auto shadow-sm rounded-lg relative">
              {sortLoading ? (
                <LoadingBox />
              ) : (
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      {columns.map(({ key, label }) => (
                        <th
                          key={key}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <button
                            className="flex items-center space-x-1 w-full hover:text-gray-700"
                            onClick={() => handleSort(key)}
                            disabled={sortLoading}
                          >
                            <span>{label}</span>
                            <ArrowUpDown
                              className={`h-4 w-4 ${
                                sortConfig.key === key ? "text-blue-600" : ""
                              }`}
                            />
                          </button>
                        </th>
                      ))}
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {sortedData.map((row, index) => (
                      <tr
                        key={index}
                        onClick={() => handleRowClick(row, index)}
                        className={`cursor-pointer transition-colors duration-150 ${
                          index === selectedRowIndex
                            ? "bg-blue-100"
                            : "hover:bg-gray-50"
                        }`}
                        style={{
                          borderLeft:
                            index === selectedRowIndex
                              ? "4px solid rgb(37 99 235 / var(--tw-bg-opacity, 1))"
                              : "",
                        }}
                      >
                        {columns.map(({ key }) => (
                          <td
                            key={key}
                            className={`px-6 py-3 whitespace-nowrap text-sm ${
                              index === selectedRowIndex
                                ? "text-blue-700"
                                : "text-gray-600"
                            }`}
                          >
                            {row[key]}
                          </td>
                        ))}
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <div className="flex items-center gap-3">
                            <button
                              className="text-blue-600"
                              disabled={sortLoading}
                            >
                              <Edit2 className="h-5 w-5" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          <div className="sm:col-span-12 md:col-span-4">
            <div
              className={`${
                formData ? "block" : "invisible"
              } bg-white rounded-lg shadow-sm h-fit`}
            >
              <div className="p-4 bg-gray-200 rounded-t-lg">
                <h3 className="text-md font-medium text-center text-gray-600">
                  Manage Scheduling Machine
                </h3>
              </div>
              {formData && (
                <div className="p-6 space-y-4">
                  {columns.map(({ key, label }) => (
                    <div key={key}>
                      <label className="block text-sm text-gray-500 mb-1">
                        {label}:
                      </label>
                      <Input
                        size="large"
                        className="w-full"
                        value={formData[key]}
                        type={key === "quantity" ? "number" : "text"}
                        onChange={(e) =>
                          handleInputChange(
                            key,
                            key === "quantity"
                              ? parseInt(e.target.value) || 0
                              : e.target.value
                          )
                        }
                        disabled={sortLoading}
                      />
                    </div>
                  ))}
                  <div className="flex gap-4 pt-4">
                    <Button
                      size="large"
                      className="flex-1 rounded"
                      type="default"
                      danger
                      onClick={() => {
                        setSelectedRowIndex(-1);
                        setFormData(null);
                      }}
                      disabled={sortLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      className="flex-1 rounded"
                      size="large"
                      onClick={() => {
                        if (selectedRowIndex >= 0 && formData) {
                          const newData = [...data];
                          newData[selectedRowIndex] = formData;
                          setData(newData);
                          setSelectedRowIndex(-1);
                          setFormData(null);
                        }
                      }}
                      disabled={sortLoading}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduledMachine;
