import { useEffect, useMemo, useState } from "react";
import { Form, Button, Checkbox, Select, Row, Col } from "antd";
import { useSelector } from "react-redux";
import Filters from "../../components/filters";
import { GET, POST } from "../../utils/apiCalls";
import API from "../../config/api";
import moment from "moment";
import {
  Activity,
  BanknoteIcon,
  PackageIcon,
  PercentIcon,
  ShoppingCartIcon,
  TrendingUpIcon,
  TruckIcon,
  XCircleIcon,
  FileText,
  ChevronRight,
  Settings,
  ArrowUpDown,
  Eye,
} from "lucide-react";
import { setFilters } from "../../redux/slices/filterSlice";
import LoadingBox from "../../components/LoadingBox";

interface KanbanRow {
  exclude?: boolean;
  fsd: string;
  entryDate: string;
  ead: string;
  total: string;
  customer: string;
  orders: string;
  status: string;
  country: string;
  channel: string;
  plant: string;
  composition: string;
}

const PrintKanban = () => {
  const [form] = Form.useForm();
  const { filters } = useSelector((state: any) => state.Filter);
  const [salesChannel2Options, setSalesChannel2Options] = useState<any>({});
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [metrics, setMetrics] = useState<any>(null);
  const [orders, setOrders] = useState<any>(null);
  const [loadingMetrics, setLoadingMetrics] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [loadingCounts, setLoadingCounts] = useState(false);
  const [error, setError] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);
  const [sortConfig, setSortConfig] = useState<any>({
    key: null,
    direction: "ascending",
  });
  const [loading, setLoading] = useState(true);
  const [sortLoading, setSortLoading] = useState(false);
  const [data, setData] = useState([
    {
      exclude: false,
      fsd: "13511",
      entryDate: "04/07/2022",
      ead: "12/06/2022",
      total: "10756 €",
      customer: "[REDACTED] [REDACTED]",
      orders: "50295725",
      status: "BAPR",
      country: "IT",
      channel: "B2B",
      plant: "Yes",
      composition: "170G=300",
    },
    {
      exclude: false,
      fsd: "13509",
      entryDate: "02/07/2022",
      ead: "10/06/2022",
      total: "8756 €",
      customer: "[REDACTED] [REDACTED]",
      orders: "50295723",
      status: "BAPR",
      country: "FR",
      channel: "B2B",
      plant: "Yes",
      composition: "170G=100",
    },
    {
      exclude: false,
      fsd: "13510",
      entryDate: "03/07/2022",
      ead: "11/06/2022",
      total: "9756 €",
      customer: "[REDACTED] [REDACTED]",
      orders: "50295724",
      status: "BAPR",
      country: "DE",
      channel: "B2C",
      plant: "No",
      composition: "170G=200",
    },

    {
      exclude: false,
      fsd: "13511",
      entryDate: "04/07/2022",
      ead: "12/06/2022",
      total: "10756 €",
      customer: "[REDACTED] [REDACTED]",
      orders: "50295725",
      status: "BAPR",
      country: "IT",
      channel: "B2B",
      plant: "Yes",
      composition: "170G=300",
    },
    {
      exclude: false,
      fsd: "13511",
      entryDate: "04/07/2022",
      ead: "12/06/2022",
      total: "10756 €",
      customer: "[REDACTED] [REDACTED]",
      orders: "50295725",
      status: "BAPR",
      country: "IT",
      channel: "B2B",
      plant: "Yes",
      composition: "170G=300",
    },
  ]);

  const [leftMetrics, setLeftMetrics] = useState([
    {
      icon: <ShoppingCartIcon className="w-5 h-5 text-amber-500" />,
      value: 0,
      label: "Total Demand Orders",
    },
    {
      icon: <BanknoteIcon className="w-5 h-5 text-blue-600" />,
      value: 0,
      label: "Total Invoiced Orders",
    },
    {
      icon: <TruckIcon className="w-5 h-5 text-green-600" />,
      value: 0,
      label: "Total Shipped Orders",
    },
    {
      icon: <XCircleIcon className="w-5 h-5 text-red-500" />,
      value: 0,
      label: "Total Cancelled Orders",
    },
    {
      icon: <TrendingUpIcon className="w-5 h-5 text-purple-500" />,
      value: 0,
      label: "Total Refunded Orders",
    },
    {
      icon: <Activity className="w-5 h-5 text-yellow-500" />,
      value: 0,
      label: "Total Demand NSV",
    },
    {
      icon: <PercentIcon className="w-5 h-5 text-green-500" />,
      value: 0,
      label: "Total Invoiced NSV",
    },
    {
      icon: <PackageIcon className="w-5 h-5 text-blue-500" />,
      value: 0,
      label: "Total Shipped NSV",
    },
    {
      icon: <BanknoteIcon className="w-5 h-5 text-red-500" />,
      value: 0,
      label: "Total Cancelled NSV",
    },
    {
      icon: <TrendingUpIcon className="w-5 h-5 text-orange-500" />,
      value: 0,
      label: "Total Refunded NSV",
    },
  ]);
  useEffect(() => {
    const initializeDashboard = async () => {
      try {
        if (!isInitialLoad) return; // Prevent multiple calls
        const currentYear = "2024";
        form.setFieldsValue({ year: [currentYear] });

        await getFilters();

        const initialData = {
          year: [currentYear],
        };

        await Promise.all([
          fetchMetrics(initialData),
          fetchCounts(initialData),
          fetchOrders(initialData),
        ]);
      } catch (error) {
        console.error("Error initializing dashboard:", error);
        setError(true);
      }
    };

    initializeDashboard();
  }, []);
  const fetchCounts = async (filterValues: any = {}) => {
    try {
      setLoadingCounts(true);
      const response: any = await POST(API.DASHBOARD_COUNTS, filterValues);

      if (response?.data) {
        // Update left metrics with the response data
        const updatedMetrics = [
          {
            icon: <ShoppingCartIcon className="w-5 h-5 text-amber-500" />,
            value: response.data.demandorders,
            label: "Total Demand Orders",
          },
          {
            icon: <BanknoteIcon className="w-5 h-5 text-blue-600" />,
            value: response.data.invoicedorders,
            label: "Total Invoiced Orders",
          },
          {
            icon: <TruckIcon className="w-5 h-5 text-green-600" />,
            value: response.data.shippedorders,
            label: "Total Shipped Orders",
          },
          {
            icon: <XCircleIcon className="w-5 h-5 text-red-500" />,
            value: response.data.cancelledorders,
            label: "Total Cancelled Orders",
          },
          {
            icon: <TrendingUpIcon className="w-5 h-5 text-purple-500" />,
            value: response.data.refundedorders,
            label: "Total Refunded Orders",
          },
          {
            icon: <Activity className="w-5 h-5 text-yellow-500" />,
            value: response.data.demandnsv,
            label: "Total Demand NSV",
          },
          {
            icon: <PercentIcon className="w-5 h-5 text-green-500" />,
            value: response.data.invoicednsv,
            label: "Total Invoiced NSV",
          },
          {
            icon: <PackageIcon className="w-5 h-5 text-blue-500" />,
            value: response.data.shippednsv,
            label: "Total Shipped NSV",
          },
          {
            icon: <BanknoteIcon className="w-5 h-5 text-red-500" />,
            value: response.data.cancellednsv,
            label: "Total Cancelled NSV",
          },
          {
            icon: <TrendingUpIcon className="w-5 h-5 text-orange-500" />,
            value: response.data.refundednsv,
            label: "Total Refunded NSV",
          },
        ];

        setLeftMetrics(updatedMetrics);
        setError(false);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
      setError(true);
    } finally {
      setLoadingCounts(false);
    }
  };
  const fetchOrders = async (filterValues: any = {}) => {
    try {
      setLoadingOrder(true);

      const response: any = await POST(API.DASHBOARD_ORDERS, filterValues);

      if (response?.data) {
        setOrders(response.data);
        setError(false);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
      setError(true);
    } finally {
      setLoadingOrder(false);
    }
  };
  const getFilters = async () => {
    try {
      if (filters.length > 0) {
        return;
      }
      const response: any = await GET(API.FILTERS);

      if (response.length) {
        dispatch(setFilters(response));
      }

      const initialValues: any = {
        year: [],
        periods: [],
        weeks: [],
        country: [],
        channel: [],
        salesChannel: [],
      };
      form.setFieldsValue(initialValues);
    } catch (error) {
      setError(true);
      console.log(error);
    }
  };
  const fetchMetrics = async (filterValues: any = {}) => {
    try {
      setLoadingMetrics(true);
      const response: any = await POST(API.DASHBOARD_METRICS, filterValues);
      if (response?.data) {
        setMetrics(response.data);
        setError(false);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
      setError(true);
    } finally {
      setLoadingMetrics(false);
    }
  };
  const handleFormChange = async (changedValues: any, allValues: any) => {
    try {
      if (changedValues.salesChannel !== undefined) {
        if (
          changedValues.salesChannel &&
          changedValues.salesChannel.length > 0
        ) {
          const response = await POST(API.FILTERSBYCHANNEL, {
            channel: changedValues.salesChannel,
          });
          setSalesChannel2Options(response);
          // setIsSalesChannel2Disabled(false);
        } else {
          form.setFieldsValue({
            salesChannel: [],
            salesChannel2: [],
          });
          setSalesChannel2Options({});
          // setIsSalesChannel2Disabled(true);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const columns: { key: keyof KanbanRow; label: string }[] = [
    { key: "exclude", label: "Order to exclude" },
    { key: "fsd", label: "FSD" },
    { key: "entryDate", label: "Entry Date" },
    { key: "ead", label: "EAD" },
    { key: "total", label: "Total" },
    { key: "customer", label: "Customer" },
    { key: "orders", label: "Orders" },
    { key: "status", label: "Order Status" },
    { key: "country", label: "Destination Country" },
    { key: "channel", label: "Channel" },
    { key: "plant", label: "Plant deliv ?" },
    { key: "composition", label: "Composition" },
  ];

  // const data: KanbanRow[] = [

  const handleRowClick = (index: number) => {
    setSelectedRowIndex(index);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Simulate loading time
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSort = async (key: any) => {
    setSortLoading(true);
    let direction = "ascending";

    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    // Simulate API sorting delay
    await new Promise((resolve) => setTimeout(resolve, 500));

    setSortConfig({ key, direction });
    setSortLoading(false);
  };

  const sortedData = useMemo(() => {
    if (!sortConfig.key) return data;

    return [...data].sort((a: any, b: any) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }, [data, sortConfig]);

  if (loading) {
    return <LoadingBox />;
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center pb-2 border-b">
        <div className="text-xl font-semibold">Printing Kanban</div>
      </div>
      <br />
      <Row>
        <Col span={14}>
          <div
            style={{ fontFamily: "AllTogetherSansW05-Bold", fontWeight: 900 }}
          >
            COMMAND SELECTION FILTER (LINE 1)
          </div>
          <div className="breadcums-txt2 mt-2" style={{ fontSize: 12 }}>
            Select the item to exclude...Use the "Ctrl" key to select in each
            control the items to exclude...
          </div>
        </Col>
        <Col span={10}>
          <div>
            <div
              className="mb-1"
              style={{
                fontWeight: "bold",
                fontFamily: "AllTogetherSansW05-Bold",
              }}
            >
              Available Pre-definitions
            </div>
            <Row className="mb-3">
              <Col span={12}>
                <div
                  style={{
                    fontFamily: "AllTogetherSansW05-Bold",
                  }}
                  className="mt-2 text-gray fontsize-[13px]"
                >
                  <span className="flex items-center gap-1">
                    <ChevronRight size={15} color="grey" /> Orders with product
                    to manufacture
                  </span>

                  <span className="flex items-center gap-1 mt-1">
                    <ChevronRight size={15} color="grey" /> All BAPR orders
                  </span>
                </div>
              </Col>
              <Col span={12}>
                <div
                  style={{
                    fontFamily: "AllTogetherSansW05-Bold",
                  }}
                  className="mt-2 text-gray fontsize-[13px]"
                >
                  <span className="flex items-center gap-1">
                    <ChevronRight size={15} color="grey" /> Orders with only
                    product no.
                  </span>

                  <span className="flex items-center gap-1 mt-1">
                    <ChevronRight size={15} color="grey" /> FR+Relais Parcel
                    orders in BAPR
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <div className="flex items-center bg-blue-50 px-4 pt-4 mb-3">
        <Filters
          filters={filters}
          form={form}
          handleFormChange={handleFormChange}
          salesChannel2Options={salesChannel2Options}
        />
      </div>
      <br />
      <br />
      <Form layout="inline">
        <Row gutter={[16, 16]}>
          {[
            "Country dest",
            "Special Type",
            "Show lineup",
            "Relay package",
            "Planet deliv",
            "poids (kg)",
            "Premix",
            "Incl RPRT",
            "Channel",
            "+/-",
            "Show colors",
            "Comp Qte",
            "Delivery Type",
            "Faces",
            "Express delivery",
          ].map((label) => (
            <Col xxl={6} xl={8} lg={12} md={12} sm={24} xs={24} key={label}>
              <Form.Item label={label}>
                <Select style={{ width: "100%" }}>
                  <Select.Option value="1">Option 1</Select.Option>
                  <Select.Option value="2">Option 2</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
      <br />
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button style={{ width: 120, marginRight: "2%" }}>Reset Filters</Button>
        <Button type="primary" style={{ width: 120 }}>
          Filter Orders
        </Button>
      </div>
      <br />
      <div style={{ fontFamily: "AllTogetherSansW05-Bold", fontWeight: 900 }}>
        LIST OF ORDERS TO PRODUCE
      </div>
      <br />
      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <Button
          className="green"
          style={{
            backgroundColor: "#28BC57",
            color: "#fff",
            marginBottom: "1%",
          }}
        >
          Export to Excel File
        </Button>
      </div>
      <div className="overflow-x-auto shadow-sm rounded-lg">
        {sortLoading ? (
          <LoadingBox />
        ) : (
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                {columns.map(({ key, label }) => (
                  <th
                    key={key}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <button
                      className="flex items-center space-x-1 hover:text-gray-700 focus:outline-none"
                      onClick={() => handleSort(key)}
                      disabled={loading}
                    >
                      <span>{label}</span>
                      <ArrowUpDown
                        className={`h-4 w-4 ${
                          sortConfig.key === key ? "text-blue-600" : ""
                        }`}
                      />
                    </button>
                  </th>
                ))}
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {sortedData.map((row, index) => (
                <tr
                  key={index}
                  onClick={() => handleRowClick(index)}
                  className={`cursor-pointer transition-colors duration-150 ${
                    index === selectedRowIndex
                      ? "bg-blue-100"
                      : "hover:bg-gray-50"
                  }`}
                  style={{
                    borderLeft:
                      index === selectedRowIndex
                        ? "4px solid rgb(37 99 235 / var(--tw-bg-opacity, 1))"
                        : "",
                  }}
                >
                  <td className="px-6 py-3 whitespace-nowrap text-sm">
                    <Checkbox disabled={loading} />
                  </td>
                  {columns.slice(1).map(({ key }) => (
                    <td
                      key={key}
                      className={`px-6 py-3 whitespace-nowrap text-sm ${
                        index === selectedRowIndex
                          ? "text-blue-700"
                          : "text-gray-600"
                      }`}
                    >
                      {key === "orders" ? (
                        <div className="flex items-center gap-2">
                          {row[key]}
                          <FileText color="#FBD334" size={17} />
                        </div>
                      ) : (
                        row[key]
                      )}
                    </td>
                  ))}
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <div className="flex items-center gap-3">
                      <button className="text-blue-600" disabled={loading}>
                        <Eye className="h-5 w-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <br />
      <Row>
        <Col span={18}>
          <div className="breadcums-txt2" style={{ fontSize: 12 }}>
            <span style={{ fontWeight: "bold", color: "black" }}>(1)</span> FSD:
            Factory shipping Data. Limit of date to which the order must be
            shipped out from factory to ensure the estimated delivery date given
            to customer
            <span style={{ fontWeight: "bold", color: "black" }}>
              {" "}
              (2){" "}
            </span>{" "}
            EAD: Estimated Arrival Date.
            <span style={{ fontWeight: "bold", color: "black" }}> (3) </span> To
            take into account order you identify below, click on button "Apply
            Filters" before clicking on button "Print kanbans".
            <span style={{ fontWeight: "bold", color: "black" }}>
              {" "}
              (4){" "}
            </span>{" "}
            The asterisk indicates a target country specified with a
            distribution order created by KSW.
          </div>
        </Col>
        <Col span={6}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button type="primary" style={{ width: 160 }}>
              Print Order kanbans
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default PrintKanban;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
