import { useState } from "react";

const DemandNavigation = (props: any) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const tabCard = [
    { id: 1, title: "GA4 Demand/TRAFFIC" },
    { id: 2, title: "NSV KPI" },
    { id: 3, title: "B2B KPI" },
    // { id: 4, title: "Daily Business Position" },
  ];

  const handleTabClick = (item: any) => {
    console.log("item ...", item);
    setSelectedTab(item?.id);
    props?.selectedItem(item);
  };

  return (
    <div className="w-full">
      <div className="flex gap-2 mt-3">
        {tabCard.map((item) => (
          <button
            key={item.id}
            onClick={() => handleTabClick(item)}
            className={`w-full whitespace-nowrap px-8 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
              selectedTab === item.id
                ? "bg-blue-600 text-white"
                : "bg-gray-500 text-white hover:bg-gray-600"
            }`}
          >
            {item.title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DemandNavigation;
