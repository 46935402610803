import * as msal from "@azure/msal-browser";
import { Form, Skeleton } from "antd";
import {
  Activity,
  BanknoteIcon,
  LayoutDashboard,
  PackageIcon,
  Percent,
  PercentIcon,
  ShoppingCartIcon,
  TrendingUpIcon,
  TruckIcon,
  XCircleIcon,
} from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { AnalyticCard } from "../../components/analyticsCard/analyticsCard";
import ErrorPage from "../../components/errorPage";
import Filters from "../../components/filters";
import Navbar from "../../components/navigation/navbar";
import API from "../../config/api";
import { setFilters } from "../../redux/slices/filterSlice";
import { GET, POST } from "../../utils/apiCalls";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
interface FilterValues {
  year: string[];
  periods: string[];
  weeks: string[];
  country: string[];
  channel: string[];
  salesChannel: string[];
}

export const Dashboard: React.FC = () => {
  const { filters } = useSelector((state: any) => state.Filter);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const dispatch = useDispatch();
  const [salesChannel2Options, setSalesChannel2Options] = useState<any>({});
  const [isSalesChannel2Disabled, setIsSalesChannel2Disabled] = useState(true);
  const [metrics, setMetrics] = useState<any>(null);
  const [orders, setOrders] = useState<any>(null);
  const [counts, setCounts] = useState<any>(null);
  const [loadingMetrics, setLoadingMetrics] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [loadingCounts, setLoadingCounts] = useState(false);
  const [error, setError] = useState(false);
  const [leftMetrics, setLeftMetrics] = useState([
    {
      icon: <ShoppingCartIcon className="w-5 h-5 text-amber-500" />,
      value: 0,
      label: "Total Demand Orders",
    },
    {
      icon: <BanknoteIcon className="w-5 h-5 text-blue-600" />,
      value: 0,
      label: "Total Invoiced Orders",
    },
    {
      icon: <TruckIcon className="w-5 h-5 text-green-600" />,
      value: 0,
      label: "Total Shipped Orders",
    },
    {
      icon: <XCircleIcon className="w-5 h-5 text-red-500" />,
      value: 0,
      label: "Total Cancelled Orders",
    },
    {
      icon: <TrendingUpIcon className="w-5 h-5 text-purple-500" />,
      value: 0,
      label: "Total Refunded Orders",
    },
    {
      icon: <Activity className="w-5 h-5 text-yellow-500" />,
      value: 0,
      label: "Total Demand NSV",
    },
    {
      icon: <PercentIcon className="w-5 h-5 text-green-500" />,
      value: 0,
      label: "Total Invoiced NSV",
    },
    {
      icon: <PackageIcon className="w-5 h-5 text-blue-500" />,
      value: 0,
      label: "Total Shipped NSV",
    },
    {
      icon: <BanknoteIcon className="w-5 h-5 text-red-500" />,
      value: 0,
      label: "Total Cancelled NSV",
    },
    {
      icon: <TrendingUpIcon className="w-5 h-5 text-orange-500" />,
      value: 0,
      label: "Total Refunded NSV",
    },
  ]);
  const [form] = Form.useForm();
  useEffect(() => {
    const initializeDashboard = async () => {
      try {
        if (!isInitialLoad) return; // Prevent multiple calls
        const currentYear = "2024";
        form.setFieldsValue({ year: ["2024"] });

        await getFilters();

        const initialData = {
          year: [currentYear],
        };

        await Promise.all([
          fetchMetrics(initialData),
          fetchCounts(initialData),
          fetchOrders(initialData),
        ]);
      } catch (error) {
        console.error("Error initializing dashboard:", error);
        setError(true);
      }
    };

    initializeDashboard();
  }, []);
  const fetchMetrics = async (filterValues: any = {}) => {
    try {
      setLoadingMetrics(true);
      const response: any = await POST(API.DASHBOARD_METRICS, filterValues);
      if (response?.data) {
        setMetrics(response.data);
        setError(false);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
      setError(true);
    } finally {
      setLoadingMetrics(false);
    }
  };
  const msalConfig = {
    auth: {
      clientId: "4daff424-f696-4b99-9467-0014e824a25f", // From Azure AD app registration
      authority: "https://login.microsoftonline.com/common",
      redirectUri: "http://localhost:3000",
    },
  };
  const msalInstance = new msal.PublicClientApplication(msalConfig);

  async function getToken() {
    try {
      const response = await msalInstance.loginPopup({
        scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
      });
      localStorage.setItem("powerbitoken", response.accessToken);
      return response.accessToken;
    } catch (error) {
      console.error(error);
    }
  }
  const fetchCounts = async (filterValues: any = {}) => {
    try {
      setLoadingCounts(true);
      const response: any = await POST(API.DASHBOARD_COUNTS, filterValues);

      if (response?.data) {
        // Update left metrics with the response data
        const updatedMetrics = [
          {
            icon: <ShoppingCartIcon className="w-5 h-5 text-amber-500" />,
            value: response.data.demandorders,
            label: "Total Demand Orders",
          },
          {
            icon: <BanknoteIcon className="w-5 h-5 text-blue-600" />,
            value: response.data.invoicedorders,
            label: "Total Invoiced Orders",
          },
          {
            icon: <TruckIcon className="w-5 h-5 text-green-600" />,
            value: response.data.shippedorders,
            label: "Total Shipped Orders",
          },
          {
            icon: <XCircleIcon className="w-5 h-5 text-red-500" />,
            value: response.data.cancelledorders,
            label: "Total Cancelled Orders",
          },
          {
            icon: <TrendingUpIcon className="w-5 h-5 text-purple-500" />,
            value: response.data.refundedorders,
            label: "Total Refunded Orders",
          },
          {
            icon: <Activity className="w-5 h-5 text-yellow-500" />,
            value: response.data.demandnsv,
            label: "Total Demand NSV",
          },
          {
            icon: <PercentIcon className="w-5 h-5 text-green-500" />,
            value: response.data.invoicednsv,
            label: "Total Invoiced NSV",
          },
          {
            icon: <PackageIcon className="w-5 h-5 text-blue-500" />,
            value: response.data.shippednsv,
            label: "Total Shipped NSV",
          },
          {
            icon: <BanknoteIcon className="w-5 h-5 text-red-500" />,
            value: response.data.cancellednsv,
            label: "Total Cancelled NSV",
          },
          {
            icon: <TrendingUpIcon className="w-5 h-5 text-orange-500" />,
            value: response.data.refundednsv,
            label: "Total Refunded NSV",
          },
        ];

        setLeftMetrics(updatedMetrics);
        setError(false);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
      setError(true);
    } finally {
      setLoadingCounts(false);
    }
  };

  // Add these formatting functions
  const formatNumber = (value: number): string => {
    return new Intl.NumberFormat("en-US").format(value);
  };

  const formatCurrency = (value: number): string => {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    const formatted =
      value >= 1000
        ? `${formatter.format(value / 1000)}K`
        : formatter.format(value);

    return `€${formatted}`;
  };
  const fetchOrders = async (filterValues: any = {}) => {
    try {
      setLoadingOrder(true);

      const response: any = await POST(API.DASHBOARD_ORDERS, filterValues);

      if (response?.data) {
        setOrders(response.data);
        setError(false);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
      setError(true);
    } finally {
      setLoadingOrder(false);
    }
  };
  const getFilters = async () => {
    try {
      if (filters.length > 0) {
        return;
      }
      const response: any = await GET(API.FILTERS);

      if (response.length) {
        dispatch(setFilters(response));
      }

      const initialValues: FilterValues = {
        year: [],
        periods: [],
        weeks: [],
        country: [],
        channel: [],
        salesChannel: [],
      };
      form.setFieldsValue(initialValues);
    } catch (error) {
      setError(true);
      console.log(error);
    }
  };

  const handleFormChange = async (changedValues: any, allValues: any) => {
    try {
      if (changedValues.salesChannel !== undefined) {
        if (
          changedValues.salesChannel &&
          changedValues.salesChannel.length > 0
        ) {
          const response = await POST(API.FILTERSBYCHANNEL, {
            channel: changedValues.salesChannel,
          });
          setSalesChannel2Options(response);
          setIsSalesChannel2Disabled(false);
        } else {
          form.setFieldsValue({
            salesChannel: [],
            salesChannel2: [],
          });
          setSalesChannel2Options({});
          setIsSalesChannel2Disabled(true);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const handleFormSubmit = async (values: any) => {
    try {
      await Promise.all([
        fetchMetrics(values),
        fetchCounts(values),
        fetchOrders(values),
      ]);
    } catch (error) {
      console.error("Error submitting form:", error);
      setError(true);
    }
  };

  const renderAnalyticCardSkeleton = () => (
    <div className="bg-white rounded-lg shadow-sm p-4">
      <div className="flex items-center">
        <Skeleton.Avatar active={true} size="large" shape="square" />
        <div className="ml-4 flex-grow">
          <Skeleton.Input active={true} size="small" className="w-3/4 mb-2" />
          <Skeleton.Input active={true} size="small" className="w-1/2" />
        </div>
      </div>
    </div>
  );
  //
  const renderMetricSkeleton = () => (
    <div className="bg-white rounded-lg shadow-sm p-4">
      <div className="flex justify-between items-center border-b border-gray-200 pb-2">
        <Skeleton.Input active={true} size="small" className="w-1/2" />
        <div className="bg-[#fde2ff] p-1 rounded-[50%] text-white">
          <Skeleton.Button active={true} size="small" shape="circle" />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-5">
        <div className="w-full">
          <Skeleton.Input active={true} size="small" className="w-3/4 mb-2" />
          <Skeleton.Input active={true} size="large" className="w-full mb-2" />
          <Skeleton active={true} />
        </div>
        <div className="w-full">
          <Skeleton.Input active={true} size="small" className="w-3/4 mb-2" />
          <Skeleton.Input active={true} size="large" className="w-full mb-2" />
          <Skeleton active={true} />
        </div>
        <div className="w-full">
          <Skeleton.Input active={true} size="small" className="w-3/4 mb-2" />
          <Skeleton.Input active={true} size="large" className="w-full mb-2" />
          <Skeleton active={true} />
        </div>
        <div className="w-full">
          <Skeleton.Input active={true} size="small" className="w-3/4 mb-2" />
          <Skeleton.Input active={true} size="large" className="w-full mb-2" />
          <Skeleton active={true} />
        </div>
      </div>
    </div>
  );
  const TrafficSkeleton = () => {
    return (
      <div className="border border-gray-200 rounded-lg shadow-sm p-4 mt-2">
        <div className="flex justify-between items-center border-b border-gray-200 pb-2">
          <Skeleton.Input active={true} size="small" className="w-1/2" />
          <div className="bg-[#d7e1f3] p-1 rounded-[50%] text-white">
            <Skeleton.Button active={true} size="small" shape="circle" />
          </div>
        </div>
        <div className="w-full pt-3">
          <Skeleton.Input active={true} size="small" className="w-3/4 mb-2" />
          <Skeleton.Input active={true} size="large" className="w-full mb-2" />
          <Skeleton active={true} />
        </div>
      </div>
    );
  };
  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <Navbar />
      <div className="flex items-center gap-2 bg-white border-b border-gray-200 px-6 py-4">
        <LayoutDashboard className="text-gray-600" />
        <h1 className="text-xl font-medium">
          Dashboard:{" "}
          <span className="font-bold">
            As of {moment(new Date("2024-12-11")).format("DD MMM YYYY")}
          </span>
        </h1>
      </div>

      <div className="p-4 md:p-6">
        <div className="flex items-center bg-blue-50 px-4 pt-4 mb-5">
          <Filters
            filters={filters}
            form={form}
            handleFormChange={handleFormChange}
            handleFormFinish={handleFormSubmit}
            isSalesChannel2Disabled={isSalesChannel2Disabled}
            salesChannel2Options={salesChannel2Options}
          />
        </div>
        {error ? (
          <ErrorPage
            message=" An unexpected error occurred. Please try again later."
            onBackToHome={() => window.location.reload()}
            onBackText={"Reload"}
          />
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
            {/* Left Section */}
            <div className="sm:col-span-12 md:col-span-3 lg:col-span-3 space-y-4">
              {loadingCounts ? (
                Array(11)
                  .fill(null)
                  .map((_, index) => (
                    <React.Fragment key={index}>
                      {renderAnalyticCardSkeleton()}
                    </React.Fragment>
                  ))
              ) : (
                <>
                  {/* Existing metrics rendering logic */}
                  {leftMetrics.map((metric, index) => (
                    <AnalyticCard
                      key={index}
                      icon={metric.icon}
                      value={metric.value}
                      label={metric.label}
                    />
                  ))}
                </>
              )}
            </div>

            {/* Middle Section */}
            <div className="sm:col-span-12 md:col-span-9 lg:col-span-9 p-4">
              <h2 className="text-2xl font-semibold mb-6">
                EU Digital Business Position
              </h2>
              {loadingMetrics ? (
                Array(1)
                  .fill(null)
                  .map((_, index) => (
                    <React.Fragment key={index}>
                      {renderMetricSkeleton()}
                      <br />
                    </React.Fragment>
                  ))
              ) : (
                <div className="border border-gray-200 rounded-lg shadow-sm p-4">
                  <div className="flex justify-between items-center border-b border-gray-200 pb-2">
                    <div className="text-[#a17478] font-bold">NSV</div>
                    <div className="bg-[#fde2ff] p-1 rounded-[50%] text-white">
                      <Percent color="#954e9a" size={20} />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-5">
                    {/* First Graph */}
                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {/* {salesValues.title} */}
                          {metrics?.demandNSV?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatCurrency(
                            Number(metrics?.demandNSV?.currentYear)
                          )}
                        </div>
                        <div
                          className={`text-sm ${
                            metrics?.demandNSV?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {metrics?.demandNSV?.percentageChange}%
                        </div>
                      </div>

                      <div className="h-16">
                        {/* Reduced height for better proportion */}
                        <Sparklines
                          data={metrics?.demandNSV?.periods.map(
                            (item: any) => item.sales
                          )}
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {metrics?.demandB2C?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatCurrency(
                            Number(metrics?.demandB2C?.currentYear)
                          )}
                        </div>
                        <div
                          className={`text-sm ${
                            metrics?.demandB2C?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {metrics?.demandB2C?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={metrics?.demandB2C?.periods.map(
                            (item: any) => item.sales
                          )}
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>

                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {metrics?.demandB2B?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatCurrency(
                            Number(metrics?.demandB2B?.currentYear)
                          )}
                        </div>
                        <div
                          className={`text-sm ${
                            metrics?.demandB2B?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {metrics?.demandB2B?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={metrics?.demandB2B?.periods.map(
                            (item: any) => item.sales
                          )}
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>

                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {metrics?.demandCancelled?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatCurrency(
                            Number(metrics?.demandCancelled?.currentYear)
                          )}
                        </div>
                        <div
                          className={`text-sm ${
                            metrics?.demandCancelled?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {metrics?.demandCancelled?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={
                            metrics?.demandCancelled?.periods
                              ? [
                                  0,
                                  ...metrics?.demandCancelled?.periods?.map(
                                    (item: any) => item?.sales
                                  ),
                                ]
                              : []
                          }
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-5">
                    {/* First Graph */}

                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {metrics?.invoicedNSV?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatCurrency(
                            Number(metrics?.invoicedNSV?.currentYear)
                          )}
                        </div>
                        <div
                          className={`text-sm ${
                            metrics?.invoicedNSV?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {metrics?.invoicedNSV?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={metrics?.invoicedNSV?.periods.map(
                            (item: any) => item.sales
                          )}
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>

                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {metrics?.invoicedB2C?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatCurrency(
                            Number(metrics?.invoicedB2C?.currentYear)
                          )}
                        </div>
                        <div
                          className={`text-sm ${
                            metrics?.invoicedB2C?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {metrics?.invoicedB2C?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={metrics?.invoicedB2C?.periods.map(
                            (item: any) => item.sales
                          )}
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>

                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {metrics?.invoicedB2B?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatCurrency(
                            Number(metrics?.invoicedB2B?.currentYear)
                          )}
                        </div>
                        <div
                          className={`text-sm ${
                            metrics?.invoicedB2B?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {metrics?.invoicedB2B?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={metrics?.invoicedB2B?.periods.map(
                            (item: any) => item.sales
                          )}
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>

                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {metrics?.refunded?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatCurrency(
                            Number(metrics?.refunded?.currentYear)
                          )}
                        </div>
                        <div
                          className={`text-sm ${
                            metrics?.refunded?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {metrics?.refunded?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={metrics?.refunded?.periods.map(
                            (item: any) => item.sales
                          )}
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              )}
              <br />

              {loadingOrder ? (
                Array(1)
                  .fill(null)
                  .map((_, index) => (
                    <React.Fragment key={index}>
                      {renderMetricSkeleton()}
                      <br />
                    </React.Fragment>
                  ))
              ) : (
                <div className="border border-gray-200 rounded-lg shadow-sm p-4">
                  <div className="flex justify-between items-center border-b border-gray-200 pb-2">
                    <div className="text-[#a17478] font-bold">Orders</div>
                    <div className="bg-[#fde2ff] p-1 rounded-[50%] text-white">
                      <Percent color="#954e9a" size={20} />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-5">
                    {/* First Graph */}
                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {orders?.demandNSV?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatNumber(Number(orders?.demandNSV?.currentYear))}
                        </div>
                        <div
                          className={`text-sm ${
                            orders?.demandNSV?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {orders?.demandNSV?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={orders?.demandNSV?.periods.map(
                            (item: any) => item.sales
                          )}
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>

                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {orders?.demandB2C?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatNumber(Number(orders?.demandB2C?.currentYear))}
                        </div>
                        <div
                          className={`text-sm ${
                            orders?.demandB2C?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {orders?.demandB2C?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={orders?.demandB2C?.periods.map(
                            (item: any) => item.sales
                          )}
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>

                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {orders?.demandB2B?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatNumber(Number(orders?.demandB2B?.currentYear))}
                        </div>
                        <div
                          className={`text-sm ${
                            orders?.demandB2B?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {orders?.demandB2B?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={orders?.demandB2B?.periods.map(
                            (item: any) => item.sales
                          )}
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>

                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {orders?.demandCancelled?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatNumber(
                            Number(orders?.demandCancelled?.currentYear)
                          )}
                        </div>
                        <div
                          className={`text-sm ${
                            orders?.demandCancelled?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {orders?.demandCancelled?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={
                            orders?.demandCancelled?.periods
                              ? [
                                  0,
                                  ...orders?.demandCancelled?.periods?.map(
                                    (item: any) => item?.sales
                                  ),
                                ]
                              : []
                          }
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-5">
                    {/* First Graph */}

                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {orders?.invoicedNSV?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatNumber(
                            Number(orders?.invoicedNSV?.currentYear)
                          )}
                        </div>
                        <div
                          className={`text-sm ${
                            orders?.invoicedNSV?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {orders?.invoicedNSV?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={orders?.invoicedNSV?.periods.map(
                            (item: any) => item.sales
                          )}
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>

                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {orders?.invoicedB2C?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatNumber(
                            Number(orders?.invoicedB2C?.currentYear)
                          )}
                        </div>
                        <div
                          className={`text-sm ${
                            orders?.invoicedB2C?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {orders?.invoicedB2C?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={orders?.invoicedB2C?.periods.map(
                            (item: any) => item.sales
                          )}
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>

                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {orders?.invoicedB2B?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatNumber(
                            Number(orders?.invoicedB2B?.currentYear)
                          )}
                        </div>
                        <div
                          className={`text-sm ${
                            orders?.invoicedB2B?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {orders?.invoicedB2B?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={orders?.invoicedB2B?.periods.map(
                            (item: any) => item.sales
                          )}
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>

                    <div className="w-full">
                      <div className="flex justify-between mb-1">
                        <div className="text-sm text-gray-600">
                          {orders?.refunded?.name}
                        </div>
                        <div className="text-sm text-gray-600">vs LY</div>
                      </div>
                      <div className="flex justify-between mb-1">
                        <div className="text-sm font-semibold mb-2">
                          {formatNumber(Number(orders?.refunded?.currentYear))}
                        </div>
                        <div
                          className={`text-sm ${
                            orders?.refunded?.percentageChange < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {orders?.refunded?.percentageChange}%
                        </div>
                      </div>
                      <div className="h-16">
                        <Sparklines
                          data={orders?.refunded?.periods.map(
                            (item: any) => item.sales
                          )}
                        >
                          <SparklinesLine color="#f48427" />
                        </Sparklines>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              )}
              {loadingMetrics ? (
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
                  {Array(1)
                    .fill(null)
                    .map((_, index) => TrafficSkeleton())}
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
                  <div className="border border-gray-200 rounded-lg shadow-sm p-4 mt-2">
                    <div className="flex justify-between items-center border-b border-gray-200 pb-2">
                      <div className="text-[#a17478] font-bold">
                        DTC Traffic
                      </div>
                      <div className="bg-[#d7e1f3] p-1 rounded-[50%] text-white">
                        <Activity color="#6895e0" size={20} />
                      </div>
                    </div>
                    {/* First Graph */}
                    <div className="flex w-full h-64 pt-2 pb-2 ">
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                          data={[
                            {
                              periods: "P1",
                              totalUsers: 541134,
                              sessions: 615962,
                              newUsers: 238741,
                            },
                            {
                              periods: "P2",
                              totalUsers: 1079742,
                              sessions: 1222184,
                              newUsers: 558920,
                            },
                            {
                              periods: "P3",
                              totalUsers: 548279,
                              sessions: 622740,
                              newUsers: 52624,
                            },
                            {
                              periods: "P4",
                              totalUsers: 474473,
                              sessions: 535417,
                              newUsers: 55473,
                            },
                            {
                              periods: "P5",
                              totalUsers: 567529,
                              sessions: 648349,
                              newUsers: 27456,
                            },
                            {
                              periods: "P6",
                              totalUsers: 590672,
                              sessions: 689249,
                              newUsers: 397994,
                            },
                            {
                              periods: "P7",
                              totalUsers: 608608,
                              sessions: 729589,
                              newUsers: 496587,
                            },
                            {
                              periods: "P8",
                              totalUsers: 467416,
                              sessions: 544062,
                              newUsers: 376842,
                            },
                            {
                              periods: "P9",
                              totalUsers: 943366,
                              sessions: 1141170,
                              newUsers: 695975,
                            },
                            {
                              periods: "P10",
                              totalUsers: 1090071,
                              sessions: 1331943,
                              newUsers: 726964,
                            },
                            {
                              periods: "P11",
                              totalUsers: 782953,
                              sessions: 923761,
                              newUsers: 526560,
                            },
                            {
                              periods: "P12",
                              totalUsers: 253792,
                              sessions: 294596,
                              newUsers: 184756,
                            },
                          ]}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="periods" tick={{ fontSize: 12 }} />
                          <YAxis
                            tick={{ fontSize: 12 }}
                            label={{
                              value: "Users",
                              angle: -90,
                              position: "insideLeft",
                              style: { textAnchor: "middle" },
                            }}
                          />
                          <Tooltip />
                          <Legend />
                          <Line
                            type="monotone"
                            dataKey="totalUsers"
                            stroke="#8884d8"
                            name="Total Users"
                            dot={{ r: 3 }}
                            strokeWidth={2}
                          />
                          <Line
                            type="monotone"
                            dataKey="sessions"
                            stroke="#82ca9d"
                            name="Sessions"
                            dot={{ r: 3 }}
                            strokeWidth={2}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Right Section */}
            {/* <div className="sm:col-span-12 md:col-span-3 lg:col-span-3 space-y-4">
            
          </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;

// <div className="border border-gray-200 rounded-lg shadow-sm p-4 mt-2">
//                     <div className="flex justify-between items-center border-b border-gray-200 pb-2">
//                       <div className="text-[#a17478] font-bold">
//                         DTC Conversion Rate
//                       </div>
//                       <div className="bg-[#d7e1f3] p-1 rounded-[50%] text-white">
//                         <Activity color="#6895e0" size={20} />
//                       </div>
//                     </div>
//                     {/* First Graph */}
//                     <div className="w-full pt-3">
//                       <div className="flex justify-between mb-1">
//                         <div className="text-sm text-gray-600">
//                           {/* {salesValues.title} */}
//                           Total Orders
//                         </div>
//                         <div className="text-sm text-gray-600">vs LY</div>
//                       </div>
//                       <div className="flex justify-between mb-1">
//                         <div className="text-sm font-semibold mb-2">XX.XK</div>
//                         <div
//                           className={`text-sm ${
//                             Number("-x".replace("x", "1")) < 0
//                               ? "text-red-500"
//                               : "text-green-500"
//                           }`}
//                         >
//                           -x%
//                         </div>
//                       </div>
//                       <div>
//                         {/* Reduced height for better proportion */}
//                         <Sparklines data={[10, 20, 5, 50, 3]}>
//                           <SparklinesLine color="#f48427" />
//                         </Sparklines>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="border border-gray-200 rounded-lg shadow-sm p-4 mt-2">
//                     <div className="flex justify-between items-center border-b border-gray-200 pb-2">
//                       <div className="text-[#a17478] font-bold">DTC AGV</div>
//                     </div>
//                     {/* First Graph */}
//                     <div className="w-full pt-3">
//                       <div className="flex justify-between mb-1">
//                         <div className="text-sm text-gray-600">
//                           {/* {salesValues.title} */}
//                           Total Orders
//                         </div>
//                         <div className="text-sm text-gray-600">vs LY</div>
//                       </div>
//                       <div className="flex justify-between mb-1">
//                         <div className="text-sm font-semibold mb-2">XX.XK</div>
//                         <div
//                           className={`text-sm ${
//                             Number("x".replace("x", "1")) < 0
//                               ? "text-red-500"
//                               : "text-green-500"
//                           }`}
//                         >
//                           x%
//                         </div>
//                       </div>
//                       <div>
//                         {/* Reduced height for better proportion */}
//                         <Sparklines data={[10, 20, 5, 50, 3]}>
//                           <SparklinesLine color="#f48427" />
//                         </Sparklines>
//                       </div>
//                     </div>
//                   </div>
