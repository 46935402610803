import { CircleX } from "lucide-react";

const B2b = () => {
  return (
    <div>
      {/* First Section: Weekly NSv Performance by Country */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-[#6b2228]">
            Weekly NSv Performance by Country (B2B - invoiced)
          </h2>
        </div>
        <div className="p-4">
          <div className="flex flex-col items-center mt-5 mb-5">
            <CircleX />
            <div className="text-sm font-bold text-center text-[#a1343c] mt-2">
              Error fetching data for this visual
            </div>
          </div>{" "}
        </div>
      </div>

      {/* Second Section: Weekly NSv Performance by Sales Channel */}
      <div className="bg-white rounded-lg shadow mt-3">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-[#6b2228]">
            Weekly NSv Performance by Sales Channel (B2B - invoiced)
          </h2>
        </div>
        <div className="p-4">
          <div className="flex flex-col items-center mt-5 mb-5">
            <CircleX />
            <div className="text-sm font-bold text-center text-[#a1343c] mt-2">
              Error fetching data for this visual
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default B2b;
