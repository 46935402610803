import React, { useState } from "react";
import { Settings } from "lucide-react";
import { Button, Form, Input, Select } from "antd";

const LabelPrinting = () => {
  const [show, setShow] = useState(false);
  const [subShow, setSubShow] = useState(false);
  return (
    <div className="p-6 min-h-screen bg-gray-50">
      {/* Header */}

      <div className="flex justify-between items-center pb-2 border-b">
        <div className="text-xl font-semibold">BBD Label Printing</div>
        <div className="text-gray-500 text-sm">
          Impression étiquettes DLUO (ZPL version)...
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
        <div
          className={`sm:col-span-12 ${
            subShow ? "md:col-span-8" : "md:col-span-12"
          }`}
        >
          <div className="text-gray-700 mb-5 mt-5">
            3 commande(s) standard restante(s) avec le statut BMFS
          </div>
          {/* Navigation and Action Bar */}
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-4 ">
              <div className="w-full">
                <Input
                  style={{ width: 300 }}
                  type="text"
                  size="large"
                  placeholder="N° de Commande"
                />
              </div>
            </div>
            <Button size="large" type="primary" onClick={() => setShow(true)}>
              Demarrer la commande
            </Button>
          </div>
          {show && (
            <>
              <div className="flex gap-4 mt-6">
                <Form.Item label={"Autocollant Supplementaires"}>
                  <Select size="large" style={{ width: 300 }}>
                    <Select.Option value="1">Option 1</Select.Option>
                    <Select.Option value="2">Option 2</Select.Option>
                  </Select>
                </Form.Item>

                <Button
                  size="large"
                  className="bg-green-500 text-white hover:bg-green-600 w-[300px]"
                  onClick={() => setSubShow(true)}
                >
                  Confirmer Demarrer commande
                </Button>
              </div>
              <div className="bg-gray-100 rounded-lg font-semibold py-1 px-4 mt-5">
                Impression Stickers pour commende #2000010136
              </div>
              <div className="text-gray-500 text-sm mt-5">
                Verifier le nombre de sticker DLUO qui seront generes, et vers
                quelle imprimante. Une fois verifie, cliquez sur le bouton
                imprimer.
              </div>
              <div className="bg-blue-500 text-white rounded-lg font-semibold py-1 px-4 mt-6 mb-5">
                Imprimer les autocollants et valider la commande #2000010136{" "}
              </div>
              <div className="overflow-x-auto shadow-sm rounded-lg">
                <table className="w-full">
                  <tbody className="bg-white divide-y divide-gray-200">
                    <tr className="cursor-pointer transition-colors duration-150 hover:bg-gray-100">
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <div className="flex items-center gap-3">12px </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        Image 1
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        Image 2
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        DLUO 23/12/2012
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}{" "}
        </div>
        {subShow && (
          <div className="sm:col-span-12 md:col-span-4">
            <div
              className={`${
                show ? "block" : "invisible"
              } bg-white rounded-lg shadow-sm h-fit mt-5`}
            >
              <div className="p-6 space-y-4 h-[500px]"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LabelPrinting;
