import React, { useEffect, useState } from "react";
import { POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import LoadingBox from "../../../components/LoadingBox";
import { ArrowUpDown } from "lucide-react";

const PromoPerformance = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMonth, setIsLoadingMonth] = useState(false);
  const [countryDistribution, setCountryDistribution] = useState<any>({});
  const [campain, setCampain] = useState<any>({});
  const [sortField, setSortField] = useState<any>("DISCOUNTCAMPAIGN");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [sortFieldMonth, setSortFieldMonth] = useState<any>("COUNTRY");
  const [sortOrderMonth, setSortOrderMonth] = useState<"ASC" | "DESC">("DESC");

  const countryColumns: any = [
    { key: "COUNTRY", label: "Country" },
    { key: "P1", label: "P01" },
    { key: "P2", label: "P02" },
    { key: "P3", label: "P03" },
    { key: "P4", label: "P04" },
    { key: "P5", label: "P05" },
    { key: "P6", label: "P06" },
    { key: "P7", label: "P07" },
    { key: "P8", label: "P08" },
    { key: "P9", label: "P09" },
    { key: "P10", label: "P10" },
    { key: "P11", label: "P11" },
    { key: "P12", label: "P12" },
    { key: "P13", label: "P13" },
    { key: "demandTotal", label: "Demand Total" },
  ];

  const campaignColumns = [
    {
      key1: "DISCOUNTCAMPAIGN",
      key: "discountCampaign",
      label: "Discount Campaign",
    },
    { key1: "DEMAND_NSV", key: "demandNSV", label: "Demand NSV" },
    {
      key1: "DEMAND_NSV_VS_LY",
      key: "demandNSVvsLY",
      label: "Demand NSV vs LY %",
    },
    {
      key1: "ORDER_COUNT",
      key: "demandNbOfOrders",
      label: "Demand Nb of Orders",
    },
    {
      key1: "DEMAND_ORDERS_VS_LY",
      key: "demandOrdersVsLY",
      label: "Demand Orders vs LY %",
    },
  ];

  useEffect(() => {
    fetchPromoCountryDistribution({
      ...props?.filters,
      sort: sortFieldMonth,
      order: sortOrderMonth,
    });
  }, [props?.filters, sortFieldMonth, sortOrderMonth]);
  useEffect(() => {
    fetchPromoCampainDistribution({
      ...props?.filters,
      sort: sortField,
      order: sortOrder,
    });
  }, [props?.filters, sortField, sortOrder]);

  const fetchPromoCampainDistribution = async (filterValues: any = {}) => {
    try {
      setIsLoading(true);
      const response: any = await POST(API.PROMO_CAMPAIN, filterValues);
      if (response) {
        setCampain(response);
      }
    } catch (error) {
      console.error("Error fetching occasions by country:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchPromoCountryDistribution = async (filterValues: any = {}) => {
    try {
      setIsLoadingMonth(true);
      const response: any = await POST(API.PROMO_BY_COUNTRY, filterValues);
      if (response) {
        setCountryDistribution(response);
      }
    } catch (error) {
      console.error("Error fetching occasions by country:", error);
    } finally {
      setIsLoadingMonth(false);
    }
  };
  const formatCurrency = (value: any) => {
    if (isNaN(value)) return "-";
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatPercentage = (value: any) => {
    if (isNaN(value)) return "-";
    return `${value?.toFixed(1)}%`;
  };

  const getPercentageColor = (value: any) => {
    if (value > 0) return "bg-green-100 text-green-800";
    if (value < 0) return "bg-red-100 text-red-800";
    return "text-gray-600";
  };
  const handleSort = (
    column: string,
    sort: any,
    order: any,
    sortItem: any,
    orderItem: any
  ) => {
    if (sortItem === column) {
      order(orderItem === "ASC" ? "DESC" : "ASC");
    } else {
      sort(column);
      order("ASC");
    }
  };
  return (
    <div className="grid grid-cols-1 gap-6">
      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-[#6b2228]">
            Demand via Promo - Country Distribution
          </h2>
        </div>
        <div className="overflow-x-auto">
          {isLoadingMonth ? (
            <LoadingBox />
          ) : (
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  {countryColumns?.map(({ key, label }: any) => (
                    <th
                      key={key}
                      onClick={() =>
                        handleSort(
                          key,
                          setSortFieldMonth,
                          setSortOrderMonth,
                          sortFieldMonth,
                          sortOrderMonth
                        )
                      }
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <div className="flex items-center space-x-1">
                        <span>{label}</span>
                        <ArrowUpDown className="h-4 w-4" />
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {countryDistribution?.rows?.map((row: any, index: any) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                      {row.COUNTRY}
                    </td>
                    {countryColumns?.slice(1).map(({ key }: any) => (
                      <td
                        key={key}
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-600"
                      >
                        {formatCurrency(row[key])}
                      </td>
                    ))}
                  </tr>
                ))}
                <tr className="bg-blue-600">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                    Total
                  </td>
                  {countryColumns.slice(1).map(({ key }: any) => (
                    <td
                      key={key}
                      className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"
                    >
                      {countryDistribution?.total?.P1 &&
                        formatCurrency(countryDistribution?.total[key])}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-[#6b2228]">
            Demand via Promo Code - Promo Campaign Distribution
          </h2>
        </div>
        <div className="overflow-x-auto">
          {isLoading ? (
            <LoadingBox />
          ) : (
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  {campaignColumns.map(({ key, key1, label }) => (
                    <th
                      key={key}
                      onClick={() =>
                        handleSort(
                          key1,
                          setSortField,
                          setSortOrder,
                          sortField,
                          sortOrder
                        )
                      }
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <div className="flex items-center space-x-1">
                        <span>{label}</span>
                        <ArrowUpDown className="h-4 w-4" />
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {campain?.rows?.map((row: any, index: any) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                      {row.DISCOUNT_CAMPAIGN}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {formatCurrency(row.DEMAND_NSV)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span
                        className={`px-2 py-1 rounded-full ${getPercentageColor(
                          row.DEMAND_NSV_VS_LY
                        )}`}
                      >
                        {formatPercentage(row.DEMAND_NSV_VS_LY)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row?.DEMAND_NB_OF_ORDERS?.toLocaleString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span
                        className={`px-2 py-1 rounded-full ${getPercentageColor(
                          row.DEMAND_ORDERS_VS_LY
                        )}`}
                      >
                        {formatPercentage(row.DEMAND_ORDERS_VS_LY)}
                      </span>
                    </td>
                  </tr>
                ))}
                <tr className="bg-blue-600">
                  {(() => {
                    return (
                      <>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                          {/* {campain?.totals.discountCampaign} */}Total
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {formatCurrency(campain?.total?.totalNsv)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {formatPercentage(campain?.total?.averageNsvGrowth)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {campain?.total?.totalOrders?.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {formatPercentage(
                            campain?.total?.averageOrdersGrowth
                          )}
                        </td>
                      </>
                    );
                  })()}
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromoPerformance;
