import { useEffect } from "react";
import { Provider } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { PersistedStore, Store } from "./redux/store";

import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import CCMDashboard from "./pages/ccmdashboard";
import Demand from "./pages/demand";
import EuOrders from "./pages/euOrders";
import OrderView from "./pages/euOrders/orderView";
import Login from "./pages/login";
import Dashboard from "./pages/omsdashboard";
import Performance from "./pages/performance";
import PlanningPhase from "./pages/planningPhase";
import ProductionPhase from "./pages/productionPhase";
import EuProducts from "./pages/euPoducts";
import ProductView from "./pages/euPoducts/productView";
import Sales from "./pages/sales";
import ShipmentPhase from "./pages/shippingPhase";
import Supply from "./pages/supply";
import WebDashboard from "./pages/webdashboard";

const AuthRedirect = ({ children }: any) => {
  const token = useSelector((state: any) => state.Auth.token);

  const isTokenExpired = (jwt: string) => {
    try {
      const decoded: { exp: number } = jwtDecode(jwt);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      return decoded.exp < currentTime; // Token is expired if `exp` is less than current time
    } catch (error) {
      console.error("Invalid token:", error);
      return true; // Treat invalid tokens as expired
    }
  };

  if (token && !isTokenExpired(token)) {
    return <Navigate to="/oms-dashboard" replace />;
  }

  return children;
};

const ProtectRedirect = ({ children }: any) => {
  const tikon = useSelector((state: any) => state.Auth.token);
  return !tikon ? <Navigate to="/login" replace /> : children;
};

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const redirectPath = params.get("redirect");
    if (redirectPath) {
      navigate(redirectPath);
    }
  }, [navigate]);
  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={PersistedStore}>
        <Routes>
          <Route
            path="/"
            element={
              <AuthRedirect>
                <Navigate to="/login" replace />
              </AuthRedirect>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/oms-dashboard"
            element={
              // <ProtectRedirect>
              <Dashboard />
              // </ProtectRedirect>
            }
          />
          <Route
            path="/ccm-dashboard"
            element={
              // <ProtectRedirect>
              <CCMDashboard />
              // </ProtectRedirect>
            }
          />
          <Route
            path="/web-dashboard"
            element={
              // <ProtectRedirect>
              <WebDashboard />
              // </ProtectRedirect>
            }
          />
          <Route
            path="/eu-orders"
            element={
              // <ProtectRedirect>
              <EuOrders />
              // </ProtectRedirect>
            }
          />

          <Route
            path="/eu-products/:productId"
            element={
              // <ProtectRedirect>
              <ProductView />
              // </ProtectRedirect>
            }
          />
          <Route
            path="/eu-products"
            element={
              // <ProtectRedirect>
              <EuProducts />
              // </ProtectRedirect>
            }
          />
          <Route
            path="/eu-orders/:orderId"
            element={
              // <ProtectRedirect>
              <OrderView />
              // </ProtectRedirect>
            }
          />
          <Route
            path="/demand"
            element={
              // <ProtectRedirect>
              <Demand />
              // </ProtectRedirect>
            }
          />
          <Route
            path="/sales"
            element={
              // <ProtectRedirect>
              <Sales />
              // </ProtectRedirect>
            }
          />
          <Route
            path="/supply"
            element={
              // <ProtectRedirect>
              <Supply />
              // </ProtectRedirect>
            }
          />
          <Route
            path="/performance"
            element={
              // <ProtectRedirect>
              <Performance />
              // </ProtectRedirect>
            }
          />
          <Route
            path="/planning-phase/*"
            element={
              // <ProtectRedirect>
              <PlanningPhase />
              // </ProtectRedirect>
            }
          />
          <Route
            path="/shipment-phase/*"
            element={
              // <ProtectRedirect>
              <ShipmentPhase />
              // </ProtectRedirect>
            }
          />
          <Route
            path="/production-phase/*"
            element={
              // <ProtectRedirect>
              <ProductionPhase />
              // </ProtectRedirect>
            }
          />
        </Routes>
      </PersistGate>
    </Provider>
  );
}

export default App;
