import React, { useState } from "react";
import { Settings } from "lucide-react";
import { Button, Input } from "antd";

const Merchandizing = () => {
  const [show, setShow] = useState(false);
  return (
    <div className="p-6 min-h-screen bg-gray-50">
      {/* Header */}
      <div className="flex justify-between items-center pb-2 border-b">
        <div className="text-xl font-semibold">Picking Merchandising</div>
        <div className="text-gray-500 text-sm">
          Supply: Picking/Merchandizing
        </div>
      </div>

      <div className="text-gray-700 mb-5 mt-5">
        1 commande(s) standard restante(s) ayant au moins 1 produit non
        manufacturé, avec le statut BFWD
      </div>

      {/* Navigation and Action Bar */}
      <div className="flex items-center justify-between gap-4">
        <div className="flex gap-4">
          <Input className="w-72" type="text" placeholder="Numéro Commande" />

          <Button size="large" type="primary" onClick={() => setShow(true)}>
            Go
          </Button>
        </div>

        {show && (
          <Button className="bg-green-500 text-white hover:bg-green-600 w-[300px]">
            Impression Kanban
          </Button>
        )}
      </div>
      {show && (
        <>
          <div className="flex justify-between items-center my-6">
            <div className="bg-gray-100 rounded-lg font-semibold py-1 px-4 w-3/4">
              Controle commande #502322523
            </div>
            <div className="font-semibold">1 item obligatoire restant</div>
          </div>
          <div className="overflow-x-auto shadow-sm rounded-lg">
            <table className="w-full">
              <tbody className="bg-white divide-y divide-gray-200">
                <tr className="cursor-pointer transition-colors duration-150 hover:bg-gray-100">
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <div className="flex items-center gap-3">12px </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">Image</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <div className="flex items-center gap-3">
                      <Button type="primary">
                        Confirmer l' ajout de ce produit
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default Merchandizing;
