import React from "react";
import Navbar from "../../components/navigation/navbar";
import Header from "../../components/header";
import { Navigate, Route, Routes } from "react-router-dom";
import Shipping from "./shipping";

const ShipmentPhase = () => {
  const Menu = [
    {
      id: 1,
      name: "Shipping",
      route: "/shipment-phase/shipping",
    },
  ];
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <Header title={"Core Production"} options={Menu} />
      <div className="ProdPlanningScreen-box">
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/shipment-phase/shipping" replace />}
          />
          <Route path="/Shipping" element={<Shipping />} />
        </Routes>
      </div>
    </div>
  );
};

export default ShipmentPhase;
