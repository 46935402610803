import { Button, Form, Input, Select } from "antd";
import React, { useState } from "react";

const Shipping = () => {
  const [show, setShow] = useState(false);
  return (
    <div className="p-6 min-h-screen bg-gray-50">
      {/* Header */}
      <div className="flex justify-between items-center pb-2 border-b">
        <div className="text-xl font-semibold">Shipping</div>
        <div className="text-gray-500 text-sm">
          Supply-Validation: Expedition (LINE 1){" "}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
        <div
          className={`sm:col-span-12 ${
            show ? "md:col-span-7" : "md:col-span-12"
          }`}
        >
          <div className="text-gray-700 font-semibold text-sm mb-5 mt-5">
            44 commande(s) standard restante(s) avec le statut BFWD
          </div>

          {/* Navigation and Action Bar */}
          <div className="flex items-center gap-4">
            <Input
              style={{ width: 300 }}
              type="text"
              size="large"
              placeholder="Numero Commande"
            />

            <Button size="large" type="primary" onClick={() => setShow(true)}>
              Go
            </Button>
          </div>
          {show && (
            <>
              <div className="bg-gray-100 rounded-lg font-semibold py-1 px-4 mt-6 mb-6">
                Options ...
              </div>
              <Form.Item label={"HUD reference - offset"}>
                <Select style={{ width: "50%" }}>
                  <Select.Option value="1">Option 1</Select.Option>
                  <Select.Option value="2">Option 2</Select.Option>
                </Select>
              </Form.Item>
            </>
          )}
        </div>
        <div className="sm:col-span-12 md:col-span-5">
          <div
            className={`${
              show ? "block" : "invisible"
            } bg-white rounded-lg shadow-sm h-fit mt-5`}
          >
            <div className="p-4 bg-gray-200 rounded-t-lg">
              <h3 className="text-md font-medium text-center text-gray-600">
                Commande 40000005343 validée{" "}
              </h3>
            </div>
            <div className="p-6 space-y-4 h-[300px]"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shipping;
