import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../../components/header";
import Navbar from "../../components/navigation/navbar";
import PrintKanban from "./printKanban";
import LabelPrinting from "./labelPrinting";
import Merchandizing from "./merchandizing";

const ProductionPhase = () => {
  const Menu = [
    {
      id: 1,
      name: "Printing Kanban",
      route: "/production-phase/PrintKanban",
    },
    {
      id: 2,
      name: "BBD Label Printing",
      route: "/production-phase/BBDLabelPrinting",
    },
    {
      id: 3,
      name: "Picking Merchandising",
      route: "/production-phase/PickingMerchandising",
    },
  ];
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <Header title={"Core Production"} options={Menu} />
      <div className="ProdPlanningScreen-box">
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/production-phase/PrintKanban" replace />}
          />
          <Route path="/PrintKanban" element={<PrintKanban />} />
          <Route path="/BBDLabelPrinting" element={<LabelPrinting />} />
          <Route path="/PickingMerchandising" element={<Merchandizing />} />
        </Routes>
      </div>
    </div>
  );
};
export default ProductionPhase;
