import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ArrowLeft,
  LayoutDashboard,
  Package,
  Calendar,
  ShoppingBag,
  Building,
  Globe,
  Beaker,
  FileText,
  Weight,
  Filter,
  ListFilter,
} from "lucide-react";
import Navbar from "../../components/navigation/navbar";
import { GET } from "../../utils/apiCalls";
import API from "../../config/api";

const ProductView = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState<any>();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      try {
        const response = await GET(API.EU_ALL_PRODUCTS + productId);
        setProduct(response);
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  const statusItems = [
    { label: "Manufactured", value: product?.IS_MANUFACTURED },
    { label: "Has BOM", value: product?.HAS_BOM },
    { label: "Active", value: product?.IS_ACTIVE },
    { label: "Copacked", value: product?.COPACKED },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="flex items-center justify-between flex-wrap border-b border-gray-200 px-6 py-4 bg-white">
        <div className="flex items-center gap-3">
          <ArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => navigate("/eu-products")}
          />
          <div className="flex items-center gap-2">
            <LayoutDashboard />
            <h1 className="text-xl font-medium">
              Product:{" "}
              <span className="font-bold">
                {loading ? "" : product?.NAME_EN}
              </span>
            </h1>
          </div>
        </div>
        <div className="flex gap-2">
          <button className="px-4 py-1.5 text-gray-700 border border-gray-300 rounded hover:bg-gray-50">
            Edit
          </button>
          <button className="px-4 py-1.5 text-white bg-blue-600 rounded hover:bg-blue-700">
            Save
          </button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-50">
          <div className="animate-pulse">
            <div className="w-16 h-16 border-4 border-gray-600 border-t-transparent rounded-full animate-spin"></div>
          </div>
        </div>
      ) : (
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-6 items-stretch">
            {/* Left Column - Basic Information */}
            <div className="col-span-12 md:col-span-4 flex flex-col">
              <div className="bg-white rounded-lg shadow-md p-4 h-full">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <ListFilter className="h-5 w-5 text-blue-500" />
                    <span className="font-bold text-blue-500">
                      Basic Information
                    </span>
                  </div>
                </div>
                <div className="text-sm text-gray-600 mt-1">
                  <div className="divide-y divide-gray-100">
                    {/* Product ID */}
                    <div className="flex items-start gap-3 p-2 bg-blue-50 rounded-lg">
                      <Package className="h-5 w-5 text-blue-600" />
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Product ID</div>
                        <div className="mt-1 font-medium text-blue-600 break-words">
                          {product?.PRODUCT_ID || "-"}
                        </div>
                      </div>
                    </div>
                    {/* SAP Code */}
                    <div className="flex items-start gap-3 p-2">
                      <Calendar className="h-5 w-5 text-gray-400" />
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">SAP Code</div>
                        <div className="mt-1 font-medium text-gray-900 break-words">
                          {product?.CODE_SAP || "-"}
                        </div>
                      </div>
                    </div>
                    {/* Category */}
                    <div className="flex items-start gap-3 p-2">
                      <ShoppingBag className="h-5 w-5 text-gray-400" />
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Category</div>
                        <div className="mt-1 font-medium text-gray-900 break-words">
                          {product?.CATEGORY || "-"}
                        </div>
                      </div>
                    </div>
                    {/* Range */}
                    <div className="flex items-start gap-3 p-2">
                      <Building className="h-5 w-5 text-gray-400" />
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Range</div>
                        <div className="mt-1 font-medium text-gray-900 break-words">
                          {product?.RANGE || "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-span-12 md:col-span-8">
              {/* Product Details */}
              <div className="bg-white rounded-lg shadow-md p-4 mb-6">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <ListFilter className="h-5 w-5 text-blue-500" />
                    <span className="font-bold text-blue-500">
                      Product Details
                    </span>
                  </div>
                </div>
                <div className="text-sm text-gray-600 mt-1">
                  <div className="divide-y divide-gray-100">
                    {/* Name (EN) */}
                    <div className="flex items-start gap-3 p-2 bg-blue-50 rounded-lg">
                      <Globe className="h-5 w-5 text-blue-600" />
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Name (EN)</div>
                        <div className="mt-1 font-medium text-blue-600 break-words">
                          {product?.NAME_EN || "-"}
                        </div>
                      </div>
                    </div>
                    {/* Name (FR) */}
                    <div className="flex items-start gap-3 p-2">
                      <Globe className="h-5 w-5 text-gray-400" />
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Name (FR)</div>
                        <div className="mt-1 font-medium text-gray-900 break-words">
                          {product?.NAME_FR || "-"}
                        </div>
                      </div>
                    </div>
                    {/* Flavour */}
                    <div className="flex items-start gap-3 p-2">
                      <Beaker className="h-5 w-5 text-gray-400" />
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Flavour</div>
                        <div className="mt-1 font-medium text-gray-900 break-words">
                          {product?.FLAVOUR || "-"}
                        </div>
                      </div>
                    </div>
                    {/* Type */}
                    <div className="flex items-start gap-3 p-2">
                      <FileText className="h-5 w-5 text-gray-400" />
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Type</div>
                        <div className="mt-1 font-medium text-gray-900 break-words">
                          {product?.TYPE || "-"}
                        </div>
                      </div>
                    </div>
                    {/* Weight */}
                    <div className="flex items-start gap-3 p-2">
                      <Weight className="h-5 w-5 text-gray-400" />
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Weight</div>
                        <div className="mt-1 font-medium text-gray-900 break-words">
                          {product?.WEIGHT ? `${product.WEIGHT}g` : "-"}
                        </div>
                      </div>
                    </div>
                    {/* Extra Weight */}
                    <div className="flex items-start gap-3 p-2">
                      <Weight className="h-5 w-5 text-gray-400" />
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Extra Weight
                        </div>
                        <div className="mt-1 font-medium text-gray-900 break-words">
                          {product?.EXTRA_WEIGHT
                            ? `${product.EXTRA_WEIGHT}g`
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* SKU Information */}
              <div className="bg-white rounded-lg shadow-md p-4 mb-6">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <ListFilter className="h-5 w-5 text-blue-500" />
                    <span className="font-bold text-blue-500">
                      SKU Information
                    </span>
                  </div>
                </div>
                <div className="text-sm text-gray-600 mt-1">
                  <div className="grid grid-cols-2 gap-4">
                    {["SUB_SKU_1", "SUB_SKU_2", "SUB_SKU_3", "SUB_SKU_4"].map(
                      (sku, index) => (
                        <div
                          key={index}
                          className="bg-white p-3 rounded-lg shadow-sm"
                        >
                          <dt className="text-xs text-gray-500 uppercase">
                            {sku.replace("_", " ")}
                          </dt>
                          <dd className="text-sm font-medium text-gray-800">
                            {product?.[sku] || "-"}
                          </dd>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>

              {/* Status Information */}
              <div className="bg-white rounded-lg shadow-md p-4">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <ListFilter className="h-5 w-5 text-blue-500" />
                    <span className="font-bold text-blue-500">
                      Status Information
                    </span>
                  </div>
                </div>
                <div className="text-sm text-gray-600 mt-1">
                  <div className="grid grid-cols-2 gap-4">
                    {statusItems.map((item, index) => (
                      <div
                        key={index}
                        className="bg-white p-3 rounded-lg shadow-sm flex items-center justify-between"
                      >
                        <dt className="text-xs text-gray-500 uppercase">
                          {item.label}
                        </dt>
                        <dd>
                          <span
                            className={`px-2 py-1 rounded-full text-xs font-medium ${
                              item.value
                                ? "bg-green-100 text-green-800"
                                : "bg-gray-100 text-gray-800"
                            }`}
                          >
                            {item.value ? "Yes" : "No"}
                          </span>
                        </dd>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductView;
