import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function Header(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [menu, setMenu] = useState(true);

  const nav = (option: any) => {
    navigate(option);
  };

  // Check if the current path matches the menu item's route
  const isActive = (route: string) => {
    return location.pathname === route;
  };
  const [marginTop, setMarginTop] = useState("0px");

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1380px)");

    const handleResize = (e: any) => {
      setMarginTop(e.matches ? "18px" : "0px");
    };

    // Initial check
    handleResize(mediaQuery);

    // Add listener
    mediaQuery.addEventListener("change", handleResize);

    // Cleanup
    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  return (
    <div className="p-3" style={{ marginTop }}>
      {menu ? (
        <div className="flex border-b" style={{ backgroundColor: "#f5f5f5" }}>
          {props?.options?.map((item: any) => {
            const active = isActive(item.route);
            return (
              <div key={item.id}>
                <div
                  onClick={() => nav(item.route)}
                  className={`
                    cursor-pointer 
                    px-6 
                    py-2
                    transition-colors
                    ${
                      active
                        ? "bg-blue-600 text-white font-medium"
                        : "text-gray-600 hover:bg-gray-200"
                    }
                   
                  `}
                >
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

export default Header;
