import React from "react";
import orange from "../../assets/images/orange.png";
// Props definition
interface ErrorPageProps {
  message: string;
  onBackText: string;
  onBackToHome: () => void;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  message,
  onBackToHome,
  onBackText,
}) => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        {/* Orange M&M-style character */}
        <img
          src={orange} // Replace with your character/image URL
          alt="Error Character"
          style={styles.image}
        />

        {/* Error text */}
        <h1 style={styles.title}>OOPS...</h1>
        <p style={styles.message}>{message}</p>

        {/* Back to Home Button */}
        <button onClick={onBackToHome} style={styles.button}>
          {onBackText || "Back to home"}
        </button>
      </div>
    </div>
  );
};

// Inline styles
const styles: any = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    backgroundColor: "#fff",
    fontFamily: "'Arial', sans-serif",
  },
  content: {
    maxWidth: "400px",
  },
  image: {
    width: "250px",
    margin: "0 auto",
  },
  title: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#000",
  },
  message: {
    fontSize: "1rem",
    marginBottom: "20px",
    color: "#333",
  },
  button: {
    backgroundColor: "#ffcc00",
    color: "#000",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    fontSize: "1rem",
    cursor: "pointer",
  },
};

export default ErrorPage;
