import React, { useState, useEffect } from "react";
import { ArrowUpDown, Edit2, Eye, Search, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingBox from "../../../../components/LoadingBox";
import { GET } from "../../../../utils/apiCalls";
import API from "../../../../config/api";
import moment from "moment";

interface Order {
  id: number;
  ORDERNUMBER: string;
  PARENTORDERNUMBER: string;
  CREATEDDATE: string;
  INVOICEDATE: string;
  SHIPPEDDATE: string;
  CURRENTSTAGE: string;
  COUNTRY_BILLING: string;
  COUNTRY_SHIPPING: string;
  CURRENCY: string;
  NSV: number;
}

interface OrdersResponse {
  data: Order[];
  totalRecords: number;
  filteredRecords: number;
  totalPages: number;
  currentPage: number;
}

interface OrdersTableProps {
  totalRecords: (val: number) => void;
  filteredRecords: (val: number) => void;
}

const OrdersTable: React.FC<OrdersTableProps> = ({
  totalRecords,
  filteredRecords,
}) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortField, setSortField] = useState("CREATEDDATE");
  const [leatestDate, setLeatestDate] = useState("2024-12-11");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const params = {
        page: currentPage,
        limit: itemsPerPage,
        sort: sortField,
        order: sortOrder,
        search: searchTerm,
      };

      const response: any = await GET(API.EU_ALLORDER, params);

      // Assuming the response structure is the same
      setOrders(response.data);
      setLeatestDate(response?.data && response?.data[0]?.CREATEDDATE);
      totalRecords(response.totalRecords);
      filteredRecords(response.filteredRecords);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [currentPage, itemsPerPage, sortField, sortOrder, searchTerm]);

  const handleSort = (column: string) => {
    if (sortField === column) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSortField(column);
      setSortOrder("ASC");
    }
  };

  const handleView = (orderId: string) => {
    navigate(`/eu-orders/${orderId}`);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setCurrentPage(1);
  };

  const handleEntriesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Shipment in progress":
        return "bg-green-100 text-green-800";
      case "Delivered":
        return "bg-blue-100 text-blue-800";
      case "Created":
        return "bg-gray-100 text-gray-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const showAround = 1;

    pages.push(1);

    if (currentPage > 2 + showAround) {
      pages.push("...");
    }

    for (
      let i = Math.max(2, currentPage - showAround);
      i <= Math.min(totalPages - 1, currentPage + showAround);
      i++
    ) {
      pages.push(i);
    }

    if (currentPage < totalPages - (1 + showAround)) {
      pages.push("...");
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return (
      <div className="flex items-center space-x-1">
        {pages.map((page, index) => (
          <React.Fragment key={index}>
            {page === "..." ? (
              <span className="px-2 py-1 text-gray-500">...</span>
            ) : (
              <button
                onClick={() => setCurrentPage(page as number)}
                className={`min-w-[32px] px-2 py-1 rounded text-sm ${
                  currentPage === page
                    ? "bg-gray-600 text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                {page}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div className="p-4 md:p-6 lg:col-span-10">
      <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
        {/* Table Header */}
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold">EU ALL Orders</h2>
          <div className="mt-4 flex flex-col sm:flex-row justify-between items-center gap-4">
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">Show</span>
              <select
                value={itemsPerPage}
                onChange={handleEntriesChange}
                className="px-2 py-1 border border-gray-300 rounded-md text-sm"
              >
                {[10, 25, 50, 100].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <span className="text-sm text-gray-600">entries</span>
            </div>
            <div className="relative w-full sm:w-auto">
              <Search className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search orders...."
                className="w-full sm:w-64 pl-10 pr-10 py-2 border border-gray-300 rounded-lg"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
              />
              {searchTerm && (
                <button
                  onClick={clearSearch}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <X className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Table Content */}
        <div className="overflow-x-auto">
          {isLoading ? (
            <LoadingBox />
          ) : (
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  {[
                    { key: "ORDERNUMBER", label: "Order ID" },
                    { key: "CREATEDDATE", label: "Created Date" },
                    { key: "INVOICEDATE", label: "Invoiced Date" },
                    { key: "SHIPPEDDATE", label: "Shipped Date" },
                    { key: "CURRENTSTAGE", label: "Current Stage" },
                    { key: "NSV", label: "NSV" },
                    { key: "COUNTRY_BILLING", label: "Billing Country" },
                    { key: "COUNTRY_SHIPPING", label: "Shipping Country" },
                  ].map(({ key, label }) => (
                    <th
                      key={key}
                      onClick={() => handleSort(key)}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    >
                      <div className="flex items-center space-x-1">
                        <span>{label}</span>
                        <ArrowUpDown className="h-4 w-4" />
                      </div>
                    </th>
                  ))}
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {orders?.map((order) => (
                  <tr key={order.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 font-medium">
                      {order.ORDERNUMBER}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {moment(order.CREATEDDATE).format("DD-MMM-YYYY")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {order.INVOICEDATE
                        ? moment(order.INVOICEDATE).format("DD-MMM-YYYY")
                        : "Not yet Invoiced"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {order.SHIPPEDDATE
                        ? moment(order.SHIPPEDDATE).format("DD-MMM-YYYY")
                        : "Not yet Shipped"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(
                          order.CURRENTSTAGE
                        )}`}
                      >
                        {order.CURRENTSTAGE}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {order.NSV}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {order.COUNTRY_BILLING}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {order.COUNTRY_SHIPPING}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      <div className="flex items-center gap-3">
                        <button
                          onClick={() => handleView(order.ORDERNUMBER)}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          <Eye className="h-5 w-5" />
                        </button>
                        {/* <button className="text-gray-600 hover:text-gray-800">
                          <Edit2 className="h-5 w-5" />
                        </button> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Pagination */}
        <div className="px-6 py-4 border-t border-gray-200">
          <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
            <div className="text-sm text-gray-500">
              Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
              {Math.min(currentPage * itemsPerPage, orders?.length)} of{" "}
              {orders?.length} entries
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 text-sm disabled:opacity-50"
              >
                Previous
              </button>
              {renderPageNumbers()}
              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className="px-3 py-1 text-sm disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersTable;
