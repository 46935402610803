import { Form, Select } from "antd";
import {
  Activity,
  BanknoteIcon,
  CalendarIcon,
  LayoutDashboard,
  PackageIcon,
  Percent,
  PercentIcon,
  ShoppingCartIcon,
  TrendingUpIcon,
  TruckIcon,
  UserPlusIcon,
  UsersIcon,
  XCircleIcon,
} from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { AnalyticCard } from "../../components/analyticsCard/analyticsCard";
import NavbarCCM from "../../components/navigation/ccmnavbar";
import API from "../../config/api";
import { setFilters } from "../../redux/slices/filterSlice";
import { GET, POST } from "../../utils/apiCalls";
import NavbarService from "../../components/navigation/servicenavbar";

const salesData = [
  { name: "P1", sales: 200 },
  { name: "P2", sales: 300 },
  { name: "P3", sales: 600 },
  { name: "P4", sales: 800 },
  { name: "P5", sales: 700 },
  { name: "P6", sales: 700 },
  { name: "P7", sales: 700 },
  { name: "P8", sales: 700 },
  { name: "P9", sales: 700 },
  { name: "P10", sales: 700 },
  { name: "P11", sales: 700 },
  { name: "P12", sales: 700 },
];
const salesValues = salesData.map((item) => item.sales);
interface FilterValues {
  year: string[];
  periods: string[];
  weeks: string[];
  country: string[];
  channel: string[];
  salesChannel: string[];
}

const ServiceCard = ({ title, children }: any) => (
  <div className="bg-gray-50 border border-gray-200 rounded-lg p-6">
    <h3 className="text-lg font-semibold text-blue-900 mb-4">{title}</h3>
    {children}
  </div>
);

export const WebDashboard: React.FC = () => {
  const { filters } = useSelector((state: any) => state.Filter);
  const dispatch = useDispatch();
  const [salesChannel2Options, setSalesChannel2Options] = useState<any>({});
  const [isSalesChannel2Disabled, setIsSalesChannel2Disabled] = useState(true);

  const [form] = Form.useForm();

  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <NavbarService />

      <div className="flex items-center gap-2 bg-white border-b border-gray-200 px-6 py-4">
        <LayoutDashboard className="text-gray-600" />
        <h1 className="text-xl font-medium ml-1">
          OMS System :
          <span className="font-bold"> Batches and Web Services</span>
        </h1>
      </div>
      <div className=" p-8">
        <div className="bg-white rounded-lg shadow-sm p-8">
          <div className="bg-blue-50 border-l-4 border-blue-600 p-6 mb-8 rounded-r-lg">
            <p>
              The below outlines the key batches and web services running in the
              Order Management System (OMS) to support various business
              processes.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <ServiceCard title="Order Processing">
              <ul className="list-disc pl-5 space-y-2">
                <li>Order Entry Checks: Validates incoming orders</li>
                <li>Order Status Changes: Monitors and updates in real-time</li>
              </ul>
            </ServiceCard>

            <ServiceCard title="Commerce Integration">
              <ul className="list-disc pl-5 space-y-2">
                <li>
                  Commercetools Sync:
                  <ul className="list-disc pl-5 mt-2 space-y-2">
                    <li>Order status updates</li>
                    <li>Product list retrieval</li>
                    <li>Customer list retrieval</li>
                  </ul>
                </li>
              </ul>
            </ServiceCard>

            <ServiceCard title="Content Management">
              <ul className="list-disc pl-5 space-y-2">
                <li>Global IMS: Syncs images, text, and cover info</li>
                <li>CandyJet: Sends customized images for printing</li>
              </ul>
            </ServiceCard>

            <ServiceCard title="Customer Communications">
              <ul className="list-disc pl-5 space-y-2">
                <li>Klaviyo: Pushes order status updates</li>
                <li>Salesforce CCM: Updates order and sales info</li>
              </ul>
            </ServiceCard>

            <ServiceCard title="Payment Processing">
              <ul className="list-disc pl-5 space-y-2">
                <li>
                  Payment Gateways:
                  <ul className="list-disc pl-5 mt-2 space-y-2">
                    <li>Worldpay</li>
                    <li>Adyen</li>
                    <li>PayPal</li>
                  </ul>
                </li>
              </ul>
            </ServiceCard>

            <ServiceCard title="Shipping & Logistics">
              <ul className="list-disc pl-5 space-y-2">
                <li>TDI Integration: Generates carrier labels</li>
                <li>Expedito: Handles shipping declarations</li>
              </ul>
            </ServiceCard>

            <ServiceCard title="External Systems">
              <ul className="list-disc pl-5 space-y-2">
                <li>Amazon Vendor Central: Manages data exchange</li>
                <li>SAP Integration: Exports financial data</li>
              </ul>
            </ServiceCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDashboard;
