const ForcastShipping = () => {
  // const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { key: "orderNo", label: "Order Number" },
    { key: "currentStage", label: "Current Stage" },
    { key: "channel", label: "Channel" },
    { key: "salesChannel", label: "Sales Channel" },
    { key: "salesChannel2", label: "Sales Channel2" },
    { key: "amount", label: "Amount (Excl.Tax)" },
    { key: "estArrdate", label: "Estimated Arrival Date" },
    { key: "estShidate", label: "Estimated Shipping Date" },
    { key: "createdDate", label: "Created Date" },
    { key: "invoiceDate", label: "Invoice Date" },
    { key: "shippedDate", label: "Shipped Date" },
  ];
  const dummyData = [
    {
      orderNo: 2345634,
      currentStage: "Delivered",
      channel: "B2B",
      salesChannel: "DISTRIBUTOR",
      salesChannel2: "KSW",
      amount: 719,
      estArrdate: "22 Dec 2022",
      estShidate: "20 Dec 2022",
      createdDate: "19 Dec 2022",
      invoiceDate: "03 Jan 2023",
      shippedDate: "03 Jan 2023",
    },
    {
      orderNo: 2345634,
      currentStage: "Delivered",
      channel: "B2B",
      salesChannel: "DISTRIBUTOR",
      salesChannel2: "KSW",
      amount: 719,
      estArrdate: "22 Dec 2022",
      estShidate: "20 Dec 2022",
      createdDate: "19 Dec 2022",
      invoiceDate: "03 Jan 2023",
      shippedDate: "03 Jan 2023",
    },
    {
      orderNo: 2345634,
      currentStage: "Delivered",
      channel: "B2B",
      salesChannel: "DISTRIBUTOR",
      salesChannel2: "KSW",
      amount: 719,
      estArrdate: "22 Dec 2022",
      estShidate: "20 Dec 2022",
      createdDate: "19 Dec 2022",
      invoiceDate: "03 Jan 2023",
      shippedDate: "03 Jan 2023",
    },
    {
      orderNo: 2345634,
      currentStage: "Delivered",
      channel: "B2B",
      salesChannel: "DISTRIBUTOR",
      salesChannel2: "KSW",
      amount: 719,
      estArrdate: "22 Dec 2022",
      estShidate: "20 Dec 2022",
      createdDate: "19 Dec 2022",
      invoiceDate: "03 Jan 2023",
      shippedDate: "03 Jan 2023",
    },
  ];
  const totalRow: any = {
    amount: 6543245,
  };
  const formatCurrency = (value: any) => {
    if (isNaN(value)) return "-";
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  // const formatPercentage = (value: any) => {
  //   if (isNaN(value)) return "-";
  //   return `${value?.toFixed(1)}%`;
  // };
  // const getPercentageColor = (value: any) => {
  //   const numValue = parseFloat(value);
  //   if (numValue > 0) return "bg-green-100 text-green-800";
  //   if (numValue < 0) return "bg-red-100 text-red-800";
  //   return "text-gray-600";
  // };
  return (
    <div className="bg-white rounded-lg shadow p-4">
      <h2 className="text-lg font-semibold text-[#a1343c] italic mb-2">
        Valerie/Taktim - Forcast Shipping
      </h2>
      <div className="overflow-x-auto">
        {false ? (
          <div>Loading...</div>
        ) : (
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                {columns.map(({ key, label }) => (
                  <th
                    key={key}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  >
                    <div className="flex items-center space-x-1">
                      <span>{label}</span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {dummyData.map((row, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {row.orderNo}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {row.currentStage}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {row.channel}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {row.salesChannel}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {row.salesChannel2}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {formatCurrency(row.amount)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {row.estArrdate}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {row.estShidate}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {row.createdDate}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {row.invoiceDate}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                    {row.shippedDate}
                  </td>
                </tr>
              ))}
              <tr className="bg-blue-600">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                  Total
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                  {formatCurrency(totalRow?.amount)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ForcastShipping;
