import React, { useEffect, useState } from "react";
import Navbar from "../../components/navigation/navbar";
import DemandNavigation from "./components/tabBar";
import Carrier from "./components/carrier";
import FactoryConsumption from "./components/factoryConsumption";
import WheatherForcast from "./components/wheatherForcast";
import ForcastShipping from "./components/forcastShipping";
import FreightShipment from "./components/freightShipment";
import Filters from "../../components/filters";
import { useSelector } from "react-redux";
import { Form } from "antd";
import { POST } from "../../utils/apiCalls";
import API from "../../config/api";

const Supply = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [headingTxt, setHeadingTxt] = useState("Carrier");
  const { filters, lastFetched } = useSelector((state: any) => state.Filter);
  const [form] = Form.useForm();
  const [salesChannel2Options, setSalesChannel2Options] = useState<any>({});

  const [isSalesChannel2Disabled, setIsSalesChannel2Disabled] = useState(true);
  const [formData, setFormData] = useState<any>({
    year: ["2024"],
  });
  const [selectedTabItem, setSelectedTabItem] = useState<any>({
    id: 1,
    title: "Carrier",
    subMenu: [
      {
        id: 1,
        title: "Carrier",
      },
      {
        id: 2,
        title: "Carrier Tracking",
      },
    ],
  });
  useEffect(() => {
    form.setFieldsValue({ year: formData.year });
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleFormChange = async (changedValues: any, allValues: any) => {
    try {
      if (changedValues.salesChannel !== undefined) {
        if (
          changedValues.salesChannel &&
          changedValues.salesChannel.length > 0
        ) {
          const response = await POST(API.FILTERSBYCHANNEL, {
            channel: changedValues.salesChannel,
          });
          setSalesChannel2Options(response);
          setIsSalesChannel2Disabled(false);
        } else {
          form.setFieldsValue({
            salesChannel: [],
            salesChannel2: [],
          });
          setSalesChannel2Options({});
          setIsSalesChannel2Disabled(true);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const filterObject = (obj: Record<string, any>) => {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([key, value]) => Array.isArray(value) && value.length > 0
      )
    );
  };
  const handleFormSubmit = async (value: any) => {
    try {
      let values = filterObject(value);
      // let obj = {
      //   ...values,
      //   page: 1,
      //   limit: 10,
      //   groupBy: headingTxt === "Demand by Country" ? "COUNTRY" : "CREATEDDATE",
      // };
      setFormData(values);
      // await Promise.all([fetchDemands(obj)]);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <Navbar />
      <div className="px-6 pt-4">
        <div className="flex items-center bg-blue-50 px-4 pt-4">
          <Filters
            filters={
              selectedTabItem?.id === 2
                ? filters.map((item: any) => {
                    if (
                      item.name === "channel" ||
                      item.name === "salesChannel"
                    ) {
                      return {
                        ...item,
                        disabled: true,
                      };
                    } else {
                      return item;
                    }
                  })
                : filters
            }
            form={form}
            handleFormChange={handleFormChange}
            handleFormFinish={handleFormSubmit}
            isSalesChannel2Disabled={
              false
              // selectedTabItem?.id === 2 ? true : isSalesChannel2Disabled
            }
            salesChannel2Options={salesChannel2Options}
          />
        </div>
      </div>
      <div className="flex items-center gap-2 bg-white border-b border-gray-200 px-6 py-4">
        <DemandNavigation
          selectedTabTxt={(val: any) => setHeadingTxt(val)}
          selectedItem={(item: any) => setSelectedTabItem(item)}
        />
      </div>
      <div className="p-4 md:p-6">
        {selectedTabItem?.id === 1 ? (
          <Carrier
            item={selectedTabItem}
            subItem={"Carrier"}
            filters={formData}
          />
        ) : selectedTabItem?.id === 2 ? (
          <FactoryConsumption />
        ) : selectedTabItem?.id === 3 ? (
          <WheatherForcast />
        ) : selectedTabItem?.id === 4 ? (
          <ForcastShipping />
        ) : selectedTabItem?.id === 5 ? (
          <FreightShipment />
        ) : null}
      </div>
    </div>
  );
};

export default Supply;
