import { Form } from "antd";
import {
  ArrowUpDown,
  BanknoteIcon,
  CalendarIcon,
  CheckCircle,
  ClipboardCheck,
  Package,
  PackageIcon,
  ShoppingCart,
  Truck,
  TruckIcon,
  XCircleIcon,
} from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AnalyticCard } from "../../../components/analyticsCard/analyticsCard";
import LoadingBox from "../../../components/LoadingBox";
import API from "../../../config/api";
import { setFilters } from "../../../redux/slices/filterSlice";
import { GET, POST } from "../../../utils/apiCalls";

const DaliyDemand = (props: any) => {
  const { filters } = useSelector((state: any) => state.Filter);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [sortField, setSortField] = useState<any>("CREATEDDATE");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [demandData, setDemandData] = useState<any>({ rows: [], total: {} });
  const [ga4TrafficData, setGa4TrafficData] = useState<any>({
    rows: [],
    total: 0,
    metrics: {},
    totalPages: 0,
  });
  const calculateSum = (array: any, field: any) => {
    return (
      (array?.length &&
        array?.reduce((sum: any, item: any) => {
          const value = parseFloat(item[field]) || 0; // Handle non-numeric or null values
          return sum + value;
        }, 0)) ||
      0
    );
  };
  const [form] = Form.useForm();
  useEffect(() => {
    getFilters();
  }, [1]);
  useEffect(() => {
    form.setFieldsValue({ year: props.filters.year });
    if (props?.subItem === "Demand (Web with GA4)") {
      fetchDemandData({
        ...props.filters,
        year: props.filters.year,
        page: currentPage,
        limit: itemsPerPage,
        sort: sortField,
        order: sortOrder,
        salesChannel: ["MARKETPLACE", "WEBSITE"],
        channel: ["B2C"],
      });
    } else if (props?.subItem === "Demand - GA4 Traffic Overview") {
      fetchGA4Traffic({
        ...props.filters,
        year: props.filters.year,
        sort: ga4TrafficCol.some((item: any) => item.key === sortField)
          ? sortField
          : null,
        order: ga4TrafficCol.some((item: any) => item.key === sortField)
          ? sortOrder
          : null,
        page: currentPage,
        limit: itemsPerPage,
      });
    } else {
      props.fetchDemands({
        ...props.filters,
        year: props.filters.year,
        sort: webGa4column.some((item: any) => item.key1 === sortField)
          ? sortField
          : null,
        order: webGa4column.some((item: any) => item.key1 === sortField)
          ? sortOrder
          : null,
        page: currentPage,
        limit: itemsPerPage,
        groupBy:
          props?.subItem === "Demand by Date" ? "CREATEDDATE" : "COUNTRY",
      });
    }
  }, [
    currentPage,
    itemsPerPage,
    props?.subItem,
    props.filters,
    sortField,
    sortOrder,
  ]);

  const fetchDemandData = async (filterValues: any = {}) => {
    setIsLoading(true);
    try {
      const response: any = await POST(API.DAILY_DEMAND_AGENCIES, filterValues);
      if (response) {
        setDemandData(response);
      }
    } catch (error) {
      console.error("Error fetching demand data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchGA4Traffic = async (filterValues: any = {}) => {
    setIsLoading(true);
    try {
      const response: any = await POST(API.GA4_TRAFFIC, filterValues);
      if (response) {
        setGa4TrafficData(response);
      }
    } catch (error) {
      console.error("Error fetching demand data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getFilters = async () => {
    try {
      if (filters.length > 0) {
        return;
      }
      const response: any = await GET(API.FILTERS);

      if (response.length) {
        dispatch(setFilters(response));
      }

      const initialValues: any = {
        year: [],
        periods: [],
        weeks: [],
        country: [],
        channel: [],
        salesChannel: [],
      };
      form.setFieldsValue(initialValues);
    } catch (error) {
      console.log(error);
    }
  };
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const formatCurrencyToK = (value: number): string => {
    const formatted =
      value >= 1000
        ? `${formatter.format(value / 1000)}K`
        : formatter.format(value);

    return `€${formatted}`;
  };
  const metrics = [
    {
      icon: <XCircleIcon className="w-5 h-5 text-red-500" />,
      value: formatCurrencyToK(props.total?.demandNsv),
      label: "Demand NSV",
      headerIcon: <XCircleIcon className="w-4 h-4 text-red-500" />,
    },
    {
      icon: <PackageIcon className="w-5 h-5 text-amber-500" />,
      value: formatCurrencyToK(props.total?.demandNsvLy),
      label: "Demand NSV vs LY",
      headerIcon: <PackageIcon className="w-4 h-4 text-amber-500" />,
    },
    {
      icon: <BanknoteIcon className="w-5 h-5 text-blue-600" />,
      value: props.total?.totalOrdersVsLy,
      label: "Demand Orders vs LY (%)",
      headerIcon: <BanknoteIcon className="w-4 h-4 text-blue-600" />,
    },
    {
      icon: <TruckIcon className="w-5 h-5 text-green-600" />,
      value: formatter.format(props.total?.totalOrdersOms),
      label: "Number Of orders",
      headerIcon: <TruckIcon className="w-4 h-4 text-green-600" />,
    },

    {
      icon: <CalendarIcon className="w-5 h-5 text-purple-500" />,
      value: props.total?.totalAovVsLy,
      label: "AOV vs LY",
      headerIcon: <CalendarIcon className="w-4 h-4 text-purple-500" />,
    },
  ];

  const metrics3 = [
    {
      icon: <ShoppingCart className="w-5 h-5 text-blue-600" />, // Represents orders and demand
      value: "1234",
      label: "Demand Orders Count",
      headerIcon: <ShoppingCart className="w-4 h-4 text-blue-600" />,
    },
    {
      icon: <ClipboardCheck className="w-5 h-5 text-green-600" />, // Represents invoiced orders
      value: "12345",
      label: "Number Of Orders Invoiced from this Demand",
      headerIcon: <ClipboardCheck className="w-4 h-4 text-green-600" />,
    },
    {
      icon: <CheckCircle className="w-5 h-5 text-green-600" />, // Represents percentage or success
      value: "96%",
      label: "Invoiced from this Demand",
      headerIcon: <CheckCircle className="w-4 h-4 text-green-600" />,
    },
    {
      icon: <Truck className="w-5 h-5 text-red-500" />, // Represents shipped orders
      value: "13543",
      label: "Number Of Orders Shipped from this Demand",
      headerIcon: <Truck className="w-4 h-4 text-red-500" />,
    },
    {
      icon: <Package className="w-5 h-5 text-purple-500" />, // Represents shipped demand
      value: "5.2",
      label: "Shipped from this Demand",
      headerIcon: <Package className="w-4 h-4 text-purple-500" />,
    },
  ];
  const columns = [
    { key: "MARS_CREATED_YEAR", label: "Mars Year" },
    { key: "MARS_CREATED_PERIOD", label: "Mars Period" },
    { key: "CREATEDDATE", label: "Ordered Date" },
    { key: "total_nsv", label: "Demand NSV € (OMS)" },
    { key: "total_nsv", label: "Demand NSV € LY (OMS)" },
    // { key: "DEMAND_NSV_VS_LY", label: "Demand NSV vs. LY (%)" },
    { key: "order_count", label: "No of Orders (OMS)" },
    { key: "order_count", label: "No of Orders (OMS) LY" },
    { key: "order_count", label: "No of Orders vs. LY (%)" },
    { key: "total_nsv", label: "Demand AOV € (OMS)" },
    { key: "total_nsv", label: "Demand AOV € (OMS) LY" },
    { key: "total_nsv", label: "Demand AOV vs LY (%)" },
    { key: "leads_count", label: "QUOTES/LEADS (OMS)" },
    { key: "leads_nsv", label: "NSV QUOTES/LEADS (OMS)" },
  ];

  const demandCountryCol = [
    { key: "COUNTRY", label: "COUNTRY" },
    { key: "total_nsv", label: "Demand NSV € (OMS)" },
    { key: "total_nsv", label: "Demand NSV € LY (OMS)" },
    // { key: "DEMAND_NSV_VS_LY", label: "Demand NSV vs. LY (%)" },
    { key: "order_count", label: "No of Orders (OMS)" },
    { key: "order_count", label: "No of Orders (OMS) LY" },
    { key: "order_count", label: "No of Orders vs. LY (%)" },
    { key: "total_nsv", label: "Demand AOV € (OMS)" },
    { key: "total_nsv", label: "Demand AOV € (OMS) LY" },
    { key: "total_nsv", label: "Demand AOV vs LY (%)" },
    { key: "leads_count", label: "QUOTES/LEADS (OMS)" },
    { key: "leads_nsv", label: "NSV QUOTES/LEADS (OMS)" },
  ];

  const ga4TrafficCol = [
    { key: "MARS_PERIOD", label: "MARS FULL PERIOD" },
    { key: "CalendarID", label: "Date" },
    { key: "Country", label: "Country" },
    { key: "Channel", label: "Channel" },
    { key: "totalUsers", label: "Total Users" },
    { key: "sessions", label: "Sessions" },
  ];
  const demandOrderCol = [
    { key: "createdDate", label: "Created Date" },
    { key: "orderNo", label: "Order Number" },
    { key: "stage", label: "Stage" },
    { key: "invoiceDate", label: "Invoice Date" },
    { key: "shippedDate", label: "Shipped Date" },
    { key: "demandOrdercount", label: "Demand Order Count" },
    { key: "labelcount", label: "Label Check Orders Count" },
    { key: "invoicecount", label: "Invoice Orders Count" },
    { key: "shippedcount", label: "Shipped Orders Count" },
    { key: "invoiced", label: "Invoiced %" },
    { key: "shipped", label: "Shipped %" },
  ];
  const demandOrderCol2 = [
    { key: "period", label: "Period ordered" },
    { key: "invoiced", label: "Invoiced %" },
    { key: "shipped", label: "Shipped %" },
  ];

  // const ga4TrafficData = [
  //   {
  //     period: "2024 P13 W3",
  //     date: "12 DEC 2024",
  //     country: "FR",
  //     channel: "B2C",
  //     TotalUsers: 382,
  //     sessions: 179,
  //   },
  //   {
  //     period: "2024 P13 W3",
  //     date: "12 DEC 2024",
  //     country: "FR",
  //     channel: "B2C",
  //     TotalUsers: 382,
  //     sessions: 179,
  //   },
  // ];
  const demandOrderData = [
    {
      createdDate: "12 Dec 2023",
      orderNo: 5647,
      stage: "shipped",
      invoiceDate: "12 Dec 2023",
      shippedDate: "12 Dec 2023",
      demandOrdercount: 79,
      labelcount: 38,
      invoicecount: 69,
      shippedcount: 59,
      invoiced: "30%",
      shipped: "45%",
    },
    {
      createdDate: "12 Dec 2023",
      orderNo: 5647,
      stage: "shipped",
      invoiceDate: "12 Dec 2023",
      shippedDate: "12 Dec 2023",
      demandOrdercount: 79,
      labelcount: 38,
      invoicecount: 69,
      shippedcount: 59,
      invoiced: "30%",
      shipped: "45%",
    },
  ];
  const demandOrderData2 = [
    {
      period: "P01",
      invoiced: "30%",
      shipped: "45%",
    },
    {
      period: "P01",
      invoiced: "30%",
      shipped: "45%",
    },
  ];

  const getPercentageColor = (value: any) => {
    const numValue = parseFloat(value);
    if (numValue > 0) return "bg-green-100 text-green-800";
    if (numValue < 0) return "bg-red-100 text-red-800";
    return "text-gray-600";
  };

  const formatCurrency = (value: any) => {
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatPercentage = (value: any) => {
    return `${value?.toFixed(1)}%`;
  };

  const renderPageNumbers = () => {
    const pages = [];
    const showAround = 1;

    pages.push(1);

    if (currentPage > 2 + showAround) {
      pages.push("...");
    }

    for (
      let i = Math.max(2, currentPage - showAround);
      i <= Math.min(props.totalPages - 1, currentPage + showAround);
      i++
    ) {
      pages.push(i);
    }

    if (currentPage < props.totalPages - (1 + showAround)) {
      pages.push("...");
    }

    if (props.totalPages > 1) {
      pages.push(props.totalPages);
    }

    return (
      <div className="flex items-center space-x-1">
        {pages.map((page, index) => (
          <React.Fragment key={index}>
            {page === "..." ? (
              <span className="px-2 py-1 text-gray-500">...</span>
            ) : (
              <button
                onClick={() => setCurrentPage(page as number)}
                className={`min-w-[32px] px-2 py-1 rounded text-sm ${
                  currentPage === page
                    ? "bg-gray-600 text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                {page}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };
  const handleEntriesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const webGa4column = [
    { key: "MARS_YEAR", key1: "MARS_CREATED_YEAR", label: "Year" },
    { key: "MARS_PERIOD", key1: "MARS_CREATED_PERIOD", label: "Period" },
    { key: "MARS_WEEK", key1: "MARS_CREATED_WEEK", label: "Week" },
    { key: "ORDERED_DATE", key1: "CREATEDDATE", label: "Date" },
    {
      key: "TRANSACTIONS_GA4",
      key1: "TRANSACTIONS_GA4",
      label: "GA4 Transactions",
    },
    {
      key: "TRANSACTIONS_GA4_LY",
      key1: "TRANSACTIONS_GA4_LY",
      label: "GA4 Transactions LY",
    },
    { key: "GA4_DEMAND_NSV", key1: "GA4_DEMAND_NSV", label: "GA4 Demand NSV" },
    {
      key: "GA4_DEMAND_NSV_LY",
      key1: "GA4_DEMAND_NSV_LY",
      label: "GA4 Demand NSV LY",
    },
    { key: "NO_OF_ORDERS_OMS", key1: "order_count", label: "Orders" },
    { key: "NO_OF_ORDERS_OMS_LY", key1: "order_count", label: "Orders LY" },
    { key: "NO_OF_ORDERS_VS_LY", key1: "order_count", label: "Orders vs LY %" },
    { key: "DEMAND_NSV_OMS", key1: "total_nsv", label: "Demand NSV" },
    { key: "DEMAND_NSV_OMS_LY", key1: "total_nsv", label: "Demand NSV LY" },
    { key: "DEMAND_NSV_VS_LY", key1: "total_nsv", label: "Demand NSV vs LY %" },
    { key: "DEMAND_AOV_OMS", key1: "total_nsv", label: "AOV" },
    { key: "DEMAND_AOV_OMS_LY", key1: "total_nsv", label: "AOV LY" },
    { key: "DEMAND_AOV_VS_LY", key1: "total_nsv", label: "AOV vs LY %" },
    { key: "TOTAL_USERS_GA4", key1: "TOTAL_USERS_GA4", label: "Users" },
    {
      key: "TOTAL_USERS_GA4_LY",
      key1: "TOTAL_USERS_GA4_LY",
      label: "Users LY",
    },
    {
      key: "CVR_GA4_SESSIONS",
      key1: "CVR_GA4_SESSIONS",
      label: "GA4 Sessions CVR",
    },
  ];
  const formatValue = (key: string, value: any) => {
    if (key.includes("VS_LY") || key.includes("CVR")) {
      return formatPercentage(value);
    }
    if (key.includes("NSV") || key.includes("AOV")) {
      return formatCurrency(value);
    }
    if (key === "ORDERED_DATE") {
      return moment(value).format("DD MMM YYYY");
    }
    return value?.toLocaleString() || "-";
  };
  const handleSort = (column: string) => {
    if (sortField === column) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSortField(column);
      setSortOrder("ASC");
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
        {props?.subItem === "Demand by Date" ||
        props?.subItem === "Demand by Country" ? (
          <div className="sm:col-span-12 md:col-span-3 lg:col-span-2 space-y-4">
            {metrics.map((metric, index) => (
              <AnalyticCard
                key={index}
                icon={metric.icon}
                value={metric.value}
                label={metric.label}
              />
            ))}
          </div>
        ) : props?.subItem === "Demand (Web with GA4)" ? (
          <div className="sm:col-span-12 md:col-span-3 lg:col-span-2 space-y-4">
            {metrics.map((metric, index) => (
              <AnalyticCard
                key={index}
                icon={metric.icon}
                value={metric.value}
                label={metric.label}
              />
            ))}
          </div>
        ) : props?.subItem === "Demand - GA4 Traffic Overview" ? null : (
          <div className="sm:col-span-12 md:col-span-3 lg:col-span-2 space-y-4">
            {metrics3.map((metric, index) => (
              <AnalyticCard
                key={index}
                icon={metric.icon}
                value={metric.value}
                label={metric.label}
              />
            ))}
          </div>
        )}

        {/* <div className="sm:col-span-12 md:col-span-9 lg:col-span-10 space-y-4"> */}
        {props?.subItem === "Demand by Date" ? (
          <div className="sm:col-span-12 md:col-span-9 lg:col-span-10 space-y-4">
            <div className="bg-white rounded-lg shadow">
              <div className="p-4 border-b border-gray-200">
                <h2 className="text-lg font-semibold text-gray-700">
                  {"Demand by Date"}
                </h2>
              </div>
              <div className="overflow-x-auto">
                {props.loading ? (
                  <LoadingBox />
                ) : (
                  <>
                    <div className="flex items-center gap-2 m-2 ml-4">
                      <span className="text-sm text-gray-600">Show</span>
                      <select
                        value={itemsPerPage}
                        onChange={handleEntriesChange}
                        className="px-2 py-1 border border-gray-300 rounded-md text-sm"
                      >
                        {[10, 25, 50, 100].map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <span className="text-sm text-gray-600">entries</span>
                    </div>

                    <table className="w-full">
                      <thead className="bg-gray-50">
                        <tr>
                          {columns.map(({ key, label }) => (
                            <th
                              key={label}
                              onClick={() => handleSort(key)}
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                            >
                              <div className="flex items-center space-x-1">
                                <span>{label}</span>
                                <ArrowUpDown className="h-4 w-4" />
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {props.data?.map((row: any, index: number) => (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.MARS_YEAR}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.MARS_PERIOD}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {moment(row?.ORDERED_DATE).format("DD MMMM YYYY")}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {formatCurrency(row.DEMAND_NSV_EUR_OMS)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {formatCurrency(row.DEMAND_NSV_EUR_OMS_LY)}
                            </td>
                            {/* <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                row.DEMAND_NSV_VS_LY
                              )}`}
                            >
                              {formatPercentage(row.DEMAND_NSV_VS_LY)}
                            </span>
                          </td> */}
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.NO_OF_ORDERS_OMS}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.NO_OF_ORDERS_OMS_LY}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span
                                className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                  row.NO_OF_ORDERS_VS_LY
                                )}`}
                              >
                                {formatPercentage(row.NO_OF_ORDERS_VS_LY)}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {formatCurrency(row.DEMAND_AOV_EUR_OMS)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {formatCurrency(row.DEMAND_AOV_EUR_OMS)}
                            </td>{" "}
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              <span
                                className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                  row.DEMAND_AOV_VS_LY
                                )}`}
                              >
                                {formatPercentage(row.DEMAND_AOV_VS_LY)}
                              </span>
                            </td>{" "}
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.QUOTES_LEADS_OMS}
                            </td>{" "}
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {formatCurrency(
                                parseInt(row.NSV_QUOTES_LEADS_OMS)
                              )}
                            </td>
                          </tr>
                        ))}
                        <tr className="bg-blue-600">
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                            {/* {totalRow.MARS_YEAR} */}Total
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-white"></td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-white"></td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {formatCurrency(props.total?.demandNsv)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {formatCurrency(props.total?.demandNsvLy)}
                          </td>
                          {/* <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-3 py-1 text-sm font-medium text-white">
                              {formatPercentage(totalRow.DEMAND_NSV_VS_LY)}
                            </span>
                          </td> */}
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {props.total?.totalOrdersOms?.toLocaleString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {props.total?.totalOrdersOmsLy.toLocaleString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-3 py-1 text-sm font-medium text-white">
                              {formatPercentage(props?.total?.totalOrdersVsLy)}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {formatCurrency(props?.total?.totalAovOms)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {formatCurrency(props?.total?.totalAovOmsLy)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {formatPercentage(props?.total?.totalAovVsLy)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {props?.total?.totalQuotesLeads?.toLocaleString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {formatCurrency(props?.total?.totalNsvQuotesLeads)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
              </div>
              <div className="px-6 py-4 border-t border-gray-200">
                <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                  <div className="text-sm text-gray-500">
                    Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                    {Math.min(
                      (currentPage - 1) * itemsPerPage + props.data?.length || 0
                    )}{" "}
                    of {props?.totalRows} entries
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                      disabled={currentPage === 1}
                      className="px-3 py-1 text-sm disabled:opacity-50"
                    >
                      Previous
                    </button>
                    {renderPageNumbers()}
                    <button
                      onClick={() =>
                        setCurrentPage((prev) =>
                          Math.min(prev + 1, props.totalPages)
                        )
                      }
                      disabled={currentPage === props.totalPages}
                      className="px-3 py-1 text-sm disabled:opacity-50"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : props?.subItem === "Demand by Country" ? (
          <div className="sm:col-span-12 md:col-span-9 lg:col-span-10 space-y-4">
            <div className="bg-white rounded-lg shadow">
              <div className="p-4 border-b border-gray-200">
                <h2 className="text-lg font-semibold text-gray-700">
                  {/* {headingTxt} */}
                  Demand by Country
                </h2>
              </div>
              <div className="overflow-x-auto">
                {props.loading ? (
                  <LoadingBox />
                ) : (
                  <>
                    <div className="flex items-center gap-2 m-2 ml-4">
                      <span className="text-sm text-gray-600">Show</span>
                      <select
                        value={itemsPerPage}
                        onChange={handleEntriesChange}
                        className="px-2 py-1 border border-gray-300 rounded-md text-sm"
                      >
                        {[10, 25, 50, 100].map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <span className="text-sm text-gray-600">entries</span>
                    </div>

                    <table className="w-full">
                      <thead className="bg-gray-50">
                        <tr>
                          {demandCountryCol.map(
                            ({ key, label }, index: number) => (
                              <th
                                key={index}
                                onClick={() => handleSort(key)}
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                              >
                                <div className="flex items-center space-x-1">
                                  <span>{label}</span>
                                  <ArrowUpDown className="h-4 w-4" />
                                </div>
                              </th>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {props?.data?.map((row: any, index: number) => (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.COUNTRY}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {formatCurrency(row.DEMAND_NSV_EUR_OMS)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {formatCurrency(row.DEMAND_NSV_EUR_OMS_LY)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.NO_OF_ORDERS_OMS.toLocaleString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.NO_OF_ORDERS_OMS_LY.toLocaleString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span
                                className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                  row.NO_OF_ORDERS_VS_LY
                                )}`}
                              >
                                {formatPercentage(row.NO_OF_ORDERS_VS_LY)}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {formatCurrency(row.DEMAND_AOV_EUR_OMS)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {formatCurrency(row.DEMAND_AOV_EUR_OMS_LY)}
                            </td>{" "}
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              <span
                                className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                  row.DEMAND_AOV_VS_LY
                                )}`}
                              >
                                {formatPercentage(row.DEMAND_AOV_VS_LY)}
                              </span>
                            </td>{" "}
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.QUOTES_LEADS_OMS.toLocaleString()}
                            </td>{" "}
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {formatCurrency(
                                parseInt(row.NSV_QUOTES_LEADS_OMS)
                              )}
                            </td>
                          </tr>
                        ))}
                        <tr className="bg-blue-600">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-white"></td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {/* {formatCurrency(totalRow.DEMAND_NSV)} */}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {/* {formatCurrency(totalRow.DEMAND_NSV_LY)} */}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {props.total?.totalOrdersOms?.toLocaleString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {props.total?.totalOrdersOmsLy.toLocaleString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-3 py-1 text-sm font-medium text-white">
                              {formatPercentage(props?.total?.totalOrdersVsLy)}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {formatCurrency(props?.total?.totalAovOms)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {formatCurrency(props?.total?.totalAovOmsLy)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {props?.total?.totalAovVsLy}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {props?.total?.totalQuotesLeads}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {formatCurrency(props?.total?.totalNsvQuotesLeads)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
              </div>
              <div className="px-6 py-4 border-t border-gray-200">
                <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                  <div className="text-sm text-gray-500">
                    Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                    {Math.min(
                      (currentPage - 1) * itemsPerPage + props.data?.length || 0
                    )}{" "}
                    of {props?.totalRows} entries
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                      disabled={currentPage === 1}
                      className="px-3 py-1 text-sm disabled:opacity-50"
                    >
                      Previous
                    </button>
                    {renderPageNumbers()}
                    <button
                      onClick={() =>
                        setCurrentPage((prev) =>
                          Math.min(prev + 1, props.totalPages)
                        )
                      }
                      disabled={currentPage === props.totalPages}
                      className="px-3 py-1 text-sm disabled:opacity-50"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : props?.subItem === "Demand (Web with GA4)" ? (
          <div className="sm:col-span-12 md:col-span-9 lg:col-span-10 space-y-4">
            <div className="bg-white rounded-lg shadow h-full">
              <div className="p-4 border-gray-200 h-full">
                {/* <h2 className="text-lg font-semibold text-gray-700">
              {headingTxt}
            </h2> */}
                <div className="overflow-x-auto">
                  {isLoading ? (
                    <LoadingBox />
                  ) : (
                    <>
                      <div className="flex items-center gap-2 m-2 ml-4">
                        <span className="text-sm text-gray-600">Show</span>
                        <select
                          value={itemsPerPage}
                          onChange={handleEntriesChange}
                          className="px-2 py-1 border border-gray-300 rounded-md text-sm"
                        >
                          {[10, 25, 50, 100].map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <span className="text-sm text-gray-600">entries</span>
                      </div>

                      <table className="w-full">
                        <thead className="bg-gray-50">
                          <tr>
                            {webGa4column.map(({ key, key1, label }, index) => (
                              <th
                                key={index}
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                                onClick={() => handleSort(key1)}
                              >
                                <div className="flex items-center space-x-1">
                                  <span>{label}</span>
                                  <ArrowUpDown className="h-4 w-4" />
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {demandData.rows?.map((row: any, index: any) => (
                            <tr key={index} className="hover:bg-gray-50">
                              {webGa4column.map((column: any, index) => (
                                <td
                                  key={index}
                                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-600"
                                >
                                  {column.key.includes("VS_LY") ||
                                  column.key.includes("CVR") ? (
                                    <span
                                      className={`px-2 py-1 rounded-full ${getPercentageColor(
                                        row[column.key]
                                      )}`}
                                    >
                                      {formatValue(column.key, row[column.key])}
                                    </span>
                                  ) : (
                                    formatValue(column.key, row[column.key])
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                          <tr className="bg-blue-600">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                              {/* {totalRow.MARS_YEAR} */}Total
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-white"></td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-white"></td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-white"></td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              {demandData.total?.totalTransactionsGa4?.toLocaleString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              {demandData.total?.totalTransactionsGa4Ly?.toLocaleString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              {demandData.total?.totalGA4NSV?.toLocaleString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              {" "}
                              {demandData.total?.totalGA4NSVLy?.toLocaleString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              {demandData.total?.totalOrdersOms?.toLocaleString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              {demandData.total?.totalOrdersOmsLy?.toLocaleString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              {formatCurrency(demandData?.total?.totalNsvOms)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              {" "}
                              {formatCurrency(demandData?.total?.totalNsvOmsLy)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              {formatCurrency(
                                demandData?.total?.totalDemandAOV
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              {demandData?.total?.totalUsersGa4?.toLocaleString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              {demandData?.total?.totalUsersGa4Ly?.toLocaleString()}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
                <div className="px-6 py-4 border-t border-gray-200">
                  <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                    <div className="text-sm text-gray-500">
                      Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                      {Math.min(
                        (currentPage - 1) * itemsPerPage + props.data?.length ||
                          0
                      )}{" "}
                      of {props?.totalRows} entries
                    </div>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() =>
                          setCurrentPage((prev) => Math.max(prev - 1, 1))
                        }
                        disabled={currentPage === 1}
                        className="px-3 py-1 text-sm disabled:opacity-50"
                      >
                        Previous
                      </button>
                      {renderPageNumbers()}
                      <button
                        onClick={() =>
                          setCurrentPage((prev) =>
                            Math.min(prev + 1, props.totalPages)
                          )
                        }
                        disabled={currentPage === props.totalPages}
                        className="px-3 py-1 text-sm disabled:opacity-50"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : props?.subItem === "Demand - GA4 Traffic Overview" ? (
          <>
            <div className=" sm:col-span-12 md:col-span-12 lg:col-span-12 space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
                <div className="sm:col-span-12 md:col-span-9 lg:col-span-12 space-y-4">
                  <div className="bg-white rounded-lg shadow p-6">
                    {/* Metrics Cards */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                      <div className="p-4 rounded-lg bg-gray-50">
                        <h3 className="text-sm text-gray-600 mb-1">Users</h3>
                        <p className="text-2xl font-semibold">
                          {calculateSum(
                            ga4TrafficData?.metrics,
                            "totalUsers"
                          )?.toLocaleString()}
                        </p>
                      </div>
                      <div className="p-4 rounded-lg bg-gray-50">
                        <h3 className="text-sm text-gray-600 mb-1">Sessions</h3>
                        <p className="text-2xl font-semibold">
                          {calculateSum(
                            ga4TrafficData?.metrics,
                            "sessions"
                          )?.toLocaleString()}
                        </p>
                      </div>
                      {/* <div className="p-4 rounded-lg bg-gray-50">
                        <h3 className="text-sm text-gray-600 mb-1">
                          # of Sessions per User
                        </h3>
                        <p className="text-2xl font-semibold">
                          {(
                            calculateSum(ga4TrafficData?.metrics, "sessions") /
                            calculateSum(ga4TrafficData?.metrics, "totalUsers")
                          )?.toFixed(2)}
                        </p>
                      </div> */}
                    </div>

                    {/* Chart */}
                    <div className="flex w-full h-64">
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                          data={ga4TrafficData?.metrics}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="periods" tick={{ fontSize: 12 }} />
                          <YAxis
                            tick={{ fontSize: 12 }}
                            label={{
                              value: "Users",
                              angle: -90,
                              position: "insideLeft",
                              style: { textAnchor: "middle" },
                            }}
                          />
                          <Tooltip />
                          <Legend />
                          <Line
                            type="monotone"
                            dataKey="totalUsers"
                            stroke="#8884d8"
                            name="Total Users"
                            dot={{ r: 3 }}
                            strokeWidth={2}
                          />
                          <Line
                            type="monotone"
                            dataKey="sessions"
                            stroke="#82ca9d"
                            name="Sessions"
                            dot={{ r: 3 }}
                            strokeWidth={2}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-12 md:col-span-9 lg:col-span-12 space-y-4">
                  <div className="bg-blue-50 p-4 text-sm font-bold text-center text-red-500 italic">
                    Traffic (Total Users and Sessions) taken from MMS ALL
                    Production Property where the dimension "Country Platform"
                    is not "Not Set" or "US".
                  </div>
                </div>
                <div className="sm:col-span-12 md:col-span-9 lg:col-span-6 space-y-4">
                  <div className="bg-white rounded-lg shadow">
                    <div className="p-4 border-b border-gray-200">
                      <h2 className="text-lg font-semibold text-gray-700">
                        {/* {headingTxt} */}
                        GA4 - B2C{" "}
                      </h2>
                    </div>
                    <div className="overflow-x-auto">
                      {isLoading ? (
                        <LoadingBox />
                      ) : (
                        <>
                          <div className="flex items-center gap-2 m-2 ml-4">
                            <span className="text-sm text-gray-600">Show</span>
                            <select
                              value={itemsPerPage}
                              onChange={handleEntriesChange}
                              className="px-2 py-1 border border-gray-300 rounded-md text-sm"
                            >
                              {[10, 25, 50, 100].map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            <span className="text-sm text-gray-600">
                              entries
                            </span>
                          </div>

                          <table className="w-full">
                            <thead className="bg-gray-50">
                              <tr>
                                {ga4TrafficCol.map(({ key, label }, index) => (
                                  <th
                                    key={index}
                                    onClick={() => handleSort(key)}
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                                  >
                                    <div className="flex items-center space-x-1">
                                      <span>{label}</span>
                                      <ArrowUpDown className="h-4 w-4" />
                                    </div>
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {ga4TrafficData?.b2c?.rows?.map(
                                (row: any, index: any) => (
                                  <tr key={index} className="hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                      {`${row?.MARS_YEAR} ${row?.MARS_PERIOD} ${row?.MARS_WEEK}`}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                      {moment(
                                        row.CalendarID,
                                        "YYYYMMDD"
                                      ).format("YYYY MMM DD")}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                      {row.Country}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                      {row.Channel}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                      {row?.totalUsers?.toLocaleString()}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                      {row?.sessions?.toLocaleString()}
                                    </td>
                                  </tr>
                                )
                              )}
                              <tr className="bg-blue-600">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                                  Total
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                                  {ga4TrafficData?.b2c?.totalUsers?.toLocaleString()}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                                  {ga4TrafficData?.b2c?.totalSessions?.toLocaleString()}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      )}
                    </div>
                    <div className="px-6 py-4 border-t border-gray-200">
                      <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                        <div className="text-sm text-gray-500">
                          Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                          {Math.min(
                            (currentPage - 1) * itemsPerPage +
                              ga4TrafficData?.b2c?.rows?.length || 0
                          )}{" "}
                          of {ga4TrafficData?.totalPages * itemsPerPage} entries
                        </div>
                        <div className="flex items-center space-x-2">
                          <button
                            onClick={() =>
                              setCurrentPage((prev) => Math.max(prev - 1, 1))
                            }
                            disabled={currentPage === 1}
                            className="px-3 py-1 text-sm disabled:opacity-50"
                          >
                            Previous
                          </button>
                          {renderPageNumbers()}
                          <button
                            onClick={() =>
                              setCurrentPage((prev) =>
                                Math.min(prev + 1, ga4TrafficData.totalPages)
                              )
                            }
                            disabled={currentPage === ga4TrafficData.totalPages}
                            className="px-3 py-1 text-sm disabled:opacity-50"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-12 md:col-span-9 lg:col-span-6 space-y-4">
                  <div className="bg-white rounded-lg shadow">
                    <div className="p-4 border-b border-gray-200">
                      <h2 className="text-lg font-semibold text-gray-700">
                        {/* {headingTxt} */}
                        GA4 - B2B{" "}
                      </h2>
                    </div>
                    <div className="overflow-x-auto">
                      {props.loading ? (
                        <LoadingBox />
                      ) : (
                        <table className="w-full">
                          <thead className="bg-gray-50">
                            <tr>
                              {ga4TrafficCol.map(({ key, label }, index) => (
                                <th
                                  key={index}
                                  onClick={() => handleSort(key)}
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                                >
                                  <div className="flex items-center space-x-1">
                                    <span>{label}</span>
                                    <ArrowUpDown className="h-4 w-4" />
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {ga4TrafficData?.b2b?.rows?.map(
                              (row: any, index: any) => (
                                <tr key={index} className="hover:bg-gray-50">
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                    {`${row?.MARS_YEAR} ${row?.MARS_PERIOD} ${row?.MARS_WEEK}`}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                    {moment(row.CalendarID, "YYYYMMDD").format(
                                      "YYYY MMM DD"
                                    )}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                    {row.Country}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                    {row.Channel}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                    {row?.totalUsers?.toLocaleString()}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                    {row?.sessions?.toLocaleString()}
                                  </td>
                                </tr>
                              )
                            )}
                            <tr className="bg-blue-600">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                                Total
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                                {ga4TrafficData?.b2b?.totalUsers?.toLocaleString()}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                                {ga4TrafficData?.b2b?.totalSessions?.toLocaleString()}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : props?.subItem === "Demand Orders Conversion" ? (
          <div className="sm:col-span-12 md:col-span-9 lg:col-span-10 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
              <div className="sm:col-span-12 md:col-span-9 lg:col-span-9 space-y-4">
                <div className="bg-white rounded-lg shadow">
                  <div className="overflow-x-auto">
                    {props.loading ? (
                      <LoadingBox />
                    ) : (
                      <table className="w-full">
                        <thead className="bg-gray-50">
                          <tr>
                            {demandOrderCol.map(({ key, label }, index) => (
                              <th
                                key={index}
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                              >
                                <div className="flex items-center space-x-1">
                                  <span>{label}</span>
                                  <ArrowUpDown className="h-4 w-4" />
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {demandOrderData.map((row, index) => (
                            <tr key={index} className="hover:bg-gray-50">
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {row.createdDate}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {row.orderNo}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {row.stage}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {row.invoiceDate}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {row.shippedDate}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {row.demandOrdercount}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {row.labelcount}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {row.invoicecount}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {row.shippedcount}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {row.invoiced}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {row.shipped}
                              </td>
                            </tr>
                          ))}
                          <tr className="bg-blue-600">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                              Total
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              3242
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              4243
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              4242
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              3242
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              59%
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              65%
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
              <div className="sm:col-span-12 md:col-span-9 lg:col-span-3 space-y-4">
                <div className="bg-white rounded-lg shadow">
                  <div className="overflow-x-auto">
                    {props.loading ? (
                      <LoadingBox />
                    ) : (
                      <table className="w-full">
                        <thead className="bg-gray-50">
                          <tr>
                            {demandOrderCol2.map(({ key, label }, index) => (
                              <th
                                key={index}
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                              >
                                <div className="flex items-center space-x-1">
                                  <span>{label}</span>
                                  <ArrowUpDown className="h-4 w-4" />
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {demandOrderData2.map((row, index) => (
                            <tr key={index} className="hover:bg-gray-50">
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {row.period}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {row.invoiced}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {row.shipped}
                              </td>
                            </tr>
                          ))}
                          <tr className="bg-blue-600">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                              Total
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              90%
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                              84%
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {/* </div> */}
      </div>
    </div>
  );
};

export default DaliyDemand;
