import { ArrowUpDown } from "lucide-react";
import { useEffect, useState } from "react";
import API from "../../../config/api";
import { POST } from "../../../utils/apiCalls";
import Pagination from "./pagination";
import LoadingBox from "../../../components/LoadingBox";

const DailySales = (props: any) => {
  console.log("props .....", props);
  const [isLoading, setIsLoading] = useState(false);
  const [isPeriodViewLoading, setIsPeriodViewLoading] = useState(false);
  const [isDailySalesLoading, setIsDailySalesLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const [dailySalesData, setDailySalesData] = useState<any>({});
  const [periodData, setPeriodData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [dailySalesCurrentPage, setDailySalesCurrentPage] = useState(1);
  const [periodCurrentPage, setPeriodCurrentPage] = useState(1);

  // Separate sort states for each view
  const [weeklySortField, setWeeklySortField] = useState<any>("CREATEDDATE");
  const [weeklySortOrder, setWeeklySortOrder] = useState<"ASC" | "DESC">(
    "DESC"
  );

  const [periodSortField, setPeriodSortField] = useState<any>("CREATEDDATE");
  const [periodSortOrder, setPeriodSortOrder] = useState<"ASC" | "DESC">(
    "DESC"
  );

  const [dailySalesSortField, setDailySalesSortField] =
    useState<any>("CREATEDDATE");
  const [dailySalesSortOrder, setDailySalesSortOrder] = useState<
    "ASC" | "DESC"
  >("DESC");

  const weeklyView = [
    { key: "MARS_INVOICED_PERIOD", label: "Mars Year/Period" },
    { key: "MARS_INVOICED_WEEK", label: "Mars Week" },
    { key: "NSV", label: "NSV" },
    { key: "NSV_LY", label: "NSV LY" },
  ];

  const periodView = [
    { key: "MARS_YEAR_PERIOD", label: "Mars Year/Period" },
    { key: "NSV", label: "Invoiced NSV" },
    { key: "NSV_LY", label: "Invoiced NSV LY" },
  ];

  const dailySalesView = [
    { key: "MARS_INVOICED_YEAR", label: "Mars Year/Period" },
    { key: "INVOICEDATE", label: "Date" },
    { key: "MARS_WEEK", label: "Country" },
    { key: "ORDERED_DATE", label: "Channel" },
    {
      key: "TRANSACTIONS_GA4",
      label: "Number of Orders Invoiced",
    },
    {
      key: "TRANSACTIONS_GA4_LY",
      label: "Total (tax free)",
    },
    { key: "Tax Amount", label: "GA4 Demand NSV" },
    {
      key: "GA4_DEMAND_NSV_LY",
      label: "Total(Taxes Included)",
    },
    { key: "NO_OF_ORDERS_OMS", label: "Fees" },
    {
      key: "NO_OF_ORDERS_OMS_LY",
      label: "Total Products + ship (Taxes Included)",
    },
    {
      key: "NO_OF_ORDERS_VS_LY",
      label: "Total Products + ship (Free Taxes/Not faces)",
    },
    { key: "DEMAND_NSV_OMS", label: "Ship Amount (Free Taxes)" },
    { key: "DEMAND_NSV_OMS_LY", label: "Total (tax tree) LY" },
    { key: "DEMAND_NSV_VS_LY", label: "Tax Amount LY" },
    { key: "DEMAND_AOV_OMS", label: "Total (tax included) LY" },
    { key: "DEMAND_AOV_OMS_LY", label: "Fees LY" },
    {
      key: "DEMAND_AOV_VS_LY",
      label: "Total Products + ship (Taxes Included) LY",
    },
    {
      key: "TOTAL_USERS_GA4",
      label: "Total Products + ship (Frre Taxes / Not Faces) LY",
    },
    {
      key: "TOTAL_USERS_GA4_LY",
      label: "Total Products + ship (Frre Taxes / Faces) LY",
    },
    {
      key: "CVR_GA4_SESSIONS",
      label: "Ship Amount (Frre Taxes) LY",
    },
  ];

  useEffect(() => {
    fetchSales({
      ...props.filters,
      sort: weeklyView.some((item: any) => item.key === weeklySortField)
        ? weeklySortField
        : null,
      order: weeklyView.some((item: any) => item.key === weeklySortField)
        ? weeklySortOrder
        : null,
    });
  }, [currentPage, props.filters, weeklySortField, weeklySortOrder]);

  useEffect(() => {
    fetchDailySalesView({
      ...props.filters,
      sort: dailySalesView.some((item: any) => item.key === dailySalesSortField)
        ? dailySalesSortField
        : null,
      order: dailySalesView.some(
        (item: any) => item.key === dailySalesSortField
      )
        ? dailySalesSortOrder
        : null,
    });
  }, [
    dailySalesCurrentPage,
    props.filters,
    dailySalesSortField,
    dailySalesSortOrder,
  ]);

  useEffect(() => {
    fetchPeriodView({
      ...props.filters,
      sort: periodView.some((item: any) => item.key === periodSortField)
        ? periodSortField
        : null,
      order: periodView.some((item: any) => item.key === periodSortField)
        ? periodSortOrder
        : null,
    });
  }, [periodCurrentPage, props.filters, periodSortField, periodSortOrder]);

  const fetchSales = async (filterValues: any = {}) => {
    try {
      setIsLoading(true);
      const response: any = await POST(
        `${API.GET_WEEKLY_VIEW}?page=${currentPage}&take=10`,
        filterValues
      );
      console.log("responseresponseresponse", response);
      if (response) {
        setData(response);
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDailySalesView = async (filterValues: any = {}) => {
    try {
      setIsDailySalesLoading(true);
      const response: any = await POST(
        `${API.GET_DAILY_SALES_VIEW}?page=${dailySalesCurrentPage}&take=10`,
        filterValues
      );
      console.log("responseresponseresponse-->>>", response);
      if (response) {
        setDailySalesData(response);
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
    } finally {
      setIsDailySalesLoading(false);
    }
  };

  const fetchPeriodView = async (filterValues: any = {}) => {
    try {
      setIsPeriodViewLoading(true);
      const response: any = await POST(
        `${API.GET_PERIOD_VIEW}?page=${periodCurrentPage}&take=13`,
        filterValues
      );
      if (response) {
        setPeriodData(response);
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
    } finally {
      setIsPeriodViewLoading(false);
    }
  };

  const formatCurrency = (value: any) => {
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const TableHeader = ({ title }: any) => (
    <div className="p-4 border-b border-gray-200">
      <h2 className="text-lg font-semibold text-gray-700">{title}</h2>
    </div>
  );

  // Separate sort handlers for each view
  const handleWeeklySort = (column: string) => {
    if (weeklySortField === column) {
      setWeeklySortOrder(weeklySortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setWeeklySortField(column);
      setWeeklySortOrder("ASC");
    }
  };

  const handlePeriodSort = (column: string) => {
    if (periodSortField === column) {
      setPeriodSortOrder(periodSortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setPeriodSortField(column);
      setPeriodSortOrder("ASC");
    }
  };

  const handleDailySalesSort = (column: string) => {
    if (dailySalesSortField === column) {
      setDailySalesSortOrder(dailySalesSortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setDailySalesSortField(column);
      setDailySalesSortOrder("ASC");
    }
  };

  console.log("periodDataperiodData", periodData);

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
        {/* Daily Sales View */}
        <div className="sm:col-span-12 md:col-span-7">
          <div className="bg-white rounded-lg shadow">
            <TableHeader title="Weekly View" />
            <div className="overflow-x-auto">
              {isLoading ? (
                <LoadingBox />
              ) : (
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr className="whitespace-nowrap">
                      {weeklyView.map((item: any, index: number) => (
                        <th
                          key={index}
                          onClick={() => handleWeeklySort(item.key)}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <div className="flex items-center space-x-1">
                            <span>{item.label}</span>
                            <ArrowUpDown className="h-4 w-4" />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {data?.rows?.map((row: any, index: number) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.MARS_YEAR_PERIOD}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.MARS_WEEK}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(Number(row.NSV))}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(Number(row.NSV_LY))}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td
                        colSpan={2}
                        className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white"
                      >
                        Total
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(Number(data?.total?.NSV))}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(Number(data?.total?.NSV_LY))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={Number(data?.currentPage) || 0}
              totalPages={Number(data?.totalPages) || 0}
              totalRows={Number(data?.totalRows) || 0}
              setCurrentPage={setCurrentPage}
              itemsPerPage={Number(data?.take) || 0}
            />
          </div>
        </div>

        {/* Period View */}
        <div className="sm:col-span-12 md:col-span-5">
          <div className="bg-white rounded-lg shadow">
            <TableHeader title="Period View" />
            <div className="overflow-x-auto">
              {isPeriodViewLoading ? (
                <LoadingBox />
              ) : (
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50 whitespace-nowrap">
                      {periodView.map((item: any, index: number) => (
                        <th
                          key={index}
                          onClick={() => handlePeriodSort(item.key)}
                          className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <div className="flex items-center gap-1">
                            {item.label} <ArrowUpDown className="h-4 w-4" />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {periodData?.rows?.map((row: any, index: any) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-50 border-t border-gray-200"
                      >
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {row.MARS_YEAR_PERIOD}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(Number(row.NSV))}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(Number(row.NSV_LY))}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td className="px-4 py-3 text-sm font-bold text-white">
                        Total
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(Number(periodData?.total?.NSV))}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(Number(periodData?.total?.NSV_LY))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={Number(periodData?.currentPage) || 0}
              totalPages={Number(periodData?.totalPages) || 0}
              totalRows={Number(periodData?.totalRows) || 0}
              setCurrentPage={setPeriodCurrentPage}
              itemsPerPage={Number(periodData?.take) || 0}
            />
          </div>
        </div>

        {/* <<<<<<<<<<<<<<-------------------------- DAILY SALES VIEW ---------------------->>>>>>>>>>>>>> */}

        <div className="sm:col-span-12 md:col-span-12 lg:col-span-12 space-y-4">
          <div className="bg-white rounded-lg shadow">
            <TableHeader title="Daily Sales View (Incl. Financial columns)" />
            <div className="overflow-x-auto">
              {isDailySalesLoading ? (
                <LoadingBox />
              ) : (
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50">
                      {dailySalesView?.map((item: any, index: number) => (
                        <th
                          onClick={() => handleDailySalesSort(item?.key)}
                          className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <div className="flex items-center gap-1">
                            {item?.label} <ArrowUpDown className="h-4 w-4" />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {dailySalesData?.rows?.map((row: any, index: any) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-50 border-t border-gray-200"
                      >
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {row.MARS_YEAR_PERIOD}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                          {row.DATE}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {row.COUNTRY}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {row.CHANNEL}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {row.NUMBER_OF_ORDERS_INVOICED}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(Number(row.TOTAL_TAX_FREE))}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(Number(row.TAX_AMOUNT))}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(Number(row.TOTAL_TAX_INCLUDED))}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(Number(row.FEES))}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(
                            Number(row.TOTAL_PRODUCTS_SHIP_INCLUDED)
                          )}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(
                            Number(row.TOTAL_PRODUCTS_SHIP_FREE_TAX_NOT_FACES)
                          )}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(Number(row.SHIP_AMOUNT_FREE_TAX))}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(Number(row.TOTAL_TAX_FREE_LY))}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(Number(row.TAX_AMOUNT_LY))}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(Number(row.TOTAL_TAX_INCLUDED_LY))}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(Number(row.FEES_LY))}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(
                            Number(row.TOTAL_PRODUCTS_SHIP_INCLUDED_LY)
                          )}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(
                            Number(
                              row.TOTAL_PRODUCTS_SHIP_FREE_TAX_NOT_FACES_LY
                            )
                          )}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(
                            Number(row.TOTAL_PRODUCTS_SHIP_FREE_TAX_FACES_LY)
                          )}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(Number(row.SHIP_AMOUNT_FREE_TAX_LY))}
                        </td>
                      </tr>
                    ))}
                    {/* <<<<<<<<<<<<<<-------------------------- DAILY SALES VIEW TOTAL ---------------------->>>>>>>>>>>>>> */}

                    <tr className="bg-blue-600">
                      <td
                        colSpan={4}
                        className="px-4 py-3 text-sm font-bold text-white"
                      >
                        Total
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {dailySalesData?.data?.total?.NUMBER_OF_ORDERS_INVOICED}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          Number(dailySalesData?.total?.TOTAL_TAX_FREE)
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          Number(dailySalesData?.total?.TAX_AMOUNT)
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          Number(dailySalesData?.total?.TOTAL_TAX_INCLUDED)
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(Number(dailySalesData?.total?.FEES))}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          Number(
                            dailySalesData?.total?.TOTAL_PRODUCTS_SHIP_INCLUDED
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          Number(
                            dailySalesData?.total
                              ?.TOTAL_PRODUCTS_SHIP_FREE_TAX_NOT_FACES
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          Number(dailySalesData?.total?.SHIP_AMOUNT_FREE_TAX)
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          Number(dailySalesData?.total?.TOTAL_TAX_FREE_LY)
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          Number(dailySalesData?.total?.TAX_AMOUNT_LY)
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          Number(dailySalesData?.total?.TOTAL_TAX_INCLUDED_LY)
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(Number(dailySalesData?.total?.FEES_LY))}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          Number(
                            dailySalesData?.total
                              ?.TOTAL_PRODUCTS_SHIP_INCLUDED_LY
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          Number(
                            dailySalesData?.total
                              ?.TOTAL_PRODUCTS_SHIP_FREE_TAX_NOT_FACES_LY
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          Number(
                            dailySalesData?.total
                              ?.TOTAL_PRODUCTS_SHIP_FREE_TAX_FACES_LY
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          Number(dailySalesData?.total?.SHIP_AMOUNT_FREE_TAX_LY)
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <Pagination
              currentPage={Number(dailySalesData?.currentPage) || 0}
              totalPages={Number(dailySalesData?.totalPages) || 0}
              totalRows={Number(dailySalesData?.totalRows) || 0}
              setCurrentPage={setDailySalesCurrentPage}
              itemsPerPage={Number(dailySalesData?.take) || 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailySales;
