import React from "react";

interface PaginationProps {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  totalRows: number;
  itemsPerPage: number;
}
const Pagination = ({
  currentPage,
  setCurrentPage,
  totalPages,
  totalRows,
  itemsPerPage,
}: PaginationProps) => {
  const renderPageNumbers = () => {
    const pages = [];
    const showAround = 1;
    pages.push(1);
    if (currentPage > 2 + showAround) pages.push("...");
    for (
      let i = Math.max(2, currentPage - showAround);
      i <= Math.min(totalPages - 1, currentPage + showAround);
      i++
    ) {
      pages.push(i);
    }
    if (currentPage < totalPages - (1 + showAround)) pages.push("...");
    if (totalPages > 1) pages.push(totalPages);
    return (
      <div className="flex items-center space-x-1">
        {pages.map((page, index) => (
          <React.Fragment key={index}>
            {page === "..." ? (
              <span className="px-2 py-1 text-gray-500">...</span>
            ) : (
              <button
                onClick={() => setCurrentPage(page as number)}
                className={`min-w-[32px] px-2 py-1 rounded text-sm ${
                  currentPage === page
                    ? "bg-gray-600 text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                {page}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };
  return (
    <div className="px-6 py-4 border-t border-gray-200">
      <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="text-sm text-gray-500">
          Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
          {Math.min(currentPage * itemsPerPage, totalRows)} of {totalRows}{" "}
          entries
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-3 py-1 text-sm disabled:opacity-50"
          >
            Previous
          </button>
          {renderPageNumbers()}
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className="px-3 py-1 text-sm disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
export default Pagination;