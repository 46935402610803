import React, { useState } from "react";

const B2b = (props: any) => {
  console.log("props .......", props);
  const [isLoading, setIsLoading] = useState(false);

  const columns1 = [
    { key: "year", label: "Mars_YearPeriod" },
    { key: "id", label: "Product ID" },
    { key: "name", label: "Product Name" },
    { key: "category", label: "Category" },
    { key: "flavour", label: "Flavour" },
    { key: "occassion", label: "Occassion" },
    { key: "invoiced_nsv", label: "Invoiced NSV" },
    { key: "qty", label: "Quantity" },
    { key: "hit", label: "Hit" },
    { key: "country", label: "Country" },
  ];
  const columns2 = [
    { key: "name", label: "Name" },
    { key: "noOrders", label: "nbr of Orders" },
    { key: "nounits", label: "nbr of Units" },
    { key: "weight", label: "Weight(KG)" },
    { key: "nsv_invoiced", label: "NSV Invoiced" },
  ];
  const dayPerDayCol = [
    { key: "year", label: "Mars_Year" },
    { key: "actual", label: "Actuals" },
    { key: "invoiced_ly", label: "Invoiced LY" },
    { key: "vs_ly_abs", label: "vs LY(abs)" },
    { key: "vs_ly", label: "vs LY" },
    { key: "nbr_orders", label: "Nbr of Orders" },
    { key: "nbr_orders_ly", label: "Nbr of Orders LY" },
    { key: "order_vs_ly", label: "Orders vs LY" },
    { key: "invoiced_aov", label: "Invoiced AOV €" },
    { key: "aov_ly", label: "AOV € LY" },
    { key: "aov_vs_ly", label: "AOV € vs LY" },
  ];
  const kpiCol = [
    { key: "country", label: "Country" },
    { key: "actual", label: "Actuals" },
    { key: "invoiced_ly", label: "Invoiced LY" },
    { key: "vs_ly_abs", label: "vs LY(abs)" },
    { key: "vs_ly", label: "vs LY" },
    { key: "nbr_orders", label: "Nbr of Orders" },
    { key: "nbr_orders_ly", label: "Nbr of Orders LY" },
    { key: "order_vs_ly", label: "Orders vs LY" },
    { key: "invoiced_aov", label: "Invoiced AOV €" },
    { key: "aov_ly", label: "AOV € LY" },
    { key: "aov_vs_ly", label: "AOV € vs LY" },
  ];
  const kpiCol2 = [
    { key: "channel", label: "Sales Channel" },
    { key: "actual", label: "Actuals" },
    { key: "invoiced_ly", label: "Invoiced LY" },
    { key: "vs_ly_abs", label: "vs LY(abs)" },
    { key: "vs_ly", label: "vs LY" },
    { key: "nbr_orders", label: "Nbr of Orders" },
    { key: "nbr_orders_ly", label: "Nbr of Orders LY" },
    { key: "order_vs_ly", label: "Orders vs LY" },
    { key: "invoiced_aov", label: "Invoiced AOV €" },
    { key: "aov_ly", label: "AOV € LY" },
    { key: "aov_vs_ly", label: "AOV € vs LY" },
  ];
  const taktimCol1 = [
    { key: "year", label: "Year" },
    { key: "period", label: "Period" },
    { key: "week", label: "Week" },
    { key: "salesChannel2", label: "Sales Channel 2" },
    { key: "orderNo", label: "Order Number" },
    { key: "country", label: "Country" },
    { key: "totalOrders", label: "Total Orders" },
    { key: "salesNsv", label: "Sales NSV" },
    { key: "email", label: "Customer Email" },
    { key: "channel", label: "Channel" },
    { key: "createdDate", label: "Created Date" },
    { key: "invoiceDate", label: "Invoice Date" },
  ];
  const taktimCol2 = [
    { key: "year", label: "Year" },
    { key: "period", label: "Period" },
    { key: "week", label: "Week" },
    { key: "orderNo", label: "Order Number" },
    { key: "country", label: "Country" },
    { key: "totalOrders", label: "Total Orders" },
    { key: "salesNsv", label: "Sales NSV" },
    { key: "email", label: "Customer Email" },
    { key: "channel", label: "Channel" },
    { key: "createdDate", label: "Created Date" },
    { key: "invoiceDate", label: "Invoice Date" },
  ];
  const taktimCol3 = [
    { key: "distributor", label: "Distributor" },
    { key: "orderNo", label: "Order Number" },
    { key: "createdDate", label: "Created Date" },
    { key: "invoiceDate", label: "Invoice Date" },
    { key: "orderCountry", label: "Order Country" },
    { key: "billingCountry", label: "Billing Country" },
    { key: "shippingCountry", label: "Shipping Country" },
    { key: "proId", label: "Product ID" },
    { key: "name", label: "Product Name" },
    { key: "qty", label: "Product Quantity" },
    { key: "nsv", label: "Product NSV" },
    { key: "amt", label: "Product Amount" },
  ];
  const shippedCol = [
    { key: "orderNo", label: "Order Number" },
    { key: "createdDate", label: "Created Date" },
    { key: "invoiceDate", label: "Invoice Date" },
    { key: "shippedDate", label: "Shipped Date" },
    { key: "channel", label: "Channel" },
    { key: "salesChannel", label: "Sales Channel" },
    { key: "salesChannel2", label: "Sales Channel2" },
    { key: "NsvNotInvoicedYet", label: "NsvNotInvoicedYet" },
  ];

  const dummyData1 = [
    {
      year: "2024 P13",
      id: 987,
      name: "Bulk Bag",
      category: "1.5kg Bulk",
      flavour: "CHOCOLATE",
      occassion: "Birth",
      invoiced_nsv: 182,
      qty: 3,
      hit: 1,
      country: "BE",
    },
    {
      year: "2024 P13",
      id: 987,
      name: "Bulk Bag",
      category: "1.5kg Bulk",
      flavour: "CHOCOLATE",
      occassion: "Birth",
      invoiced_nsv: 182,
      qty: 3,
      hit: 1,
      country: "BE",
    },
  ];
  const dummyData2 = [
    {
      name: "Customisable Paper bag",
      noOrders: 1234,
      nounits: 345,
      weight: 5647,
      nsv_invoiced: 5647,
    },
    {
      name: "Customisable Paper bag",
      noOrders: 1234,
      nounits: 345,
      weight: 5647,
      nsv_invoiced: 5647,
    },
  ];
  const dayPerDayData = [
    {
      year: 2024,
      actual: 1234,
      invoiced_ly: 345,
      vs_ly_abs: 5647,
      vs_ly: "56%",
      nbr_orders: 5647,
      nbr_orders_ly: 5647,
      order_vs_ly: "5%",
      invoiced_aov: 5647,
      aov_ly: 5647,
      aov_vs_ly: 5647,
    },
    {
      year: 2024,
      actual: 1234,
      invoiced_ly: 345,
      vs_ly_abs: 5647,
      vs_ly: "56%",
      nbr_orders: 5647,
      nbr_orders_ly: 5647,
      order_vs_ly: "5%",
      invoiced_aov: 5647,
      aov_ly: 5647,
      aov_vs_ly: 5647,
    },
    {
      year: 2024,
      actual: 1234,
      invoiced_ly: 345,
      vs_ly_abs: 5647,
      vs_ly: "56%",
      nbr_orders: 5647,
      nbr_orders_ly: 5647,
      order_vs_ly: "5%",
      invoiced_aov: 5647,
      aov_ly: 5647,
      aov_vs_ly: 5647,
    },
    {
      year: 2024,
      actual: 1234,
      invoiced_ly: 345,
      vs_ly_abs: 5647,
      vs_ly: "56%",
      nbr_orders: 5647,
      nbr_orders_ly: 5647,
      order_vs_ly: "5%",
      invoiced_aov: 5647,
      aov_ly: 5647,
      aov_vs_ly: 5647,
    },
  ];
  const kpiData = [
    {
      country: 2024,
      actual: 1234,
      invoiced_ly: 345,
      vs_ly_abs: 5647,
      vs_ly: "56%",
      nbr_orders: 5647,
      nbr_orders_ly: 5647,
      order_vs_ly: "5%",
      invoiced_aov: 5647,
      aov_ly: 5647,
      aov_vs_ly: 5647,
    },
    {
      country: 2024,
      actual: 1234,
      invoiced_ly: 345,
      vs_ly_abs: 5647,
      vs_ly: "56%",
      nbr_orders: 5647,
      nbr_orders_ly: 5647,
      order_vs_ly: "5%",
      invoiced_aov: 5647,
      aov_ly: 5647,
      aov_vs_ly: 5647,
    },
  ];
  const kpiData2 = [
    {
      channel: "WEBSITE",
      actual: 1234,
      invoiced_ly: 345,
      vs_ly_abs: 5647,
      vs_ly: "56%",
      nbr_orders: 5647,
      nbr_orders_ly: 5647,
      order_vs_ly: "5%",
      invoiced_aov: 5647,
      aov_ly: 5647,
      aov_vs_ly: 5647,
    },
    {
      channel: "TAKTIM",
      actual: 1234,
      invoiced_ly: 345,
      vs_ly_abs: 5647,
      vs_ly: "56%",
      nbr_orders: 5647,
      nbr_orders_ly: 5647,
      order_vs_ly: "5%",
      invoiced_aov: 5647,
      aov_ly: 5647,
      aov_vs_ly: 5647,
    },
  ];
  const taktimDta1 = [
    {
      year: 2024,
      period: "P1",
      week: "W2",
      salesChannel2: "KSW",
      orderNo: 3335647,
      country: "FR",
      totalOrders: 1,
      salesNsv: 87654,
      email: "test@gmail.com",
      channel: "B2B",
      createdDate: "11 Dec 2023",
      invoiceDate: "2 Jan 2024",
    },
    {
      year: 2024,
      period: "P1",
      week: "W2",
      salesChannel2: "KSW",
      orderNo: 3335647,
      country: "FR",
      totalOrders: 1,
      salesNsv: 87654,
      email: "test@gmail.com",
      channel: "B2B",
      createdDate: "11 Dec 2023",
      invoiceDate: "2 Jan 2024",
    },
  ];
  const taktimData2 = [
    {
      year: 2024,
      period: "P1",
      week: "W2",
      orderNo: 3335647,
      country: "FR",
      totalOrders: 1,
      salesNsv: 87654,
      email: "test@gmail.com",
      channel: "B2B",
      createdDate: "11 Dec 2023",
      invoiceDate: "2 Jan 2024",
    },
    {
      year: 2024,
      period: "P1",
      week: "W2",
      orderNo: 3335647,
      country: "FR",
      totalOrders: 1,
      salesNsv: 87654,
      email: "test@gmail.com",
      channel: "B2B",
      createdDate: "11 Dec 2023",
      invoiceDate: "2 Jan 2024",
    },
  ];
  const taktimData3 = [
    {
      distributor: "KSW",
      orderNo: 1234,
      createdDate: 345,
      invoiceDate: 5647,
      orderCountry: 5647,
      billingCountry: 5647,
      shippingCountry: 5647,
      proId: 5647,
      name: 5647,
      qty: 5647,
      nsv: "€5647",
      amt: "€5647",
    },
    {
      distributor: "KSW",
      orderNo: 1234,
      createdDate: 345,
      invoiceDate: 5647,
      orderCountry: 5647,
      billingCountry: 5647,
      shippingCountry: 5647,
      proId: 5647,
      name: 5647,
      qty: 5647,
      nsv: "€5647",
      amt: "€5647",
    },
  ];
  const shippedData = [
    {
      orderNo: 1234,
      createdDate: "12 Dec 2024",
      invoiceDate: "",
      shippedDate: "17 Dec 2024",
      channel: "B2C",
      salesChannel: "WEBSITE",
      salesChannel2: "WEBSITE",
      NsvNotInvoicedYet: 5647,
    },
    {
      orderNo: 1234,
      createdDate: "12 Dec 2024",
      invoiceDate: "",
      shippedDate: "17 Dec 2024",
      channel: "B2C",
      salesChannel: "WEBSITE",
      salesChannel2: "WEBSITE",
      NsvNotInvoicedYet: 5647,
    },
  ];

  const totalRow1: any = {
    invoiced_nsv: 43219,
    qty: 5629,
    hit: 2629,
  };
  const totalRow2: any = {
    noOrders: 12234,
    nounits: 1345,
    weight: 25647,
    nsv_invoiced: 25647,
  };
  const dayPerDayTot: any = {
    actual: 1234,
    invoiced_ly: 345,
    vs_ly_abs: 5647,
    vs_ly: "56%",
    nbr_orders: 5647,
    nbr_orders_ly: 5647,
    order_vs_ly: "5%",
    invoiced_aov: 5647,
    aov_ly: 5647,
    aov_vs_ly: 5647,
  };
  const kpiTot: any = {
    actual: 1234,
    invoiced_ly: 345,
    vs_ly_abs: 5647,
    vs_ly: "56%",
    nbr_orders: 5647,
    nbr_orders_ly: 5647,
    order_vs_ly: "5%",
    invoiced_aov: 5647,
    aov_ly: 5647,
    aov_vs_ly: 5647,
  };
  const totTaktim1: any = {
    totalOrders: 219,
    salesNsv: 35629,
  };
  const totTaktim3: any = {
    amt: "675678",
  };
  const formatCurrency = (value: any) => {
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };
  return (
    <div>
      {props?.subItem === "B2B - Product Split" ? (
        <>
          <div className="bg-white rounded-lg shadow">
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold text-[#6b2228]">
                B2B - Products Split Analysis
              </h2>
            </div>
            <div className="overflow-x-auto">
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      {columns1.map(({ key, label }) => (
                        <th
                          key={key}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer"
                        >
                          <div className="flex items-center space-x-1">
                            <span>{label}</span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {dummyData1.map((row, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.year}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.category}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.flavour}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.occassion}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.invoiced_nsv}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.qty}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.hit}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.country}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                        Total
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {totalRow1.invoiced_nsv}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {totalRow1.qty}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {totalRow1.hit}
                      </td>{" "}
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <div className="bg-white rounded-lg shadow mt-5">
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold text-[#6b2228]">
                Copacked Product Analysis{" "}
              </h2>
            </div>
            <div className="overflow-x-auto">
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      {columns2.map(({ key, label }) => (
                        <th
                          key={key}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer"
                        >
                          <div className="flex items-center space-x-1">
                            <span>{label}</span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {dummyData2.map((row, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.noOrders}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.nounits}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.weight}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.nsv_invoiced}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                        Total
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {totalRow2.noOrders}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {totalRow2.nounits}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {totalRow2.weight}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {totalRow2.nsv_invoiced}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </>
      ) : props?.subItem === "B2B - Day per Day" ? (
        <div className="bg-white rounded-lg shadow mt-5">
          <div className="overflow-x-auto">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {dayPerDayCol.map(({ key, label }) => (
                      <th
                        key={key}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{label}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {dayPerDayData.map((row, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.year}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.actual)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.invoiced_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.vs_ly_abs)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.vs_ly}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.nbr_orders)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.nbr_orders_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.order_vs_ly}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.invoiced_aov)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.aov_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.aov_ly)}
                      </td>
                    </tr>
                  ))}
                  <tr className="bg-blue-600">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                      Total
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(dayPerDayTot.actual)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(dayPerDayTot.invoiced_ly)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(dayPerDayTot.vs_ly_abs)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {dayPerDayTot.vs_ly}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(dayPerDayTot.nbr_orders)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(dayPerDayTot.nbr_orders_ly)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {dayPerDayTot.order_vs_ly}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(dayPerDayTot.invoiced_aov)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(dayPerDayTot.aov_ly)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(dayPerDayTot.aov_vs_ly)}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      ) : props?.subItem === "B2B - KPI" ? (
        <>
          <div className="bg-white rounded-lg shadow">
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold text-[#6b2228]">
                Sales Performane by Country (B2B - Invoiced)
              </h2>
            </div>
            <div className="overflow-x-auto">
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      {kpiCol.map(({ key, label }) => (
                        <th
                          key={key}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer"
                        >
                          <div className="flex items-center space-x-1">
                            <span>{label}</span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {kpiData.map((row, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.country}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.actual)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.invoiced_ly)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.vs_ly_abs)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.vs_ly}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.nbr_orders)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.nbr_orders_ly)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.order_vs_ly}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.invoiced_aov)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.aov_ly)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.aov_ly)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                        Total
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.actual)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.invoiced_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.vs_ly_abs)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {kpiTot.vs_ly}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.nbr_orders)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.nbr_orders_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {kpiTot.order_vs_ly}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.invoiced_aov)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.aov_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.aov_vs_ly)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <div className="bg-white rounded-lg shadow mt-5">
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold text-[#6b2228]">
                Sales Performane by Sales Channel (B2B - Invoiced)
              </h2>
            </div>
            <div className="overflow-x-auto">
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      {kpiCol2.map(({ key, label }) => (
                        <th
                          key={key}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer"
                        >
                          <div className="flex items-center space-x-1">
                            <span>{label}</span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {kpiData2.map((row, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.channel}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.actual)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.invoiced_ly)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.vs_ly_abs)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.vs_ly}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.nbr_orders)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.nbr_orders_ly)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.order_vs_ly}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.invoiced_aov)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.aov_ly)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.aov_ly)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                        Total
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.actual)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.invoiced_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.vs_ly_abs)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {kpiTot.vs_ly}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.nbr_orders)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.nbr_orders_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {kpiTot.order_vs_ly}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.invoiced_aov)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.aov_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(kpiTot.aov_vs_ly)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </>
      ) : props?.subItem === "B2B | Taktim & Other Distributors" ? (
        <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
          <div className="sm:col-span-12 md:col-span-6">
            <div className="bg-blue-50 p-4 mt-3 text-sm text-gray-600 italic text-center h-full">
              <p>
                <span className="font-normal">Information:</span> Report listing
                all <span className="font-bold italic">Invoiced Orders</span> on
                a specific date range created by{" "}
                <span className="font-semibold italic">DISTRIBUTORS</span>{" "}
                <span className="italic">(excluding TAKTIM)</span>.
              </p>
              <p className="font-bold text-blue-800 mt-2">
                SALES NSV (CONSIDER CREDITS NOTES RELATED TO THE ORDERS)
              </p>
              <p className="italic mt-1">
                This report has a specific purpose related to the performance of
                the Agents
              </p>
            </div>
          </div>

          {/* Right Box */}
          <div className="sm:col-span-12 md:col-span-6">
            <div className="bg-blue-50 p-4 mt-3 text-sm text-gray-600 italic text-center h-full">
              <p>
                <span className="font-normal">Information:</span> Report listing
                all <span className="font-bold italic">Invoiced Orders</span> on
                a specific date range created by{" "}
                <span className="font-semibold italic">TAKTIM</span>.
              </p>
              <p className="font-bold text-blue-800 mt-2">
                SALES NSV (CONSIDER CREDITS NOTES RELATED TO THE ORDERS)
              </p>
              <p className="italic mt-1">
                This report has a specific purpose related to the performance of
                the Agents
              </p>
            </div>
          </div>
          <div className="sm:col-span-12 md:col-span-6">
            <div className="bg-white p-4 rounded-lg shadow">
              <div className="bg-white rounded-lg shadow mt-5">
                <div className="p-4 border-b border-gray-200">
                  <h2 className="text-lg font-semibold text-[#6b2228]">
                    Distributors excl Taktim (Sales NSV including Credit Notes)
                  </h2>
                </div>
                <div className="overflow-x-auto">
                  {isLoading ? (
                    <div>Loading...</div>
                  ) : (
                    <table className="w-full">
                      <thead className="bg-gray-50">
                        <tr>
                          {taktimCol1.map(({ key, label }) => (
                            <th
                              key={key}
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer"
                            >
                              <div className="flex items-center space-x-1">
                                <span>{label}</span>
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {taktimDta1.map((row, index) => (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.year}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.period}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.week}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.salesChannel2}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.orderNo}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.country}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.totalOrders}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {formatCurrency(row.salesNsv)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.email}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.channel}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.createdDate}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.invoiceDate}
                            </td>
                          </tr>
                        ))}
                        <tr className="bg-blue-600">
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                            Total
                          </td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {totTaktim1.totalOrders}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {formatCurrency(totTaktim1.salesNsv)}
                          </td>
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="sm:col-span-12 md:col-span-6">
            <div className="bg-white p-4 rounded-lg shadow">
              <div className="bg-white rounded-lg shadow mt-5">
                <div className="p-4 border-b border-gray-200">
                  <h2 className="text-lg font-semibold text-[#6b2228]">
                    Taktim (Sales NSV including Credit Notes)
                  </h2>
                </div>
                <div className="overflow-x-auto">
                  {isLoading ? (
                    <div>Loading...</div>
                  ) : (
                    <table className="w-full">
                      <thead className="bg-gray-50">
                        <tr>
                          {taktimCol2.map(({ key, label }) => (
                            <th
                              key={key}
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer"
                            >
                              <div className="flex items-center space-x-1">
                                <span>{label}</span>
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {taktimData2.map((row, index) => (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.year}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.period}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.week}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.orderNo}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.country}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.totalOrders}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {formatCurrency(row.salesNsv)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.email}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.channel}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.createdDate}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.invoiceDate}
                            </td>
                          </tr>
                        ))}
                        <tr className="bg-blue-600">
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                            Total
                          </td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {totTaktim1.totalOrders}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {formatCurrency(totTaktim1.salesNsv)}
                          </td>
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="sm:col-span-12 md:col-span-12">
            <div className="bg-white p-4 rounded-lg shadow">
              <div className="bg-white rounded-lg shadow mt-5">
                <div className="p-4 border-b border-gray-200">
                  <h2 className="text-lg font-semibold text-[#6b2228]">
                    Distributors Report (Requires further validation & check)
                  </h2>
                </div>
                <div className="overflow-x-auto">
                  {isLoading ? (
                    <div>Loading...</div>
                  ) : (
                    <table className="w-full">
                      <thead className="bg-gray-50">
                        <tr>
                          {taktimCol3.map(({ key, label }) => (
                            <th
                              key={key}
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer"
                            >
                              <div className="flex items-center space-x-1">
                                <span>{label}</span>
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {taktimData3.map((row, index) => (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.distributor}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.orderNo}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.createdDate}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.invoiceDate}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.orderCountry}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.billingCountry}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.shippingCountry}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.proId}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.qty}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.nsv}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                              {row.amt}
                            </td>
                          </tr>
                        ))}
                        <tr className="bg-blue-600">
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                            Total
                          </td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                            {formatCurrency(totTaktim3.amt)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : props?.subItem === "Shipped Not Invoiced" ? (
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="bg-white rounded-lg shadow mt-5">
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold text-[#6b2228]">
                Shipped and Not Invoiced
              </h2>
            </div>
            <div className="overflow-x-auto">
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      {shippedCol.map(({ key, label }) => (
                        <th
                          key={key}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer"
                        >
                          <div className="flex items-center space-x-1">
                            <span>{label}</span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {shippedData.map((row, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.orderNo}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.createdDate}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.invoiceDate}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.shippedDate}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.channel}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.salesChannel}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.salesChannel2}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.NsvNotInvoicedYet}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                        Total
                      </td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(totTaktim3.amt)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default B2b;
