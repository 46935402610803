import React, { useEffect, useState } from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import LoadingBox from "../../../components/LoadingBox";
import { ArrowUpDown } from "lucide-react";
const OccassionSplit = (props: any) => {
  const [isLoadingOccation, setIsLoadingOccation] = useState(false);
  const [isLoadingOccationbyCountry, setIsLoadingOccationbyCountry] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [detailedData, setDetailedData] = useState<any>([]);
  const [occasionsByCountry, setOccasionsByCountry] = useState<any>({});
  const [occasions, setOccasions] = useState<any>({});
  const [sortFieldOccation, setSortFieldOccation] = useState<any>("OCCASION");
  const [sortOrderOccation, setSortOrderOccation] = useState<"ASC" | "DESC">(
    "DESC"
  );
  const [sortFieldOccationbyCountry, setSortFieldOccationbyCountry] =
    useState<any>("COUNTRY");
  const [sortOrderOccationbyCountry, setSortOrderOccationbyCountry] = useState<
    "ASC" | "DESC"
  >("DESC");
  const [sortField, setSortField] = useState<any>("CREATEDDATE");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    fetchOccasionSplit({ ...props?.filters });
  }, [props?.filters]);
  useEffect(() => {
    fetchOccasions({
      ...props?.filters,
      sort: sortFieldOccation,
      order: sortOrderOccation,
    });
  }, [props?.filters, sortFieldOccation, sortOrderOccation]);
  useEffect(() => {
    fetchOccasionsByCountry({
      ...props?.filters,
      sort: sortFieldOccationbyCountry,
      order: sortOrderOccationbyCountry,
    });
  }, [props?.filters, sortFieldOccationbyCountry, sortOrderOccationbyCountry]);
  useEffect(() => {
    fetchDetailedAnalysis({
      ...props?.filters,
      sort: sortField,
      order: sortOrder,
    });
  }, [props?.filters, sortField, sortOrder]);

  const fetchDetailedAnalysis = async (filterValues: any = {}) => {
    try {
      setIsLoading(true);
      const response: any = await POST(
        API.DETAILED_OCCASION_ANALYSIS_INVOICE,
        filterValues
      );
      if (response) {
        setDetailedData(response);
      }
    } catch (error) {
      console.error("Error fetching detailed analysis:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOccasionSplit = async (filterValues: any = {}) => {
    try {
      const response: any = await POST(API.OCCASION_SPLIT_INVOICE, filterValues);
      if (response) {
        // Transform data for pie chart
        const transformedData = response.map((item: any) => ({
          name: item.occasion,
          value: item.percentage,
          percentage: item.percentage,
        }));
        setPieData(transformedData);
      }
    } catch (error) {
      console.error("Error fetching occasion split:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOccasionsByCountry = async (filterValues: any = {}) => {
    try {
      setIsLoadingOccationbyCountry(true);
      const response: any = await POST(API.OCCASIONS_BY_COUNTRY_INVOICE, filterValues);
      if (response) {
        setOccasionsByCountry(response);
      }
    } catch (error) {
      console.error("Error fetching occasions by country:", error);
    } finally {
      setIsLoadingOccationbyCountry(false);
    }
  };
  const fetchOccasions = async (filterValues: any = {}) => {
    try {
      setIsLoadingOccation(true);
      const response: any = await POST(API.OCCASIONS_INVOICE, filterValues);
      if (response) {
        setOccasions(response);
      }
    } catch (error) {
      console.error("Error fetching occasions by country:", error);
    } finally {
      setIsLoadingOccation(false);
    }
  };

  const columns = [
    { key: "OCCASSION", label: "Occassion" },
    { key: "DEMAND_NSV", label: "Invoiced NSV" },
    { key: "DEMAND_NSV_LY", label: "Invoiced NSV LY" },
    { key: "DEMAND_NSV_VS_LY", label: "Invoiced NSV vs LY %" },
  ];

  const columns2 = [
    { key: "OCCASSION", label: "Occassion" },
    { key: "COUNTRY", label: "Country" },
    { key: "DEMAND_NSV", label: "Invoiced NSV" },
    { key: "DEMAND_NSV_LY", label: "Invoiced NSV LY" },
    { key: "DEMAND_NSV_VS_LY", label: "Invoiced NSV vs LY %" },
  ];
  const columns3 = [
    { key: "OCCASSION", label: "Occassion" },
    { key: "DEMAND_NSV", label: "Invoiced NSV" },
    { key: "DEMAND_NSV_LY", label: "Invoiced NSV LY" },
    { key: "DEMAND_NSV_VS_LY", label: "Invoiced NSV vs LY %" },
    { key: "DEMAND_NB_OF_ORDERS", label: "Invoiced Nb of Orders" },
    { key: "DEMAND_NB_OF_ORDERS_LY", label: "Invoiced Nb of Orders LY" },
    { key: "DEMAND_ORDERS_VS_LY", label: "Invoiced Orders vs LY %" },
    { key: "DEMAND_AOV", label: "Invoiced AOV €" },
    { key: "DEMAND_AOV_LY", label: "Invoiced AOV € LY" },
    { key: "DEMAND_AOV_VS_LY", label: "Invoiced AOV vs LY €" },
  ];

  const getPercentageColor = (value: any) => {
    const numValue = parseFloat(value);
    if (numValue > 0) return "bg-green-100 text-green-800";
    if (numValue < 0) return "bg-red-100 text-red-800";
    return "text-gray-600";
  };

  const formatCurrency = (value: any) => {
    if (isNaN(value)) return "-";
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatPercentage = (value: any) => {
    if (isNaN(value)) return "-";
    return `${value?.toFixed(1)}%`;
  };

  const COLORS = [
    "#4A90E2", // Soft blue
    "#FF7676", // Coral
    "#9D7FEA", // Lavender
    "#67B266", // Sage green
    "#ED8936", // Peach
    "#F6E05E", // Soft yellow
    "#805AD5", // Purple
    "#4FD1C5", // Teal
    "#48BB78", // Green
    "#F687B3", // Pink
    "#B794F4", // Light purple
    "#F6AD55", // Light orange
    "#718096", // Cool gray
    "#97735B", // Warm brown
    "#FFB84D", // Warm yellow
    "#7F9CF5", // Periwinkle
  ];
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload; // Get the data for the hovered slice
      return (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>{`${data.name}: ${data.percentage}%`}</p>
        </div>
      );
    }
    return null;
  };
  const handleSort = (
    column: string,
    sort: any,
    order: any,
    sortItem: any,
    orderItem: any
  ) => {
    if (sortItem === column) {
      order(orderItem === "ASC" ? "DESC" : "ASC");
    } else {
      sort(column);
      order("ASC");
    }
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
      <div className="sm:col-span-12 md:col-span-7">
        <div className="bg-white rounded-lg shadow">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Invoiced | Occassions NSV (Occassion)
            </h2>
          </div>
          <div className="overflow-auto h-screen max-h-[70vh]">
            {isLoadingOccation ? (
              <LoadingBox />
            ) : (
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map(({ key, label }) => (
                      <th
                        key={key}
                        onClick={() =>
                          handleSort(
                            key,
                            setSortFieldOccation,
                            setSortOrderOccation,
                            sortFieldOccation,
                            sortOrderOccation
                          )
                        }
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{label}</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {occasions?.rows?.map((row: any, index: number) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.OCCASSION}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.DEMAND_NSV)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.DEMAND_NSV_LY)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        <span
                          className={`px-2 py-1 rounded-full ${getPercentageColor(
                            row.DEMAND_NSV_VS_LY
                          )}`}
                        >
                          {formatPercentage(row.DEMAND_NSV_VS_LY)}
                        </span>
                      </td>
                    </tr>
                  ))}
                  {occasions?.total && (
                    <tr className="bg-blue-600 sticky bottom-0">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                        Total
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(occasions.total.totalNsv)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(occasions.total.totalNsvLy)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(occasions.total.totalNsvVsLy)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <div className="sm:col-span-12 md:col-span-5 ">
        <div className="bg-white rounded-lg shadow">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Invoiced | Occassions NSV by Country (Occassion, Country)
            </h2>
          </div>
          <div className="overflow-auto h-screen max-h-[70vh]">
            {isLoadingOccationbyCountry ? (
              <LoadingBox />
            ) : (
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {columns2.map(({ key, label }) => (
                      <th
                        key={key}
                        onClick={() =>
                          handleSort(
                            key,
                            setSortFieldOccationbyCountry,
                            setSortOrderOccationbyCountry,
                            sortFieldOccationbyCountry,
                            sortOrderOccationbyCountry
                          )
                        }
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{label}</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {occasionsByCountry?.rows?.map((row: any, index: number) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.OCCASSION}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.COUNTRY}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.DEMAND_NSV)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.DEMAND_NSV_LY)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        <span
                          className={`px-2 py-1 rounded-full ${getPercentageColor(
                            row.DEMAND_NSV_VS_LY
                          )}`}
                        >
                          {formatPercentage(row.DEMAND_NSV_VS_LY)}
                        </span>
                      </td>
                    </tr>
                  ))}
                  {occasionsByCountry?.total && (
                    <tr className="bg-blue-600 sticky bottom-0">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                        Total
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(
                          Number(occasionsByCountry.total.totalNsv)
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(occasionsByCountry.total.totalNsvLy)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(
                          occasionsByCountry.total.totalNsvVsLy
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <div className="sm:col-span-12 md:col-span-8">
        <div className="bg-white rounded-lg shadow mt-5">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Invoiced | Detailed Occasion Analysis
            </h2>
          </div>
          <div className="overflow-x-auto">
            {isLoading ? (
              <LoadingBox />
            ) : (
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {columns3.map(({ key, label }) => (
                      <th
                        key={key}
                        onClick={() =>
                          handleSort(
                            key,
                            setSortField,
                            setSortOrder,
                            sortField,
                            sortOrder
                          )
                        }
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      >
                        <div className="flex items-center space-x-5">
                          <div>{label}</div>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {detailedData?.rows?.map((row: any, index: any) => {
                    return (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                          {row.OCCASSION}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.DEMAND_NSV)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.DEMAND_NSV_LY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <span
                            className={`px-2 py-1 rounded-full ${getPercentageColor(
                              row.DEMAND_NSV_VS_LY
                            )}`}
                          >
                            {formatPercentage(row.DEMAND_NSV_VS_LY)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.DEMAND_NB_OF_ORDERS?.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.DEMAND_NB_OF_ORDERS_LY?.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <span
                            className={`px-2 py-1 rounded-full ${getPercentageColor(
                              row.DEMAND_ORDERS_VS_LY
                            )}`}
                          >
                            {formatPercentage(row.DEMAND_ORDERS_VS_LY)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.DEMAND_AOV)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.DEMAND_AOV_LY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <span
                            className={`px-2 py-1 rounded-full ${getPercentageColor(
                              row.DEMAND_AOV_VS_LY
                            )}`}
                          >
                            {formatPercentage(row.DEMAND_AOV_VS_LY)}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                  {(() => {
                    return (
                      <tr className="bg-blue-600">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                          Total
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                          {formatCurrency(detailedData?.total?.totalNsv)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                          {formatCurrency(detailedData?.total?.totalNsvLy)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                          {formatPercentage(detailedData?.total?.totalNsvVsLy)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                          {detailedData.total?.totalOrders?.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                          {detailedData.total?.totalOrdersLy?.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                          {formatPercentage(
                            detailedData.total?.totalOrdersVsLy
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                          {formatCurrency(detailedData.total?.totalAov)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                          {formatCurrency(detailedData.total?.totalAovLy)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                          {formatPercentage(detailedData.total?.totalAovVsLy)}
                        </td>
                      </tr>
                    );
                  })()}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <div className="sm:col-span-12 md:col-span-4">
        <div className="bg-white rounded-lg shadow mt-5">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Invoiced | Occassion Split %
            </h2>
          </div>
          <div className="w-full h-[320px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  outerRadius={isMobile ? 50 : 100} // Adjust radius based on screen size
                  dataKey="value"
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OccassionSplit;
