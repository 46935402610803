const API = {
  // WEB_URL: "http://localhost:3000",
  // BASE_URL: "http://localhost:8080/",
  BASE_URL: "https://stage.api.maverickbackendeu.mms.com/",

  LOGIN: "auth/microsoft", //POST
  EU_ALLORDER: "eu-all-orders/", //GET POST
  CATEGORY_NSV: "eu-all-orders/product-category-nsv",
  OCCASION_NSV: "eu-all-orders/product-occasion-nsv",
  COUNTRY_NSV: "eu-all-orders/product-country-nsv",
  EU_ALL_PRODUCTS: "eu-product-master/", //GET POST
  DAILY_DEMAND: "eu-all-orders/daily-demand", // POST
  DAILY_DEMAND_AGENCIES: "eu-all-orders/daily-demand-agencies", // POST
  FILTERS: "dashboard/filter-options", //GET
  FILTERSBYCHANNEL: "dashboard/filter-options-by-saleschannel", //POST
  DASHBOARD_METRICS: "dashboard/all-metrics", //POST
  DASHBOARD_ORDERS: "dashboard/all-orders", //POST
  DASHBOARD_COUNTS: "dashboard/total-counts", //POST
  DETAILED_OCCASION_ANALYSIS: "eu-all-orders/detailed-occasion-analysis",
  DETAILED_OCCASION_ANALYSIS_INVOICE:
    "eu-all-orders/detailed-occasion-analysis-invoice",
  OCCASION_SPLIT: "eu-all-orders/occasion-split",
  OCCASIONS_BY_COUNTRY: "eu-all-orders/occasions-by-country",
  OCCASION_SPLIT_INVOICE: "eu-all-orders/occasion-split-invoice",
  OCCASIONS_BY_COUNTRY_INVOICE: "eu-all-orders/occasions-by-country-invoice",
  OCCASIONS: "eu-all-orders/occasions",
  OCCASIONS_INVOICE: "eu-all-orders/occasions-invoice",
  PROMO_BY_COUNTRY: "eu-all-orders/promo-country-distribution",
  PROMO_CAMPAIN: "eu-all-orders/promo-campaign-distribution",
  PROMO_BY_COUNTRY_INVOICE: "eu-all-orders/promo-country-distribution-invoice",
  PROMO_CAMPAINY_INVOICE: "eu-all-orders/promo-campaign-distribution-invoice",
  GA4_TRAFFIC: "eu-all-traffic/ga4-traffic",

  // SUPPLY CARRIER

  CARRIER_ORDERS_INVOICE: "eu-all-orders/carrier-orders-invoice",
  CARRIER_DELIVERY_MODES: "eu-all-orders/carrier-delivery-modes",
  MONTHLY_DISPATCHED_ORDERS: "eu-all-orders/monthly-dispatched-orders",
  CARRIER_DELIVERY_COUNT: "eu-all-orders/carrier-delivery-count",
  CARRIER_MODE_STATS: "eu-all-orders/carrier-mode-stats",
  CARRIER_COUNTRY_STATS_NSV: "eu-all-orders/carrier-country-nsv",
  GET_WEEKLY_VIEW: "eu-all-orders/weekly-view",
  GET_DAILY_SALES_VIEW: "eu-all-orders/daily-sales",
  GET_PERIOD_VIEW: "eu-all-orders/period-view",
};
export default API;
