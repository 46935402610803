import { PublicClientApplication } from "@azure/msal-browser";
import {
  AlertCircle,
  ArrowRight,
  BookOpen,
  CheckCircle,
  LifeBuoy,
  LogIn,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import nexusLogo from "../../assets/images/m&m.logo.png";
import API from "../../config/api";
import { loginSuccess } from "../../redux/slices/authSlice";
import { msalConfig } from "../../services/authService";
import { POST2 } from "../../utils/apiCalls";

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const location = useLocation();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [azureAuthClient, setAzureAuthClient] =
    useState<PublicClientApplication | null>(null);

  useEffect(() => {
    const initializeMsal = async () => {
      const msalInstance = new PublicClientApplication(msalConfig);
      await msalInstance.initialize();
      setAzureAuthClient(msalInstance);
    };

    initializeMsal();
  }, []);

  // Utility function to handle retries
  const retryOperation = async (
    operation: () => Promise<any>,
    maxAttempts: number = 10,
    delayMs: number = 1000
  ): Promise<any> => {
    for (let attempt = 1; attempt <= maxAttempts; attempt++) {
      try {
        const result = await operation();
        if (result?.token) {
          return result;
        }
        // If no token but also no error, wait before retrying
        await new Promise((resolve) => setTimeout(resolve, delayMs));
      } catch (error) {
        if (attempt === maxAttempts) {
          throw error; // Rethrow if this was our last attempt
        }
        // Wait before retrying
        await new Promise((resolve) => setTimeout(resolve, delayMs));
      }
    }
    // throw new Error(`Operation failed after ${maxAttempts} attempts`);
    throw new Error(`Login Failed !`);
  };

  const handleMicrosoftLogin = async () => {
    if (azureAuthClient) {
      setIsLoading(true);
      setError(null); // Clear any previous errors
      try {
        const res = await azureAuthClient.acquireTokenPopup({
          scopes: msalConfig.auth.scopes,
        });

        if (!res?.account?.idTokenClaims?.oid) {
          setError(
            "You must be associated with MARS OKTA to verify your account"
          );
          return;
        }

        // Retry the DB login operation
        const loginResult = await retryOperation(
          async () => {
            const result = await POST2(API.LOGIN, {
              oid: res.account?.idTokenClaims?.oid,
              email: res.account?.username,
            });
            return result;
          },
          10, // Maximum 10 attempts
          1000 // 1 second delay between attempts
        );

        if (loginResult?.token) {
          dispatch(
            loginSuccess({
              token: loginResult.token,
              user: loginResult,
            })
          );
          navigate("/oms-dashboard");
        } else {
          setError(
            "Your OKTA account was verified, but you do not have permission to access this application. Please request access by emailing Digital_EU_OMS_Support@effem.com"
          );
        }
      } catch (e) {
        console.log("Login error:", e);
        setError(`Login error: ${e instanceof Error ? e.message : String(e)}`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col lg:flex-row font-sans">
      {/* Left section - Login Card */}
      <div className="w-full lg:w-1/2 bg-white flex items-center justify-center p-4 lg:p-0">
        <div className="flex flex-col justify-center w-full max-w-[480px] h-[550px] bg-white rounded-lg p-4 sm:p-6 lg:p-8 shadow-[0_4px_20px_rgba(0,0,0,0.1)] transform transition-transform duration-300 ease-in-out hover:scale-105">
          <h1 className="text-[32px] sm:text-[40px] font-bold text-[#003B71] text-center mb-6 lg:mb-8 leading-[40px] sm:leading-[50px]">
            LOGIN TO
            <br />
            NEXUS | OMS & CCM
          </h1>
          <button
            onClick={handleMicrosoftLogin}
            disabled={isLoading}
            className="w-full bg-[#005b99] text-white h-[45px] sm:h-[50px] px-4 rounded-[4px] mb-3 flex items-center justify-center transform transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-[#004974] disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <LogIn className="w-5 h-5 sm:w-6 sm:h-6 mr-1" strokeWidth={3.5} />
            <span className="font-bold text-[16px] sm:text-[20px] leading-none">
              {isLoading ? "Logging in..." : "Login with MARS Okta"}
            </span>
          </button>

          {/* Error message display */}
          {error && (
            <div className="flex items-start justify-center text-red-500 mb-4 p-2 bg-red-50 rounded">
              <AlertCircle className="w-15 h-6 mr-2" size={22} />
              <span className="text-sm">{error}</span>
            </div>
          )}

          <div className="flex justify-center mb-6">
            <img
              src={nexusLogo}
              alt="M&M Digital Logo"
              className="w-10 h-10 sm:w-12 sm:h-12 object-contain"
            />
          </div>
          <div className="text-center text-[#888] text-sm sm:text-base leading-[1.5] mb-6">
            You have connected to a proprietary system. Only authorized users
            may access this system. Access by unauthorized individuals is
            prohibited.
          </div>
          <div className="text-center text-gray-500 text-xs sm:text-sm">
            Developed for M&M Digital EU
          </div>
        </div>
      </div>

      {/* Right section - Info */}
      <div className="w-full lg:w-1/2 bg-[#005b99] text-white p-4 sm:p-6 lg:py-16 lg:pl-8 lg:pr-16 flex items-center">
        <div className="w-full max-w-xl mx-auto lg:mx-0">
          <section className="mb-8 lg:mb-10">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              How to Login Using Okta
            </h2>
            <div className="space-y-3 lg:space-y-4">
              {[
                'Click the "Login with MARS Okta" button on the login page.',
                "You will be redirected to Okta's secure login page.",
                "Enter your credentials.",
                "Once authenticated, you will be redirected to the NEXUS OMS dashboard.",
              ].map((text, index) => (
                <div key={index} className="flex items-center">
                  <ArrowRight className="w-5 h-5 sm:w-6 sm:h-6 mr-2 lg:mr-3 flex-shrink-0" />
                  <p className="text-sm sm:text-base">{text}</p>
                </div>
              ))}
            </div>
          </section>

          <section className="mb-8 lg:mb-10">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              System Features & Purpose
            </h2>
            <div className="space-y-3 lg:space-y-4">
              {[
                "Streamlined order management and customer communication system.",
                "Integrates with various data sources for real-time reporting.",
                "Helps manage orders, customer profiles, and automated updates.",
              ].map((text, index) => (
                <div key={index} className="flex items-center">
                  <CheckCircle
                    className="w-5 h-5 sm:w-6 sm:h-6 mr-2 lg:mr-3 flex-shrink-0 text-white"
                    strokeWidth={2.5}
                  />
                  <p className="text-sm sm:text-base">{text}</p>
                </div>
              ))}
            </div>
          </section>

          <section>
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              Help and KT in Using the System
            </h2>
            <div className="space-y-3 lg:space-y-4">
              <div className="flex items-center">
                <LifeBuoy
                  className="w-5 h-5 sm:w-6 sm:h-6 mr-2 lg:mr-3 flex-shrink-0 text-white"
                  strokeWidth={2.5}
                />
                <p className="text-sm sm:text-base whitespace-normal xl:whitespace-nowrap break-words">
                  Need help? Contact the support team at
                  [Digital_EU_OMS_Support@effem.com]
                </p>
              </div>
              <div className="flex items-center">
                <BookOpen
                  className="w-5 h-5 sm:w-6 sm:h-6 mr-2 lg:mr-3 flex-shrink-0 text-white"
                  strokeWidth={2.5}
                />
                <p className="text-sm sm:text-base">
                  For detailed KT resources, visit the NEXUS OMS documentation
                  site.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Login;
