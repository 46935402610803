import React from "react";

const UnLock = () => {
  return (
    <div className="text-xl font-bold text-center mt-[200px] text-[#a1343c]">
      Planned For Phase 2
    </div>
  );
};

export default UnLock;
