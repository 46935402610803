import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const FactoryConsumption = () => {
  // const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { key: "name", label: "Name" },
    { key: "p1", label: "p1" },
    { key: "p10", label: "p10" },
    { key: "p11", label: "p11" },
    { key: "p12", label: "p12" },
    { key: "p13", label: "p13" },
    { key: "p2", label: "p2" },
    { key: "p3", label: "p3" },
    { key: "p4", label: "p4" },
    { key: "p5", label: "p5" },
    { key: "p6", label: "p6" },
    { key: "p7", label: "p7" },
    { key: "p8", label: "p8" },
    { key: "p9", label: "p9" },
  ];

  const dummyData = [
    {
      name: "Apothecary Jar",
      p1: 1234,
      p10: 345,
      p11: 5647,
      p12: 941,
      p13: 876,
      p2: 82,
      p3: 38,
      p4: 169,
      p5: 629,
      p6: 639,
      p7: 439,
      p8: 569,
      p9: 269,
    },
    {
      name: "Boite 50 g",
      p1: 1234,
      p10: 345,
      p11: 5647,
      p12: 941,
      p13: 876,
      p2: 82,
      p3: 38,
      p4: 169,
      p5: 629,
      p6: 639,
      p7: 439,
      p8: 569,
      p9: 269,
    },
  ];

  const totalRow: any = {
    p1: 12234,
    p10: 1345,
    p11: 25647,
    p12: 2941,
    p13: 1876,
    p2: 8212,
    p3: 2138,
    p4: 13169,
    p5: 62129,
    p6: 6239,
    p7: 43219,
    p8: 5629,
    p9: 2629,
  };

  // const getPercentageColor = (value: any) => {
  //   const numValue = parseFloat(value);
  //   if (numValue > 0) return "bg-green-100 text-green-800";
  //   if (numValue < 0) return "bg-red-100 text-red-800";
  //   return "text-gray-600";
  // };

  // const formatCurrency = (value: any) => {
  //   if (isNaN(value)) return "-";
  //   return new Intl.NumberFormat("en-EU", {
  //     style: "currency",
  //     currency: "EUR",
  //     minimumFractionDigits: 0,
  //     maximumFractionDigits: 0,
  //   }).format(value);
  // };

  // const formatPercentage = (value: any) => {
  //   if (isNaN(value)) return "-";
  //   return `${value.toFixed(1)}%`;
  // };
  const lineData = [
    { period: "P2", value: 39.7 },
    { period: "P4", value: 34.6 },
    { period: "P5", value: 31.7 },
    { period: "P12", value: 25.9 },
    { period: "P10", value: 21.1 },
    { period: "P3", value: 18.5 },
    { period: "P6", value: 16.3 },
    { period: "P9", value: 14.2 },
    { period: "P11", value: 12.7 },
    { period: "P1", value: 9.2 },
    { period: "P7", value: 8.2 },
    { period: "P8", value: 7 },
    { period: "P13", value: 6.2 },
  ];
  return (
    <div>
      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-[#6b2228]">
            Produced (BOM Stock Movement/Consumption){" "}
          </h2>
        </div>
        <div className="overflow-x-auto">
          {false ? (
            <div>Loading...</div>
          ) : (
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map(({ key, label }) => (
                    <th
                      key={key}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    >
                      <div className="flex items-center space-x-1">
                        <span>{label}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {dummyData.map((row, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.p1}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.p10}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.p11}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.p12}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.p13}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.p2}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.p3}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.p4}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.p5}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.p6}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.p7}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.p8}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.p9}
                    </td>
                  </tr>
                ))}
                <tr className="bg-blue-600">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                    Total
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {totalRow.p1}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {totalRow.p10}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {totalRow.p11}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {totalRow.p12}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {totalRow.p13}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {totalRow.p2}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {totalRow.p3}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {totalRow.p4}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {totalRow.p5}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {totalRow.p6}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {totalRow.p7}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {totalRow.p8}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {totalRow.p9}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="bg-white rounded-lg shadow mt-5">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-[#6b2228]">
            Produced (BOM Stock Movement/Consumption)
          </h2>
        </div>
        <div className="w-full h-[320px] mt-12">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={lineData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="period"
                angle={-90}
                textAnchor="end"
                height={100}
                axisLine={false}
                label={{
                  value: "Mars Period",
                  //   angle: -90,
                  position: "center",
                  dx: 0,
                  dy: 20,
                  style: { textAnchor: "middle", fontSize: "12px" },
                }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) => `${value}M`}
                label={{
                  value: "Qty",
                  angle: -90,
                  position: "insideLeft",
                  dx: 0,
                  dy: 20,
                  style: { textAnchor: "middle", fontSize: "12px" },
                }}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#2196F3"
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default FactoryConsumption;
