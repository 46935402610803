import React, { useEffect, useState } from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const ProductSplit = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [props?.item]);
  const columns1 = [
    { key: "name", label: "name_EN" },
    { key: "invoiced_qty", label: "Invoiced Quantity" },
    { key: "invoiced_nsv", label: "Invoiced NSV" },
    { key: "invoiced_qty_ly", label: "Invoiced Quantity LY" },
    { key: "invoiced_nsv_ly", label: "Invoiced NSV LY" },
  ];
  const columns2 = [
    { key: "category", label: "Category" },
    { key: "p1", label: "P01" },
    { key: "p2", label: "P02" },
    { key: "p3", label: "P03" },
    { key: "p4", label: "P04" },
    { key: "p5", label: "P05" },
    { key: "p6", label: "P06" },
    { key: "p7", label: "P07" },
    { key: "p8", label: "P08" },
    { key: "p9", label: "P09" },
    { key: "p10", label: "P10" },
    { key: "p11", label: "P11" },
    { key: "p12", label: "P12" },
    { key: "p13", label: "P13" },
    { key: "total", label: "Total" },
  ];
  const columns3 = [
    { key: "category", label: "category" },
    { key: "invoiced_nsv", label: "Invoiced NSV" },
    { key: "invoiced_nsv_ly", label: "Invoiced NSV LY" },
    { key: "invoiced_nsv_vs_ly", label: "Invoiced NSV vs LY %" },
    { key: "invoiced_qty", label: "Invoiced Quantity" },
    { key: "invoiced_qty_ly", label: "Invoiced Quantity LY" },
    { key: "invoiced_qty_vs_ly", label: "Invoiced Quantity vs LY %" },
    { key: "count", label: "Count of Orders having the product category" },
    { key: "invoiced_aov", label: "Invoiced AOV (NSV)" },
  ];
  const dummyData1 = [
    {
      name: "Birth",
      invoiced_qty: 364,
      invoiced_nsv: 984,
      invoiced_qty_ly: 42,
      invoiced_nsv_ly: 42,
    },
    {
      name: "Birth",
      invoiced_qty: 364,
      invoiced_nsv: 984,
      invoiced_qty_ly: 42,
      invoiced_nsv_ly: 42,
    },
  ];
  const dummyData2 = [
    {
      category: "Birth",
      p1: 12234,
      p2: 8212,
      p3: 2138,
      p4: 13169,
      p5: 62129,
      p6: 6239,
      p7: 43219,
      p8: 5629,
      p9: 2629,
      p10: 1345,
      p11: 25647,
      p12: 2941,
      p13: 1876,
      total: 42876,
    },
    {
      category: "Birth",
      p1: 12234,
      p2: 8212,
      p3: 2138,
      p4: 13169,
      p5: 62129,
      p6: 6239,
      p7: 43219,
      p8: 5629,
      p9: 2629,
      p10: 1345,
      p11: 25647,
      p12: 2941,
      p13: 1876,
      total: 42876,
    },
  ];
  const dummyData3 = [
    {
      category: "Birth",
      invoiced_nsv: 364,
      invoiced_nsv_ly: 984,
      invoiced_nsv_vs_ly: 42,
      invoiced_qty: 42,
      invoiced_qty_ly: 42,
      invoiced_qty_vs_ly: 42,
      count: 42,
      invoiced_aov: 42,
    },
    {
      category: "Birth",
      invoiced_nsv: 364,
      invoiced_nsv_ly: 984,
      invoiced_nsv_vs_ly: 42,
      invoiced_qty: 42,
      invoiced_qty_ly: 42,
      invoiced_qty_vs_ly: 42,
      count: 42,
      invoiced_aov: 42,
    },
  ];
  const formatCurrency = (value: any) => {
    if (isNaN(value)) return "-";
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };
  const totalRow1: any = {
    invoiced_qty: 364,
    invoiced_nsv: 984,
    invoiced_qty_ly: 42,
    invoiced_nsv_ly: 42,
  };
  const totalRow2: any = {
    p1: 12234,
    p2: 8212,
    p3: 2138,
    p4: 13169,
    p5: 62129,
    p6: 6239,
    p7: 43219,
    p8: 5629,
    p9: 2629,
    p10: 1345,
    p11: 25647,
    p12: 2941,
    p13: 1876,
    total: 675467,
  };
  const pieData = [
    { name: "Favors", value: 56.79, percentage: 41.78 },
    { name: "Gifting", value: 45.19, percentage: 33.25 },
    { name: "Accessories", value: 33.44, percentage: 24.64 },
    { name: "Goodies", value: 2.5, percentage: 2.37 },
  ];
  const COLORS = ["#1E88E5", "#FF5722", "#673AB7", "#2E7D32"];

  return (
    <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
      <div className="sm:col-span-12 md:col-span-5">
        <div className="bg-white rounded-lg shadow mt-5">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Top Products{" "}
            </h2>
          </div>
          <div className="overflow-x-auto">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {columns1.map(({ key, label }) => (
                      <th
                        key={key}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{label}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {dummyData1.map((row, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.invoiced_qty)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.invoiced_nsv)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.invoiced_qty_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.invoiced_nsv_ly)}
                      </td>
                    </tr>
                  ))}
                  <tr className="bg-blue-600">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                      Total
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {totalRow1.invoiced_qty}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow1.invoiced_nsv)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {totalRow1.invoiced_qty_ly}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow1.invoiced_nsv_ly)}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <div className="sm:col-span-12 md:col-span-7">
        <div className="bg-white rounded-lg shadow mt-5">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Periodic NSV{" "}
            </h2>
          </div>
          <div className="overflow-x-auto">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {columns2.map(({ key, label }) => (
                      <th
                        key={key}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{label}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {dummyData2.map((row, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.category}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.p1)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.p2)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.p3)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.p4)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.p5)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.p6)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.p7)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.p8)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.p9)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.p10)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.p11)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.p12)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.p13)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.total)}
                      </td>
                    </tr>
                  ))}
                  <tr className="bg-blue-600">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                      Total
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow2.p1)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow2.p2)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow2.p3)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow2.p4)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow2.p5)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow2.p6)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow2.p7)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow2.p8)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow2.p9)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow2.p10)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow2.p11)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow2.p12)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow2.p13)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(totalRow2.total)}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <div className="sm:col-span-12 md:col-span-5">
        <div className="bg-white rounded-lg shadow mt-5">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Category Distribution
            </h2>
          </div>
          <div className="w-full h-[320px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  outerRadius={isMobile ? 50 : 100} // Adjust radius based on screen size
                  dataKey="value"
                  label={({ name, percentage }) =>
                    `${name} (${percentage?.toFixed(2)}%)`
                  }
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="sm:col-span-12 md:col-span-7">
        <div className="bg-white rounded-lg shadow mt-5">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Sales KPIs{" "}
            </h2>
          </div>
          <div className="overflow-x-auto">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {columns3.map(({ key, label }) => (
                      <th
                        key={key}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{label}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {dummyData3.map((row, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.category}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.invoiced_nsv)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.invoiced_nsv_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.invoiced_nsv_vs_ly}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.invoiced_qty)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.invoiced_qty_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.invoiced_qty_vs_ly}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.count)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.invoiced_aov)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="bg-blue-50 p-4 mt-3 text-sm text-gray-600 italic text-center mt-5">
          <p>
            Sales KPI : the report shows the products that were ordered and
            their related order numbers count.One order can have products from
            different categories.In those cases,the count(numbers of
            order)increases for each category
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductSplit;
