import { ArrowUpDown, CircleX } from "lucide-react";

const Ga4 = () => {
  const columns = [
    { key: "period", label: "Period" },
    { key: "week", label: "Week" },
    { key: "total_users_vs_ly", label: "Total Users vs LY" },
    { key: "sessions_vs_ly", label: "Sessions vs LY" },
    { key: "transactions_vs_ly", label: "Transactions vs LY" },
    { key: "demand_vs_ly", label: "Demand vs LY" },
    { key: "aov_vs_ly", label: "AOV vs LY %" },
    { key: "conversion_rate_vs_ly", label: "Conversion Rate vs LY" },
  ];
  const ga4Data = [
    {
      period: "2024 P5",
      week: "W1",
      total_users_vs_ly: 243.4,
      sessions_vs_ly: 241.0,
      transactions_vs_ly: 20.1,
      demand_vs_ly: 15.7,
      aov_vs_ly: -6.4,
      conversion_rate_vs_ly: -51.5,
    },
    {
      period: "2024 P5",
      week: "W2",
      total_users_vs_ly: 243.7,
      sessions_vs_ly: 245.3,
      transactions_vs_ly: 25.7,
      demand_vs_ly: 26.5,
      aov_vs_ly: 6.1,
      conversion_rate_vs_ly: -48.5,
    },
    {
      period: "2024 P5",
      week: "W3",
      total_users_vs_ly: 240.0,
      sessions_vs_ly: 241.1,
      transactions_vs_ly: 14.9,
      demand_vs_ly: 7.0,
      aov_vs_ly: -6.8,
      conversion_rate_vs_ly: -45.1,
    },
    {
      period: "2024 P5",
      week: "W4",
      total_users_vs_ly: 245.8,
      sessions_vs_ly: 244.4,
      transactions_vs_ly: 36.2,
      demand_vs_ly: 25.5,
      aov_vs_ly: -6.9,
      conversion_rate_vs_ly: -55.1,
    },
  ];
  const omsData = [
    {
      period: "2024 P5",
      week: "W1",
      orders: 3264,
      demand: 310763,
      aov: 95,
    },
    {
      period: "2024 P5",
      week: "W2",
      orders: 3535,
      demand: 335508,
      aov: 95,
    },
    {
      period: "2024 P5",
      week: "W3",
      orders: 3193,
      demand: 304384,
      aov: 95,
    },
    {
      period: "2024 P5",
      week: "W4",
      orders: 2655,
      demand: 244592,
      aov: 92,
    },
  ];
  const ga4Total = {
    total_users_vs_ly: 243.1,
    sessions_vs_ly: 242.9,
    transactions_vs_ly: 23.3,
    demand_vs_ly: 18.0,
    aov_vs_ly: -5.0,
    conversion_rate_vs_ly: -50.0,
  };
  const omsTotal = {
    orders: -10.3,
    demand: -7.6,
    aov: 2.8,
  };
  const formatCurrency = (value: any) => {
    if (isNaN(value)) return "-";
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };
  const formatPercentage = (value: any) => {
    if (isNaN(value)) return "-";
    return `${value?.toFixed(1)}%`;
  };
  const getPercentageColor = (value: any) => {
    const numValue = parseFloat(value);
    if (numValue > 0) return "bg-green-100 text-green-800";
    if (numValue < 0) return "bg-red-100 text-red-800";
    return "text-gray-600";
  };

  return (
    <div>
      <div className="bg-blue-50 mb-5 p-4 font-bold text-sm text-[#a1343c] text-center italic">
        <p>
          GA4 taken from MMS ALL Production Property where the dimension
          "Country Platform" is not "Not Set" or "US"
        </p>
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b border-gray-200 flex justify-between">
          <h2 className="text-lg font-semibold text-[#6b2228]">
            EU | WEBSITE Demand & Traffic KPI
          </h2>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
            <div className="sm:col-span-12 md:col-span-12 lg:col-span-7 space-y-4">
              <h2 className="text-lg font-semibold text-[#a1343c] italic">
                GA4 Dataset
              </h2>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      {columns.map(({ key, label }) => (
                        <th
                          key={key}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        >
                          <div className="flex items-center space-x-1">
                            <span>{label}</span>
                            <ArrowUpDown className="h-4 w-4" />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {ga4Data.map((row, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.period}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.week}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                              row.total_users_vs_ly
                            )}`}
                          >
                            {formatPercentage(row.total_users_vs_ly)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                              row.sessions_vs_ly
                            )}`}
                          >
                            {formatPercentage(row.sessions_vs_ly)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                              row.transactions_vs_ly
                            )}`}
                          >
                            {formatPercentage(row.transactions_vs_ly)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                              row.demand_vs_ly
                            )}`}
                          >
                            {formatPercentage(row.demand_vs_ly)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatPercentage(row.aov_vs_ly)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                              row.conversion_rate_vs_ly
                            )}`}
                          >
                            {formatPercentage(row.conversion_rate_vs_ly)}
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                        GA4 Data
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(ga4Total.total_users_vs_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(ga4Total.sessions_vs_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(ga4Total.transactions_vs_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(ga4Total.demand_vs_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(ga4Total.aov_vs_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(ga4Total.conversion_rate_vs_ly)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="sm:col-span-12 md:col-span-12 lg:col-span-5 space-y-4">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold text-[#a1343c] italic">
                  ORACLE APEX (OMS)
                </h2>
                <span
                  className="text-[#62be84] text-sm px-2"
                  style={{ width: 300, textAlign: "center" }}
                >
                  Orders that don't go through the website are not displayed
                  here.
                </span>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Period
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Week
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Orders
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Demand
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        AOV
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {omsData.map((row, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.period}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.week}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.orders)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.demand)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.aov)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                        OMS Data
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(omsTotal.orders)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(omsTotal.demand)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(omsTotal.aov)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b border-gray-200 flex justify-between">
          <h2 className="text-lg font-semibold text-[#6b2228]">
            EU | WEBSITE Demand & Traffic KPI || vs Last Year
          </h2>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
            <div className="sm:col-span-12 md:col-span-12 lg:col-span-7 space-y-4">
              <h2 className="text-lg font-semibold text-[#a1343c] italic">
                GA4 Dataset
              </h2>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      {columns.map(({ key, label }) => (
                        <th
                          key={key}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        >
                          <div className="flex items-center space-x-1">
                            <span>{label}</span>
                            <ArrowUpDown className="h-4 w-4" />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {ga4Data.map((row, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.period}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.week}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                              row.total_users_vs_ly
                            )}`}
                          >
                            {formatPercentage(row.total_users_vs_ly)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                              row.sessions_vs_ly
                            )}`}
                          >
                            {formatPercentage(row.sessions_vs_ly)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                              row.transactions_vs_ly
                            )}`}
                          >
                            {formatPercentage(row.transactions_vs_ly)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                              row.demand_vs_ly
                            )}`}
                          >
                            {formatPercentage(row.demand_vs_ly)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatPercentage(row.aov_vs_ly)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                              row.conversion_rate_vs_ly
                            )}`}
                          >
                            {formatPercentage(row.conversion_rate_vs_ly)}
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                        GA4 Data
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(ga4Total.total_users_vs_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(ga4Total.sessions_vs_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(ga4Total.transactions_vs_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(ga4Total.demand_vs_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(ga4Total.aov_vs_ly)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(ga4Total.conversion_rate_vs_ly)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="sm:col-span-12 md:col-span-12 lg:col-span-5 space-y-4">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold text-[#a1343c] italic">
                  ORACLE APEX (OMS)
                </h2>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Period
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Week
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Orders
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Demand
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        AOV
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {omsData.map((row, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.period}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.week}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.orders)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.demand)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.aov)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                        OMS Data
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(omsTotal.orders)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(omsTotal.demand)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatPercentage(omsTotal.aov)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ga4;
