import React, { useState, useEffect } from "react";
import { AnalyticCard } from "../../../components/analyticsCard/analyticsCard";
import {
  UsersIcon,
  MousePointerClickIcon,
  EyeIcon,
  ArrowUpDown,
} from "lucide-react";
import { POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import LoadingBox from "../../../components/LoadingBox";
import moment from "moment";

const DailyDemandAgency = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [demandData, setDemandData] = useState<any>({ rows: [], total: {} });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortField, setSortField] = useState<any>("CREATEDDATE");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [headingTxt] = useState("Demand by Date");
  const formatCurrency = (value: any) => {
    if (isNaN(value) || value === null) return "-";
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };
  const metrics = [
    {
      icon: <UsersIcon className="w-5 h-5 text-blue-600" />,
      value: demandData?.total?.totalUsersGa4?.toLocaleString() || "0",
      label: "Total Users",
      headerIcon: <UsersIcon className="w-4 h-4 text-blue-600" />,
    },
    {
      icon: <MousePointerClickIcon className="w-5 h-5 text-green-600" />,
      value: demandData?.total?.totalTransactionsGa4?.toLocaleString() || "0",
      label: "Total Transactions",
      headerIcon: <MousePointerClickIcon className="w-4 h-4 text-green-600" />,
    },
    {
      icon: <MousePointerClickIcon className="w-5 h-5 text-red-500" />,
      value: formatCurrency(demandData?.total?.totalGA4NSV) || "0",
      label: "Total Demand NSV GA4",
      headerIcon: <MousePointerClickIcon className="w-4 h-4 text-red-500" />,
    },
  ];

  const columns = [
    { key: "MARS_YEAR", key1: "MARS_CREATED_YEAR", label: "Year" },
    { key: "MARS_PERIOD", key1: "MARS_CREATED_PERIOD", label: "Period" },
    { key: "MARS_WEEK", key1: "MARS_CREATED_WEEK", label: "Week" },
    { key: "ORDERED_DATE", key1: "CREATEDDATE", label: "Date" },
    {
      key: "TRANSACTIONS_GA4",
      key1: "TRANSACTIONS_GA4",
      label: "GA4 Transactions",
    },
    {
      key: "TRANSACTIONS_GA4_LY",
      key1: "TRANSACTIONS_GA4_LY",
      label: "GA4 Transactions LY",
    },
    { key: "GA4_DEMAND_NSV", key1: "GA4_DEMAND_NSV", label: "GA4 Demand NSV" },
    {
      key: "GA4_DEMAND_NSV_LY",
      key1: "GA4_DEMAND_NSV_LY",
      label: "GA4 Demand NSV LY",
    },
    { key: "NO_OF_ORDERS_OMS", key1: "order_count", label: "Orders" },
    { key: "NO_OF_ORDERS_OMS_LY", key1: "order_count", label: "Orders LY" },
    { key: "NO_OF_ORDERS_VS_LY", key1: "order_count", label: "Orders vs LY %" },
    { key: "DEMAND_NSV_OMS", key1: "total_nsv", label: "Demand NSV" },
    { key: "DEMAND_NSV_OMS_LY", key1: "total_nsv", label: "Demand NSV LY" },
    { key: "DEMAND_NSV_VS_LY", key1: "total_nsv", label: "Demand NSV vs LY %" },
    { key: "DEMAND_AOV_OMS", key1: "total_nsv", label: "AOV" },
    { key: "DEMAND_AOV_OMS_LY", key1: "total_nsv", label: "AOV LY" },
    { key: "DEMAND_AOV_VS_LY", key1: "total_nsv", label: "AOV vs LY %" },
    { key: "TOTAL_USERS_GA4", key1: "TOTAL_USERS_GA4", label: "Users" },
    {
      key: "TOTAL_USERS_GA4_LY",
      key1: "TOTAL_USERS_GA4_LY",
      label: "Users LY",
    },
    {
      key: "CVR_GA4_SESSIONS",
      key1: "CVR_GA4_SESSIONS",
      label: "GA4 Sessions CVR",
    },
  ];

  useEffect(() => {
    fetchDemandData({
      ...props.filters,
      sort: columns.some((item: any) => item.key1 === sortField)
        ? sortField
        : null,
      order: columns.some((item: any) => item.key1 === sortField)
        ? sortOrder
        : null,
      page: currentPage,
      limit: itemsPerPage,
    });
  }, [props?.filters, currentPage, itemsPerPage, sortField, sortOrder]);

  const fetchDemandData = async (filterValues: any = {}) => {
    setIsLoading(true);
    try {
      const response: any = await POST(API.DAILY_DEMAND_AGENCIES, filterValues);
      if (response) {
        setDemandData(response);
      }
    } catch (error) {
      console.error("Error fetching demand data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatPercentage = (value: any) => {
    if (isNaN(value) || value === null) return "-";
    return `${Number(value).toFixed(1)}%`;
  };

  const formatDate = (date: any) => {
    if (!date) return "-";
    return moment(date).format("DD MMMM YYYY");
  };

  const formatValue = (key: string, value: any) => {
    if (key.includes("VS_LY") || key.includes("CVR")) {
      return formatPercentage(value);
    }
    if (key.includes("NSV") || key.includes("AOV")) {
      return formatCurrency(value);
    }
    if (key === "ORDERED_DATE") {
      return formatDate(value);
    }
    return value?.toLocaleString() || "-";
  };

  const getPercentageColor = (value: any) => {
    if (value > 0) return "bg-green-100 text-green-800";
    if (value < 0) return "bg-red-100 text-red-800";
    return "text-gray-600";
  };
  const handleEntriesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };
  const renderPageNumbers = () => {
    const pages = [];
    const showAround = 1;

    pages.push(1);

    if (currentPage > 2 + showAround) {
      pages.push("...");
    }

    for (
      let i = Math.max(2, currentPage - showAround);
      i <= Math.min(demandData.totalPages - 1, currentPage + showAround);
      i++
    ) {
      pages.push(i);
    }

    if (currentPage < demandData.totalPages - (1 + showAround)) {
      pages.push("...");
    }

    if (demandData.totalPages > 1) {
      pages.push(demandData.totalPages);
    }

    return (
      <div className="flex items-center space-x-1">
        {pages.map((page, index) => (
          <React.Fragment key={index}>
            {page === "..." ? (
              <span className="px-2 py-1 text-gray-500">...</span>
            ) : (
              <button
                onClick={() => setCurrentPage(page as number)}
                className={`min-w-[32px] px-2 py-1 rounded text-sm ${
                  currentPage === page
                    ? "bg-gray-600 text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                {page}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };
  const handleSort = (column: string) => {
    if (sortField === column) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSortField(column);
      setSortOrder("ASC");
    }
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
      <div className="sm:col-span-12 md:col-span-3 lg:col-span-2 space-y-4">
        {metrics.map((metric, index) => (
          <AnalyticCard
            key={index}
            icon={metric.icon}
            value={metric.value}
            label={metric.label}
          />
        ))}
      </div>

      <div className="sm:col-span-12 md:col-span-9 lg:col-span-10 space-y-4">
        <div className="bg-white rounded-lg shadow">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              {headingTxt}
            </h2>
          </div>
          <div className="overflow-x-auto">
            {isLoading ? (
              <LoadingBox />
            ) : (
              <>
                <div className="flex items-center gap-2 m-2 ml-4">
                  <span className="text-sm text-gray-600">Show</span>
                  <select
                    value={itemsPerPage}
                    onChange={handleEntriesChange}
                    className="px-2 py-1 border border-gray-300 rounded-md text-sm"
                  >
                    {[10, 25, 50, 100].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <span className="text-sm text-gray-600">entries</span>
                </div>

                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      {columns.map((column) => (
                        <th
                          key={column.key}
                          onClick={() => handleSort(column.key1)}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          <div className="flex items-center space-x-1">
                            <span>{column.label}</span>
                            <ArrowUpDown className="h-4 w-4" />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {demandData.rows?.map((row: any, index: any) => (
                      <tr key={index} className="hover:bg-gray-50">
                        {columns.map((column) => (
                          <td
                            key={column.key}
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-600"
                          >
                            {column.key.includes("VS_LY") ||
                            column.key.includes("CVR") ? (
                              <span
                                className={`px-2 py-1 rounded-full ${getPercentageColor(
                                  row[column.key]
                                )}`}
                              >
                                {formatValue(column.key, row[column.key])}
                              </span>
                            ) : (
                              formatValue(column.key, row[column.key])
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                        {/* {totalRow.MARS_YEAR} */}Total
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {demandData.total?.totalTransactionsGa4?.toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {demandData.total?.totalTransactionsGa4Ly?.toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {demandData.total?.totalGA4NSV?.toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {" "}
                        {demandData.total?.totalGA4NSVLy?.toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {demandData.total?.totalOrdersOms?.toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {demandData.total?.totalOrdersOmsLy?.toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(demandData?.total?.totalNsvOms)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {" "}
                        {formatCurrency(demandData?.total?.totalNsvOmsLy)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {formatCurrency(demandData?.total?.totalDemandAOV)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {demandData.total?.totalUsersGa4?.toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                        {demandData.total?.totalUsersGa4Ly?.toLocaleString()}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            <div className="px-6 py-4 border-t border-gray-200">
              <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                <div className="text-sm text-gray-500">
                  Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                  {Math.min(
                    (currentPage - 1) * itemsPerPage + props.data?.length || 0
                  )}{" "}
                  of {itemsPerPage * demandData?.totalPages} entries
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                    className="px-3 py-1 text-sm disabled:opacity-50"
                  >
                    Previous
                  </button>
                  {renderPageNumbers()}
                  <button
                    onClick={() =>
                      setCurrentPage((prev) =>
                        Math.min(prev + 1, props.totalPages)
                      )
                    }
                    disabled={currentPage === props.totalPages}
                    className="px-3 py-1 text-sm disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyDemandAgency;
