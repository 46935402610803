import React, { useState, useEffect, useMemo } from "react";
import { Settings, RotateCw, ArrowUpDown, Edit2 } from "lucide-react";
import { Button, Checkbox, DatePicker, Input, Select } from "antd";
import moment from "moment";
import LoadingBox from "../../components/LoadingBox";

interface ProductionRow {
  date: string;
  team: string;
  compositionType: string;
  quantity: string;
  plannedQuantityUnit: string;
  plannedQuantityKG: string;
  ratio: string;
  staffComposition: string;
  dayMode?: boolean;
}

const ProductionPlan = () => {
  const columns: { key: keyof ProductionRow; label: string }[] = [
    { key: "date", label: "Date" },
    { key: "team", label: "Team" },
    { key: "compositionType", label: "Composition Type" },
    { key: "quantity", label: "Quantity" },
    { key: "plannedQuantityUnit", label: "Planned Quantity Unit" },
    { key: "plannedQuantityKG", label: "Planned Quantity KG" },
    { key: "ratio", label: "Ratio" },
    { key: "staffComposition", label: "Staff Composition" },
  ];

  const initialData: ProductionRow[] = [
    {
      date: "17/01/2024",
      team: "D1",
      compositionType: "4000G",
      quantity: "2580",
      plannedQuantityUnit: "103.20",
      plannedQuantityKG: "11",
      ratio: "1",
      staffComposition: "Yes",
    },
    {
      date: "1/01/2022",
      team: "D2",
      compositionType: "1020G",
      quantity: "22280",
      plannedQuantityUnit: "122.20",
      plannedQuantityKG: "1",
      ratio: "5",
      staffComposition: "Yes",
    },
    {
      date: "5/01/2023",
      team: "D2",
      compositionType: "1670G",
      quantity: "123450",
      plannedQuantityUnit: "9999",
      plannedQuantityKG: "221",
      ratio: "522",
      staffComposition: "Yes",
    },
    {
      date: "8/01/2024",
      team: "D1",
      compositionType: "1720G",
      quantity: "123450",
      plannedQuantityUnit: "9999",
      plannedQuantityKG: "221",
      ratio: "522",
      staffComposition: "Yes",
    },
    {
      date: "30/01/2022",
      team: "D2",
      compositionType: "1000G",
      quantity: "123450",
      plannedQuantityUnit: "9999",
      plannedQuantityKG: "221",
      ratio: "522",
      staffComposition: "Yes",
    },
  ];

  const [tableData, setTableData] = useState<ProductionRow[]>(initialData);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);
  const [formData, setFormData] = useState<ProductionRow | null>(
    initialData[0]
  );
  const [dayMode, setDayMode] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof ProductionRow | null;
    direction: "ascending" | "descending";
  }>({
    key: null,
    direction: "ascending",
  });
  const [sortLoading, setSortLoading] = useState(false);

  useEffect(() => {
    if (tableData?.length > 0) {
      setSelectedRowIndex(0);
      setFormData(tableData[0]);
    }
  }, []);

  const handleSort = async (key: keyof ProductionRow) => {
    setSortLoading(true);

    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    // Simulate API sorting delay
    await new Promise((resolve) => setTimeout(resolve, 500));

    setSortConfig({ key, direction });
    setSortLoading(false);
  };

  const sortedData = useMemo(() => {
    if (!sortConfig.key) return tableData;

    return [...tableData].sort((a: any, b: any) => {
      const key: any = sortConfig.key;
      let aValue = a[key];
      let bValue = b[key];

      // Special handling for date strings
      if (key === "date") {
        aValue = moment(aValue, "DD/MM/YYYY").valueOf();
        bValue = moment(bValue, "DD/MM/YYYY").valueOf();
      }
      // Special handling for numeric strings
      else if (
        [
          "quantity",
          "plannedQuantityUnit",
          "plannedQuantityKG",
          "ratio",
        ].includes(key)
      ) {
        aValue = parseFloat(aValue) || 0;
        bValue = parseFloat(bValue) || 0;
      }

      if (aValue < bValue) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }, [tableData, sortConfig]);
  useEffect(() => {
    if (selectedRowIndex >= 0) {
      const currentRow = sortedData[selectedRowIndex];
      setFormData(currentRow);
      setDayMode(!!currentRow.dayMode);
    }
  }, [selectedRowIndex, sortedData]);

  const handleRowClick = (row: ProductionRow, index: number) => {
    setSelectedRowIndex(index);
  };

  const handleInputChange = (field: keyof ProductionRow, value: string) => {
    if (formData) {
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  };

  const handleApply = () => {
    if (formData && selectedRowIndex >= 0) {
      const newTableData = [...tableData];
      newTableData[selectedRowIndex] = {
        ...formData,
        dayMode,
      };
      setTableData(newTableData);
      setSelectedRowIndex(-1);
      setFormData(null);
      setDayMode(false);
    }
  };

  const handleCancel = () => {
    setSelectedRowIndex(-1);
    setFormData(null);
    setDayMode(false);
  };

  return (
    <div className="p-6">
      <div className="bg-white rounded-lg border border-gray-200 shadow-sm p-4">
        <div className="flex justify-between items-center pb-2 mb-6">
          <div className="text-xl font-semibold">
            Production Plan - Objectives
          </div>
        </div>

        <div className="flex items-center gap-6 mb-6">
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-600">Date:</span>
            <DatePicker
              className="w-40"
              placeholder="Select date"
              disabled={sortLoading}
            />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-600">Team:</span>
            <Select
              placeholder="Select team"
              className="w-40"
              disabled={sortLoading}
              options={[
                { value: "D1", label: "D1" },
                { value: "D2", label: "D2" },
              ]}
            />
          </div>
          <Button type="primary" disabled={sortLoading}>
            <RotateCw className="w-4 h-4" />
            Refresh
          </Button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
          <div
            className={`sm:col-span-12 ${
              formData ? "md:col-span-8" : "md:col-span-12"
            }`}
          >
            <div className="overflow-x-auto shadow-sm rounded-lg relative">
              {sortLoading ? (
                <LoadingBox />
              ) : (
                <table className="w-full">
                  <thead className="bg-gray-50 w-full">
                    <tr>
                      {columns.map(({ key, label }) => (
                        <th
                          key={key}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                        >
                          <button
                            className="flex items-center space-x-1 w-full hover:text-gray-700"
                            onClick={() => handleSort(key)}
                            disabled={sortLoading}
                          >
                            <span>{label}</span>
                            <ArrowUpDown
                              className={`h-4 w-4 ${
                                sortConfig.key === key ? "text-blue-600" : ""
                              }`}
                            />
                          </button>
                        </th>
                      ))}
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Day Mode
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {sortedData.map((row, index) => (
                      <tr
                        key={index}
                        onClick={() => handleRowClick(row, index)}
                        className={`cursor-pointer transition-colors duration-150 ${
                          index === selectedRowIndex
                            ? "bg-blue-100"
                            : "hover:bg-gray-50"
                        }`}
                        style={{
                          borderLeft:
                            index === selectedRowIndex
                              ? "4px solid rgb(37 99 235)"
                              : "",
                        }}
                      >
                        {columns.map(({ key }) => (
                          <td
                            key={key}
                            className={`px-6 py-3 whitespace-nowrap text-sm ${
                              index === selectedRowIndex
                                ? "text-blue-700"
                                : "text-gray-600"
                            }`}
                          >
                            {row[key]}
                          </td>
                        ))}
                        <td className="px-6 py-3 whitespace-nowrap text-sm">
                          <Checkbox
                            disabled={sortLoading}
                            // checked={row.dayMode}
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <div className="flex items-center gap-3">
                            <button
                              className="text-blue-600"
                              disabled={sortLoading}
                            >
                              <Edit2 className="h-5 w-5" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          <div className="sm:col-span-12 md:col-span-4">
            <div
              className={`${
                formData ? "block" : "invisible"
              } bg-white rounded-lg shadow-sm h-fit`}
            >
              <div className="p-4 bg-gray-200 rounded-t-lg">
                <h3 className="text-md font-medium text-center text-gray-600">
                  Manage Production Plan
                </h3>
              </div>
              {formData && (
                <div className="p-6 space-y-4">
                  <div>
                    <label className="block text-sm text-gray-500 mb-1">
                      Date:
                    </label>
                    <Input
                      size="large"
                      className="w-full"
                      value={formData.date}
                      onChange={(e) =>
                        handleInputChange("date", e.target.value)
                      }
                      disabled={sortLoading}
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500 mb-1">
                      Team code:
                    </label>
                    <Select
                      size="large"
                      className="w-full"
                      placeholder="Select team code"
                      value={formData.team}
                      onChange={(value) => handleInputChange("team", value)}
                      options={[
                        { value: "D1", label: "D1" },
                        { value: "D2", label: "D2" },
                      ]}
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500 mb-1">
                      Composition type:
                    </label>
                    <Select
                      size="large"
                      className="w-full"
                      placeholder="Select composition type"
                      value={formData.compositionType}
                      onChange={(value) =>
                        handleInputChange("compositionType", value)
                      }
                      options={[
                        { value: "40G", label: "40G" },
                        { value: "70G", label: "70G" },
                        { value: "100G", label: "100G" },
                        { value: "1670G", label: "1670G" },
                      ]}
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500 mb-1">
                      Quantity:
                    </label>
                    <Input
                      size="large"
                      className="w-full"
                      value={formData.quantity}
                      onChange={(e) =>
                        handleInputChange("quantity", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500 mb-1">
                      Planned Quantity Unit:
                    </label>
                    <Input
                      size="large"
                      className="w-full"
                      value={formData.plannedQuantityUnit}
                      onChange={(e) =>
                        handleInputChange("plannedQuantityUnit", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500 mb-1">
                      Planned Quantity KG:
                    </label>
                    <Input
                      size="large"
                      className="w-full"
                      value={formData.plannedQuantityKG}
                      onChange={(e) =>
                        handleInputChange("plannedQuantityKG", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500 mb-1">
                      Ratio:
                    </label>
                    <Input
                      size="large"
                      className="w-full"
                      value={formData.ratio}
                      onChange={(e) =>
                        handleInputChange("ratio", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500 mb-1">
                      Staff composition:
                    </label>
                    <Input
                      size="large"
                      className="w-full"
                      value={formData.staffComposition}
                      onChange={(e) =>
                        handleInputChange("staffComposition", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label className="flex items-center gap-2 text-sm text-gray-500">
                      <Checkbox
                        checked={dayMode}
                        onChange={(e) => setDayMode(e.target.checked)}
                        disabled={sortLoading}
                      />
                      Day Mode
                    </label>
                  </div>
                  <div className="text-sm text-gray-500 mt-2">
                    1: the team code is composed with 2 chars: first one is the
                    letter of the week (A=Monday), the next one is number of
                    time range (1=morning, 2=afternoon, 3=night)
                  </div>
                  <div className="flex gap-4">
                    <Button
                      size="large"
                      className="flex-1 rounded"
                      type="default"
                      danger
                      onClick={handleCancel}
                      disabled={sortLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      className="flex-1 rounded"
                      size="large"
                      onClick={handleApply}
                      disabled={sortLoading}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionPlan;
