import { Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Filters from "../../components/filters";
import Navbar from "../../components/navigation/navbar";
import API from "../../config/api";
import { setFilters } from "../../redux/slices/filterSlice";
import { GET, POST } from "../../utils/apiCalls";
import DailyDemandAgency from "./components/dailyDemandAgency";
import DaliyDemand from "./components/daliyDemand";
import OccassionSplit from "./components/occassionSplit";
import ProductSplit from "./components/productSplit";
import PromoPerformance from "./components/promoPerformance";
import DemandNavigation from "./components/tabBar";

const Demand = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { filters, lastFetched } = useSelector((state: any) => state.Filter);
  const dispatch = useDispatch();
  const [salesChannel2Options, setSalesChannel2Options] = useState<any>({});
  const [data, setData] = useState<any>({});

  const [headingTxt, setHeadingTxt] = useState("Demand by Date");
  const [formData, setFormData] = useState<any>({
    year: ["2024"],
  });
  const [form] = Form.useForm();
  const [isSalesChannel2Disabled, setIsSalesChannel2Disabled] = useState(true);
  const [selectedTabItem, setSelectedTabItem] = useState<any>({
    id: 1,
    title: "Daily Sales",
    subMenu: [
      {
        id: 1,
        title: "Sales - Invoiced Finacial View",
      },
      {
        id: 2,
        title: "Daily Sales - Detailed",
      },
    ],
  });
  const [isProductsplitLoading, setProductSplitIsLoading] = useState(false);

  const [year, setYear] = useState<any>([2024]);
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [sortOrder, setSortOrder] = useState<any>("ASC");
  const [sortBy, setSortBy] = useState<any>("CATEGORY");
  const [filtersArray, setFiltersArray] = useState<any>({ year });

  useEffect(() => {
    const initializeDashboard = async () => {
      try {
        form.setFieldsValue({ year: formData.year });

        await getFilters();

        const initialData = {
          ...formData.year,
          year: formData.year,
          page: 1,
          limit: 10,
          groupBy:
            headingTxt === "Demand by Country" ? "COUNTRY" : "CREATEDDATE",
        };

        await Promise.all([fetchDemands(initialData)]);
      } catch (error) {
        console.error("Error initializing dashboard:", error);
      }
    };
    if (selectedTabItem?.id === 1) {
      initializeDashboard();
    } else if (selectedTabItem?.id === 2) {
      form.setFieldsValue({
        year: formData.year,
        salesChannel: ["WEBSITE"],
        // salesChannel2: ["All"],
        channel: ["B2C"],
      });
      setFormData({
        year: formData.year,
        salesChannel: ["WEBSITE"],
        // salesChannel2: ["All"],
        channel: ["B2C"],
      });
    } else {
    }
  }, [headingTxt]);

  const fetchDemands = async (filterValues: any = {}) => {
    try {
      setIsLoading(true);
      const response: any = await POST(API.DAILY_DEMAND, filterValues);
      if (response) {
        setData(response);
      } else {
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFilters();
  }, [1]);
  const getFilters = async () => {
    try {
      if (filters.length > 0) {
        return;
      }
      const response: any = await GET(API.FILTERS);

      if (response.length) {
        dispatch(setFilters(response));
      }

      const initialValues: any = {
        year: [],
        periods: [],
        weeks: [],
        country: [],
        channel: [],
        salesChannel: [],
      };
      form.setFieldsValue(initialValues);
    } catch (error) {
      console.log(error);
    }
  };
  const handleFormChange = async (changedValues: any, allValues: any) => {
    try {
      if (changedValues.salesChannel !== undefined) {
        if (
          changedValues.salesChannel &&
          changedValues.salesChannel.length > 0
        ) {
          const response = await POST(API.FILTERSBYCHANNEL, {
            channel: changedValues.salesChannel,
          });
          setSalesChannel2Options(response);
          setIsSalesChannel2Disabled(false);
        } else {
          form.setFieldsValue({
            salesChannel: [],
            salesChannel2: [],
          });
          setSalesChannel2Options({});
          setIsSalesChannel2Disabled(true);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const filterObject = (obj: Record<string, any>) => {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([key, value]) => Array.isArray(value) && value.length > 0
      )
    );
  };
  const handleFormSubmit = async (value: any) => {
    try {
      let values = filterObject(value);
      let obj = {
        ...values,
        page: 1,
        limit: 10,
        groupBy: headingTxt === "Demand by Country" ? "COUNTRY" : "CREATEDDATE",
      };
      setFormData(values);
      await Promise.all([fetchDemands(obj)]);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <Navbar />
      <div className="px-6 pt-4">
        <div className="flex items-center bg-blue-50 px-4 pt-4">
          <Filters
            filters={
              selectedTabItem?.id === 2
                ? filters.map((item: any) => {
                    if (
                      item.name === "channel" ||
                      item.name === "salesChannel"
                    ) {
                      return {
                        ...item,
                        disabled: true,
                      };
                    } else {
                      return item;
                    }
                  })
                : filters
            }
            form={form}
            handleFormChange={handleFormChange}
            handleFormFinish={handleFormSubmit}
            isSalesChannel2Disabled={
              selectedTabItem?.id === 2 ? true : isSalesChannel2Disabled
            }
            salesChannel2Options={salesChannel2Options}
          />
        </div>
      </div>
      <div className="flex items-center gap-2 bg-white border-b border-gray-200 px-6 py-4">
        <DemandNavigation
          selectedTabTxt={(val: any) => setHeadingTxt(val)}
          selectedItem={(item: any) => setSelectedTabItem(item)}
        />
      </div>
      {(selectedTabItem?.id === 1 && headingTxt === "Demand by Date") ||
      headingTxt === "Demand by Country" ? (
        <div className="bg-blue-50 p-4 m-6 mt-2 text-sm text-gray-600 italic">
          <p>
            Information: All orders have been selected,{" "}
            <span className="text-red-500">
              excluding those marked as "Lead" by Salesforce
            </span>
            . The number of orders and ordered NSV{" "}
            <span className="text-red-500">
              may still change for a previous date if an order returns to "Lead"
              status for modification
            </span>
            . This could temporarily alter the count of orders and also alter
            the NSV if the client decides to add or remove products.
          </p>
        </div>
      ) : headingTxt === "Demand (Web with GA4)" ? (
        <div className=" bg-blue-50 p-4 m-6 mt-2 text-sm text-gray-600 italic text-center">
          Information: All orders have been selected,{" "}
          <span className="text-red-500">
            excluding those marked as "Lead" by Salesforce
          </span>
          <div>
            Limited to website channels - Orders that don't go through the
            website are not displayed here.
          </div>
        </div>
      ) : headingTxt === "Demand Orders Conversion" ? (
        <div className="bg-blue-50 p-4 m-6 mt-2 text-sm text-gray-600 italic">
          <p>
            Information: All orders have been selected,{" "}
            <span className="text-red-500">
              excluding those marked as "Lead" by Salesforce
            </span>
            This is just the flow of the orders{" "}
            <b>created on that data range</b> and the % of them that{" "}
            <b>got invoiced or shipped as of yesterday</b>
          </p>
        </div>
      ) : selectedTabItem?.id === 2 ? (
        <div className="bg-blue-50 p-4 m-6 mt-2 text-sm text-gray-600 italic text-center">
          <p>
            Information: All orders have been selected,
            <span className="text-red-500">
              excluding those with the status "Lead" generated by Salesforce.
            </span>
            <div>
              For the Media Agencies Report, we consider only B2C orders, and
              the source WEBSITE excluding the Marketplaces.
            </div>
          </p>
        </div>
      ) : selectedTabItem?.id === 3 ? (
        <div className="bg-blue-50 p-4 m-6 mt-2 text-sm text-gray-600 italic text-center">
          <p>
            Information: All orders have been selected,
            <span className="text-red-500">
              excluding those with the status "Lead" generated by Salesforce.
            </span>
          </p>
        </div>
      ) : selectedTabItem?.id === 4 ? (
        <div className="bg-blue-50 p-4 m-6 mt-2 text-sm text-gray-600 italic text-center">
          <p>
            Information: All orders have been selected,
            <span className="text-red-500">
              excluding those with the status "Lead" generated by Salesforce.
            </span>
          </p>
        </div>
      ) : null}
      <div className="p-4 md:p-6">
        {selectedTabItem?.id === 1 ? (
          <DaliyDemand
            item={selectedTabItem}
            subItem={headingTxt}
            data={data?.rows}
            total={data?.total}
            fetchDemands={fetchDemands}
            totalPages={data?.totalPages}
            totalRows={data?.totalRows}
            loading={isLoading}
            filters={formData}
          />
        ) : selectedTabItem?.id === 2 ? (
          <DailyDemandAgency filters={formData} />
        ) : selectedTabItem?.id === 3 ? (
          <OccassionSplit filters={formData} />
        ) : selectedTabItem?.id === 4 ? (
          <PromoPerformance filters={formData} />
        ) : selectedTabItem?.id === 5 ? (
          <ProductSplit filters={formData} />
        ) : null}
      </div>
    </div>
  );
};

export default Demand;
